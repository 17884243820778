import React, {
  createContext,
  useState,
  useContext,
  ReactNode,
  useEffect,
} from 'react';
import { isDev } from './dev';
import videoCollection from '../domain/Video';

interface VideoAngleContextProps {
  addPlayer: (playerRef: any) => void; // Adds a ref to an active jwplayer.
  removePlayer: (playerId: string) => void; // Remove playerRef
  changeAngle: (playerId, newAngleId: string, newAngleName: string) => void;
  playerData: {
    playerId: string;
    playerRef: any; // JW PlayerRef
    selectedAngle?: string | null;
    selectedAngleName?: string | null;
    angles?: string[] | null;
  }[];
  setAngleForPlayer: (
    playerId: string,
    selectedAngle: string,
    selectedAngleName: string,
    angles?: any[]
  ) => void;

  getPlayerDataForPlayerId: (
    playerId: string
  ) => VideoAngleContextProps['playerData'][0];
  removePlayerData: (playerId: string) => void;
}

// Create the context
const VideoAngleContext =
  createContext<VideoAngleContextProps | undefined>(undefined);

// Create a custom hook to use the context
export const useVideoAngleContext = () => {
  const context = useContext(VideoAngleContext);
  if (!context) {
    if (isDev()) {
      console.warn(
        'useVideoAngleContext must be used within a VideoAngleContextProvider'
      );
    }
    // UseVideoAngleContext was called without provider
    // this can happen when a component uses videoplayer without needing the context.
    // eg. Player in statistics modal.
    return null;
  }
  return context;
};

// Create the context provider component
interface VideoAngleContextProviderProps {
  children: ReactNode;
}

const VideoAngleContextProvider: React.FC<VideoAngleContextProviderProps> = ({
  children,
}) => {
  const [playerData, setPlayerData] = useState<
    VideoAngleContextProps['playerData']
  >([]);

  const setAngleForPlayer = (
    playerId: string,
    newSelectedAngle: string,
    newSelectedAngleName: string,
    angles: string[] | null = null
  ) => {
    setPlayerData((_playerData) => {
      const index = _playerData.findIndex(
        (playerData) => playerData.playerId === playerId
      );
      const newData = [..._playerData];
      newData[index] = {
        ..._playerData[index],
        selectedAngle: newSelectedAngle,
        selectedAngleName: newSelectedAngleName,
      };
      return newData;
    });
  };

  useEffect(() => {
    if (playerData.length > 0) {
      const currentPlayer = playerData[playerData.length - 1].playerRef;
      const playlistItems = currentPlayer.getPlaylist();

      // Set selected angle on opening _another_ player.
      // eg. observe video tagging modal.
      if (
        playerData.length > 1 &&
        playerData[playerData.length - 1].selectedAngleName === 'initial' &&
        playerData[0].selectedAngle !== playlistItems[0].videoId
      ) {
        const existingPlayer = getPlayerDataForPlayerId(playerData[0].playerId);
        changeAngle(
          currentPlayer.id,
          existingPlayer.selectedAngle,
          existingPlayer.selectedAngleName
        );
      }

      // Change angle when selected but existing player rebuilds.
      if (
        playerData.length == 1 &&
        playerData[0].selectedAngle !== playlistItems[0]?.videoFragment.videoId
      ) {
        changeAngle(
          currentPlayer.id,
          playerData[0].selectedAngle,
          playerData[0].selectedAngleName
        );
      }
    }
  }, [playerData]);

  const getPlayerDataForPlayerId = (playerId: string) => {
    return (
      (playerData.length &&
        playerData[playerData.findIndex((p) => p.playerId === playerId)]) ||
      null
    );
  };

  const addPlayer = (playerRef: any) => {
    const newPlayerId: string = playerRef.id;
    if (
      playerData.length > 0 &&
      playerData.find(
        (existingPlayers) => existingPlayers.playerId === newPlayerId
      )
    ) {
      if (isDev()) console.warn(`player with ${newPlayerId} already exists`);
      const newPlayers = [...playerData];
      const index = newPlayers.findIndex(
        (existingPlayer) => existingPlayer.playerId === newPlayerId
      );

      newPlayers.splice(index, 1);
      newPlayers.push({
        playerId: newPlayerId,
        playerRef: playerRef,
        selectedAngle: playerData[index].selectedAngle,
        selectedAngleName: playerData[index].selectedAngleName,
      });
      setPlayerData([...newPlayers]);
    } else {
      const newPlayer = {
        playerId: newPlayerId,
        playerRef: playerRef,
      };
      setPlayerData((prevData) => {
        return [...prevData, { ...newPlayer }];
      });
    }
  };

  const removePlayer = (playerId: string) => {
    setPlayerData((_playerData) => {
      const newData = [..._playerData];
      newData.splice(
        newData.findIndex((p) => p.playerId === playerId),
        1
      );
      return newData;
    });
  };

  const changeAngle = async (playerId, newAngle, newAngleName) => {
    isDev() && console.log('changing Angle', newAngle, newAngleName);
    const player = getPlayerDataForPlayerId(playerId)?.playerRef;
    if (!player) {
      console.warn('NO PLAYER FOUND ', playerId);
      console.log(JSON.stringify(playerData)); // for sentry logging
      throw new Error('NO PLAYER FOUND');
    }
    const timeBeforeChange = player?.getPosition();
    const playlistItems = player.getPlaylist();
    const currentIndex = player.getPlaylistIndex();
    if (newAngle && newAngle !== playlistItems[0]?.videoFragment.videoId) {
      let newPlaylist = playlistItems.map((item) => {
        let video = videoCollection.getOrPlaceholder(newAngle);
        let oldVideo = videoCollection.getOrPlaceholder(
          item.videoFragment.videoId
        );
        return {
          file: item.file.replace(oldVideo.mediaUrl, video.mediaUrl),
          videoFragment: {
            ...playlistItems[0].videoFragment,
            videoId: newAngle,
          },
        };
      });
      setAngleForPlayer(playerId, newAngle, newAngleName);

      /* Resume player from where you were before switching angles */
      const promise = new Promise((resolve) => {
        const handler = () => {
          player.off('play', handler);
          resolve();
        };
        player.on('play', handler);
      });
      player.load(newPlaylist);
      player.playlistItem(currentIndex);
      // Old method resumed playstate on playlist change,
      // However here i need play().
      player.play();
      await promise;
      player.seek(timeBeforeChange);
    }
  };

  return (
    <VideoAngleContext.Provider
      value={{
        addPlayer,
        removePlayer,
        changeAngle,
        setAngleForPlayer,
        getPlayerDataForPlayerId,
        playerData,
      }}
    >
      {children}
    </VideoAngleContext.Provider>
  );
};

export default VideoAngleContextProvider;
