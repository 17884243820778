import React, { Component } from 'react';

import { action, observable, extendObservable } from 'mobx';

import * as tus from 'tus-js-client';

import VideoCollection from '../domain/Video';
import { asyncAction } from 'mobx-utils';
import { ParseVideoFile } from '../modules/match/components/FileUploadPreview';

const CHUNK_SIZE = 25 * 1024 * 1024;
const MS_IN_SEC = 1000;

class UploadFile {
  constructor(file, uploadUrl, { onProgress, onError, onSuccess }) {
    this.tusUpload = null;
    this.percentage = 0;
    this.uploadSpeedKB = '-';
    this.progressCheckerInterval = null;
    this.file = file;
    this.lastOnProgress = null;

    this.tusUpload = new tus.Upload(file, {
      endpoint: 'about:blank',
      uploadUrl: uploadUrl,
      chunkSize: CHUNK_SIZE,
      retryDelays: [
        MS_IN_SEC,
        5 * MS_IN_SEC,
        5 * MS_IN_SEC,
        10 * MS_IN_SEC,
        10 * MS_IN_SEC,
        30 * MS_IN_SEC,
        30 * MS_IN_SEC,
        60 * MS_IN_SEC,
        60 * MS_IN_SEC,
        60 * MS_IN_SEC,
      ],
      resume: false,
      onError: (error) => {
        console.log('error', error);
        this.uploadSpeedKB = '-';
        onError(this, error);
        clearInterval(this.progressCheckerInterval);
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        this.percentage = ((bytesUploaded / bytesTotal) * 100).toFixed(2);
        const now = new Date().getTime() / 1000;
        if (this.lastOnProgress !== null) {
          const timedelta = now - this.lastOnProgress;
          if (timedelta >= 2) {
            // update speed every 2 seconds, otherwise it will fluctuate a lot
            const sizeDelta = bytesUploaded - this.lastBytesUploaded;
            this.uploadSpeedKB = (sizeDelta / timedelta / 1024).toFixed(1);
            this.lastOnProgress = now;
            this.lastBytesUploaded = bytesUploaded;
          }
        } else {
          this.lastOnProgress = now;
          this.lastBytesUploaded = bytesUploaded;
        }
        onProgress(this, bytesUploaded, bytesTotal, this.uploadSpeedKB);
      },
      onSuccess: () => {
        this.uploadSpeedKB = '-';
        onSuccess(this);

        clearInterval(this.progressCheckerInterval);
      },
    });
  }

  checkProgress() {
    const now = new Date().getTime() / 1000;
    if (now - this.lastOnProgress > 60) {
      this.lastOnProgress = now;
      // try to restart
      this.tusUpload.abort();

      this.tusUpload._retryAttempt = 0;
      this.tusUpload.start();

      this.setCheckInterval();
    }
  }

  setCheckInterval() {
    clearInterval(this.progressCheckerInterval);

    this.progressCheckerInterval = setInterval(
      this.checkProgress.bind(this),
      5000
    );
  }

  start() {
    this.lastOnProgress = null;
    this.lastBytesUploaded = null;
    this.uploadSpeedKB = 0;

    this.tusUpload._retryAttempt = 0;
    this.tusUpload.start();

    this.setCheckInterval();
  }

  stop() {
    clearInterval(this.progressCheckerInterval);
    this.tusUpload.abort();
    this.uploadSpeedKB = 0;
  }

  asObject() {
    return { size: this.file.size, name: this.file.name };
  }

  getPercentage() {
    return this.percentage;
  }

  getUploadSpeedKB() {
    return this.uploadSpeedKB;
  }

  getTotalSize() {
    return this.file.size;
  }
}

class UploadState {
  constructor() {
    this._bytesTotal = observable(0);
    this._bytesUploaded = observable(0);
    this._state = observable('queueing');
    this._files = observable([]);

    this._uploadHistory = observable([]);

    extendObservable(this, {
      get bytesTotal() {
        return this._bytesTotal.get();
      },
      get bytesUploaded() {
        return this._bytesUploaded.get();
      },
      get state() {
        return this._state.get();
      },
      get files() {
        return this._files;
      },
      get speed() {
        if (this._uploadHistory.length < 2) {
          return null;
        } else {
          const lastIdx = this._uploadHistory.length - 1;
          const tsDelta =
            (this._uploadHistory[lastIdx].ts - this._uploadHistory[0].ts) /
            1000;
          const bytesDelta =
            this._uploadHistory[lastIdx].bytesUploaded -
            this._uploadHistory[0].bytesUploaded;

          if (tsDelta === 0) {
            return null;
          } else {
            return bytesDelta / tsDelta;
          }
        }
      },
    });
  }

  setBytesTotal = action((bytesTotal) => {
    this._bytesTotal.set(bytesTotal);
  });

  setBytesUploaded = action((bytesUploaded) => {
    this._bytesUploaded.set(bytesUploaded);

    this._uploadHistory.push({
      ts: window.performance.now(),
      bytesUploaded,
    });
    this._uploadHistory.splice(0, this._uploadHistory.length - 50);
  });

  setState = action((state) => {
    this._state.set(state);
  });

  reOrder = action((startIndex, endIndex) => {
    const [removed] = this._files.splice(startIndex, 1);
    this._files.splice(endIndex, 0, removed);
  });

  addFile = action((file) => {
    this._files.push(file);
  });

  removeFile = action((file) => {
    const index = this._files.indexOf(file);
    this._files.splice(index, 1);
  });
}

class FileUploader {
  constructor(onStart, onSuccess, uploadState) {
    this.files = [];
    this.uploadFiles = [];

    this.uploadState = uploadState;

    this.onStartHandler = onStart;
    this.onSuccessHandler = onSuccess;

    this.currentUploadFileIndex = 0;

    this.videoId = null;
  }

  async addFile(file) {
    this.files.push(file);
    let enrichedFile = await ParseVideoFile(file);
    this.uploadState.addFile(enrichedFile);
  }

  removeFile(file) {
    const index = this.files.indexOf(file);
    this.files.splice(index, 1);

    this.uploadState.removeFile(file);
  }

  setFile(file) {
    if (this.files.length > 0) {
      this.removeFile(this.files[0]);
    }
    this.addFile(file);
  }

  reOrder = (startIndex, endIndex) => {
    const [removed] = this.files.splice(startIndex, 1);
    this.files.splice(endIndex, 0, removed);
    this.uploadState.reOrder(startIndex, endIndex);
  };

  async start(uploadable, clockId) {
    this.uploadState.setState('starting');

    this.videoId = await uploadable.initVideoUpload(
      this.files.map((file) => {
        return {
          name: file.name,
          size: file.size,
        };
      }),
      clockId
    );
    this.onStartHandler(this.videoId);

    await this._initUpload();

    this.startUpload();
  }

  async _initUpload() {
    const video = await VideoCollection.getOrFetch(this.videoId);
    const videoParts = video.parts;
    for (const [index, file] of this.files.entries()) {
      if (
        //videoParts[index].filename !== file.name ||
        // unicode problems (ttv-391)

        videoParts[index].fileSize !== file.size
      ) {
        throw 'Mismatch';
      }
    }

    this.uploadFiles = videoParts.map((videoPart, index) => {
      return new UploadFile(this.files[index], videoPart.tusUploadUrl, {
        onProgress: (uploadFile, bytesUploaded, bytesTotal, uploadSpeedKB) =>
          this.onProgress(uploadFile, bytesUploaded, bytesTotal, uploadSpeedKB),
        onError: (uploadFile) => this.onError(uploadFile),
        onSuccess: (uploadFile) => this.onSuccess(uploadFile),
      });
    });
  }

  startUpload() {
    this.uploadFiles[this.currentUploadFileIndex].start();

    this.uploadState.setBytesTotal(this.totalSize());
    this.uploadState.setState('uploading');
  }

  stopUpload() {
    this.uploadFiles[this.currentUploadFileIndex].stop();
    this.uploadState.setState('stopped');
  }

  totalSize() {
    let size = 0;
    for (const uploadFile of this.uploadFiles) {
      size += uploadFile.file.size;
    }
    return size;
  }

  onProgress(uploadFile, bytesUploaded, bytesTotal) {
    this.currentUploadFileIndex = this.uploadFiles.indexOf(uploadFile);

    let totalBytesUploaded = 0;
    for (let index = 0; index < this.currentUploadFileIndex; index++) {
      totalBytesUploaded += this.uploadFiles[index].getTotalSize();
    }
    totalBytesUploaded += bytesUploaded;

    this.uploadState.setBytesUploaded(totalBytesUploaded);
    this.uploadState.setState('uploading');
  }

  onSuccess(uploadFile) {
    this.currentUploadFileIndex = this.uploadFiles.indexOf(uploadFile);
    if (this.currentUploadFileIndex === this.uploadFiles.length - 1) {
      this.uploadState.setState('success');

      this.onSuccessHandler(this.videoId);

      // make sure other components will be updated
      const video = VideoCollection.get(this.videoId);
      video.fetch();
    } else {
      this.uploadFiles[this.currentUploadFileIndex + 1].start();
    }
  }

  onError() {
    this.uploadState.setState('error');
  }
}

class EncodeState {
  constructor() {
    this._state = observable('new');
    this._percentage = observable(0.0);

    this._refreshInterval = null;
    this.videId = null;

    extendObservable(this, {
      get state() {
        return this._state.get();
      },
      get percentage() {
        return this._percentage.get();
      },
    });
  }

  setVideoId(videoId) {
    this.videoId = videoId;
  }

  startMonitoring(videoId) {
    this.setVideoId(videoId);

    this._refreshInterval = setInterval(this._refreshState.bind(this), 10000);

    this._refreshState();
  }

  stopMonitoring() {
    clearInterval(this._refreshInterval);
  }

  _refreshState = asyncAction(function* () {
    const video = VideoCollection.getOrCreate(this.videoId);
    yield video.fetch();

    this._state.set(video.get('state'));

    switch (video.get('state')) {
      case 'ready':
      case 'failed':
      case 'aborted':
        // final state
        clearInterval(this._refreshInterval);
        break;

      case 'new':
      case 'waiting_for_encode':
      case 'fetching_content_info':
        break;

      case 'encoding':
        // Assume all input files are same bitrate so
        // filesize is indicator of encoding duration
        let totalSize = 0,
          encodedSize = 0;
        for (const part of video.get('parts')) {
          totalSize += part.fileSize;
          if (part.state === 'encode-success') {
            encodedSize += part.fileSize;
          } else if (part.state === 'encode-progress') {
            encodedSize += part.fileSize * part.stateInfo.percentage;
          }
        }
        this._percentage.set((encodedSize / totalSize) * 100);
        break;
    }
  });
}

export { FileUploader, UploadState, EncodeState };
