import { LearningLineQueryService } from 'domain/Development/LearningLineQueryService';
class ObservationInputRegistry {
  constructor() {
    this.observationInputs = [];
  }

  register(observationInput) {
    this.observationInputs.push(observationInput);
  }

  getObservationInputByName(name) {
    for (const observationInput of this.observationInputs) {
      if (observationInput.name === name) {
        return observationInput;
      }
    }
    throw `ObservationInput not found: ${name}`;
  }

  getObservationInputByKey(key) {
    const [name, args] = key.split('=');
    return this.getObservationInputByName(name).clone(args);
  }

  getObservationInputs() {
    return this.observationInputs;
  }

  async forSession({ session, live, type }) {
    // TODO check if session has feature available
    const sportType = session.sportType();

    const observationInputs = [];

    if (type === 'training') {
      if (live) {
        observationInputs.push(this.getObservationInputByName('training.live'));
      } else {
        observationInputs.push(
          this.getObservationInputByName('training.default')
        );
      }
      return observationInputs;
    }

    if (live) {
      if (session.isFeatureAvailable('observeBroadcast')) {
        observationInputs.push(
          this.getObservationInputByName('broadcast.live.default')
        );
      }
      switch (sportType) {
        case 'korfball':
          observationInputs.push(
            this.getObservationInputByName('korfball.live.default')
          );
          break;
        case 'handball':
          observationInputs.push(
            this.getObservationInputByName('handball.live.default')
          );
          break;
        case 'hockey':
          observationInputs.push(
            this.getObservationInputByName('hockey.live.default')
          );
          break;
        case 'soccer':
          observationInputs.push(
            this.getObservationInputByName('soccer.live.default')
          );
          // observationInputIds.push('soccer.live.passmap');
          break;
      }
    } else {
      switch (sportType) {
        case 'korfball':
          observationInputs.push(
            this.getObservationInputByName('korfball.video.default')
          );
          break;
        case 'handball':
          observationInputs.push(
            this.getObservationInputByName('handball.video.default')
          );
          break;
        case 'hockey':
          observationInputs.push(
            this.getObservationInputByName('hockey.video.default')
          );
          break;
        case 'soccer':
          observationInputs.push(
            this.getObservationInputByName('soccer.video.default')
          );
          // observationInputIds.push('soccer.live.passmap');
          break;
        case 'tennis':
          observationInputs.push(
            this.getObservationInputByName('tennis.video.default')
          );
          break;
        case 'other':
        default:
          observationInputs.push(
            this.getObservationInputByName('other.video.default')
          );
          break;
      }
    }

    if (session.isFeatureAvailable('observeDevelopment')) {
      const learningLines = await LearningLineQueryService.learningLines();
      for (const learningLine of learningLines) {
        const observationInput = this.getObservationInputByKey(
          `development.default=${learningLine.id}`
        );
        observationInput.title = learningLine.name;
        observationInputs.push(observationInput);
      }
    }
    if (session.isFeatureAvailable('observeLearningLine')) {
      const learningLines = await LearningLineQueryService.learningLines();
      for (const learningLine of learningLines) {
        if (learningLine.hasPrivilege('edit')) {
          const observationInput = this.getObservationInputByKey(
            `learning-line.default=${learningLine.id}`
          );
          observationInput.title = learningLine.name;
          observationInputs.push(observationInput);
        }
      }
    }
    return observationInputs;
  }
}

const registry = new ObservationInputRegistry();

export default registry;
