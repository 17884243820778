import Loading from '../../../lib/Loading';
import teamCollection from 'domain/Team';
import { useEffect, useMemo, useState } from 'react';
import { Page } from '../../../lib/Page';
import personCollection from '../../../domain/Person';
import { OverviewItem } from '../../../lib/OverviewItem';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';
import { Session } from '../../../domain/Session';
import { Modal } from '../../../lib';
import {
  CreateTeamModal,
  deleteTeam,
  ManagePlayersModal,
  MergeTeamsModal,
  unMergeTeam,
  unMergeTeams,
} from './actions';
import sweetAlert from '../../../lib/sweetAlert';
import { InputCheckboxRadio } from '../../../lib/InputCheckBoxRadio';
import exchangeIcon from '../../../img/icons/exchange.png';
import { gotoRoute } from '../../route';

export const TeamManagementOverview = () => {
  const [teams, setTeams] = useState(null);
  const [players, setPlayers] = useState(null);
  const [search, setSearch] = useState('');
  const [modalComponent, setModalComponent] = useState(null);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const mergeMode = true;
  const { t } = useTranslation('module.settings.club');
  let currentSession = Session.current();
  const ownTeamId = currentSession.currentResource().teamId;

  useEffect(() => {
    (async () => {
      let _teams = await teamCollection.fetch();
      let _players = await personCollection.fetch();
      setTeams(sortedTeams(_teams));
      setPlayers(_players);
    })();
  }, []);

  const refresh = async () => {
    let _teams = await teamCollection.fetch();
    let _players = await personCollection.fetch();
    setTeams(sortedTeams(_teams));
    setPlayers(_players);
    setModalComponent(null);
    setSelectedTeams([]);
    // setMergeMode(false);
  };

  const sortedTeams = (teams) => {
    return teams.sort((teama, teamb) => {
      if (teama.teamId === ownTeamId) return -1; // Own team goes first
      if (teamb.teamId === ownTeamId) return 1; // Own team goes first

      return teama.name.localeCompare(teamb.name);
    });
  };

  const filterTeams = useMemo(() => {
    return (team) => {
      let _team = teamCollection.get(team.teamId);
      if (_team?.aliasOf() || _team.isDemo() || _team._isCopiedShare()) {
        return 0;
      }

      if (_team.isShared()) {
        if (
          teamCollection.toArray().find((t) => t?.tags.copyOf === team.teamId)
        ) {
          return 0; // copy is found.
        }
      }

      return (
        team?.name.toLowerCase().includes(search.toLowerCase()) ||
        team?.shortCode.toLowerCase().includes(search.toLowerCase())
      );
    };
  }, [teamCollection, search]);

  // console.log(teams);
  if (!teams || !players) {
    return <Loading type={'fullscreen'} />;
  }
  const checkboxToggle = (teamId) => {
    setSelectedTeams((oldValue) => {
      if (oldValue.includes(teamId)) {
        return oldValue.filter((v) => v != teamId);
      } else {
        return [...oldValue, teamId];
      }
    });
  };

  const pageProps = {
    title: t('teamsManagement'),
    breadcrumbs: [
      { path: '/', title: 'Home' },
      { title: t('teamsManagement') },
    ],
    viewFilters: [
      {
        type: 'search',
        placeholder: t('common:search'),
        onChange: debounce((value) => {
          setSearch(value);
        }, 200),
      },
    ],
    stickyHeader: selectedTeams.length > 0,
  };
  pageProps.primaryAction = [
    {
      title: t('addTeam'),
      secondary: true,
      onClick: () => {
        setModalComponent(
          <CreateTeamModal onCloseClick={() => setModalComponent(null)} />
        );
      },
      disabled: !currentSession.currentPrivileges().hasPrivilege('team:create'),
    },
    {
      title: t('mergeCount', { count: selectedTeams.length }),
      onClick: () => {
        setModalComponent(
          <MergeTeamsModal
            teams={teams.filter((team) => selectedTeams.includes(team.teamId))}
            onClose={() => {
              setModalComponent(null);
            }}
            callback={() => refresh()}
          />
        );
      },
      // secondary: true,
      disabled:
        !currentSession.currentPrivileges().hasPrivilege('team:create') ||
        selectedTeams.length <= 1,
    },
  ];

  if (selectedTeams.length >= 1) {
    pageProps.primaryAction.push({
      title: t('common:deselectAll'),
      onClick: () => setSelectedTeams([]),
    });
  }

  return (
    <>
      <Page {...pageProps} className={'opponent-overview'}>
        <div className="page-description">
          <h2>{t('manageOpponentsTitle')}</h2>
          <p>{t('manageOpponentsDescription')}</p>
        </div>
        <div className={'teams-overview'}>
          {teams
            .filter((t) => filterTeams(t))
            .map((team, index) => {
              let wrapperClass = 'original-team-wrapper';
              let _mergemode = mergeMode;
              let aliases = teamCollection.get(team.teamId).aliasedTeams();
              const isOwnTeam = team?.teamId === ownTeamId;

              let isMergable =
                _mergemode &&
                team.teamId !== ownTeamId &&
                aliases?.length === 0;
              let isSelected = selectedTeams.includes(team.teamId);

              // Copy should fall below this one
              // The copy could be in aliases already we filter that out.
              if (0 && team?.tags?.copyOf) {
                let _copyOf = teamCollection
                  .toArray()
                  .find((t) => t.teamId === team.tags.copyOf);
                if (
                  _copyOf && // CopyOf needs to exist
                  !aliases.find((a) => a.teamId === _copyOf.teamId)
                )
                  aliases.push(_copyOf);
              }

              if (0 && isOwnTeam) {
                aliases = [
                  ...aliases,
                  ...teamCollection.toArray().filter((t) => t._isCopiedShare()),
                ];
              }

              return (
                <div className={'teams-overview-group-wrapper'}>
                  <div
                    key={index}
                    data-teamId={team.teamId}
                    className={`teams-overview-item d-flex ${
                      aliases?.length > 0 ? 'parent-team-wrapper' : wrapperClass
                    } ${isSelected ? 'is-selected' : ''}`}
                  >
                    {_mergemode && (
                      <div
                        style={{
                          opacity: isMergable ? 1 : 0,
                          pointerEvents: isMergable ? 'auto' : 'none',
                        }}
                      >
                        <InputCheckboxRadio
                          onChange={(v) => {
                            checkboxToggle(team.teamId);
                          }}
                          disabled={!isMergable}
                          type={'checkbox'}
                          checked={isSelected}
                        />
                      </div>
                    )}
                    <TeamComponent
                      key={`tc-${index}`}
                      team={team}
                      setModalComponent={setModalComponent}
                      canEdit={true}
                      refresh={refresh}
                      onClick={() => isMergable && checkboxToggle(team.teamId)}
                    />
                  </div>
                  {aliases?.map((alias, index) => {
                    return (
                      <div
                        data-teamId={alias.teamId}
                        key={`alias-${index}`}
                        className={`teams-overview-item d-flex sub-team-wrapper ${
                          _mergemode ? 'is-mergemode' : ''
                        }`}
                      >
                        <TeamComponent
                          key={`tca-${index}`}
                          team={teams.find((t) => t.teamId === alias.teamId)} // use original from teams endpoint, not teamscollection.
                          setModalComponent={setModalComponent}
                          refresh={refresh}
                          canEdit={true}
                        />
                      </div>
                    );
                  })}
                </div>
              );
            })}
        </div>
        {modalComponent}
      </Page>
    </>
  );
};

export const TeamComponent = ({
  team,
  setModalComponent = () => {},
  canEdit = false,
  onClick,
  refresh = () => {},
}) => {
  let _team = teamCollection.get(team.teamId);
  let currentSession = Session.current();
  const isOwnTeam = currentSession.currentResource().teamId == team.teamId;
  const { t } = useTranslation('module.settings.club');
  let canEditTeam = team._metadata.privilegesV2['team:edit'].ok;
  let canviewTeam = team._metadata.privilegesV2['team:view'].ok;
  let canDestroyTeam = team._metadata.privilegesV2['team:destroy'].ok;
  let canManagePlayers = team._metadata.privilegesV2['team:manage-members'].ok;
  let canCreateLocalTeam =
    !_team.getLocalCopy() && !_team._isCopiedShare() && !_team.aliasOf();
  let secondaryActions = [];

  if (
    0 &&
    canEdit &&
    currentSession.currentPrivileges().hasPrivilege('team:edit')
  ) {
    // todo: enable edit team modal and functions
    secondaryActions.push({
      onClick: () => {
        setModalComponent(
          <Modal onCloseClick={() => setModalComponent(null)}></Modal>
        );
      },
      label: t('common:edit'),
    });
  }
  if (
    canEdit &&
    currentSession.currentPrivileges().hasPrivilege('team:destroy') &&
    canDestroyTeam &&
    !isOwnTeam
  ) {
    secondaryActions.push({
      onClick: () =>
        sweetAlert({
          title: t('deleteTeamConfirmTitle'),
          text: t('deleteTeamConfirmMessage', { team: _team }),
          dangerMode: true,
          buttons: [t('common:cancel'), t('common:confirm')],
        }).then(async (willDelete) => {
          if (willDelete) {
            await deleteTeam(team.teamId);
            await refresh();
          }
        }),
      label: t('common:delete'),
    });
  }

  if (
    canEdit &&
    currentSession.currentPrivileges().hasPrivilege('team:edit') &&
    _team.aliasOf()
  ) {
    secondaryActions.push({
      onClick: () =>
        sweetAlert({
          // todo: translate..
          title: t('unMerge'),
          text: t('unMergeTeam', { teamName: team.name }),
          dangerMode: true,
          buttons: [t('common:cancel'), t('common:confirm')],
        }).then(async (willDelete) => {
          if (willDelete) {
            await unMergeTeam(team.name, _team.aliasOf());
            await refresh();
          }
        }),
      label: 'Unmerge',
    });
  }

  if (
    canEdit &&
    currentSession.currentPrivileges().hasPrivilege('team:edit') &&
    team.aliases?.length > 0
  ) {
    secondaryActions.push({
      onClick: () =>
        sweetAlert({
          // todo: translate..
          title: t('unMerge'),
          text: t('unMergeTeams', { teamName: team.name }),
          dangerMode: true,
          buttons: [t('common:cancel'), t('common:confirm')],
        }).then(async (willDelete) => {
          if (willDelete) {
            await unMergeTeams(team.teamId);
            await refresh();
          }
        }),
      label: 'Unmerge',
    });
  }

  if (canEdit && _team.isShared() && canCreateLocalTeam) {
    secondaryActions.push({
      label: t('createLocalTeamFor', { teamName: team.name }),
      onClick: async () => {
        _team.createLocalTeamFromShareTeam();
        await refresh();
      },
    });
  }

  if (
    canEdit &&
    currentSession.currentPrivileges().hasPrivilege('team:edit') &&
    canManagePlayers
  ) {
    secondaryActions.push({
      onClick: () => {
        isOwnTeam
          ? gotoRoute('user-management.team')
          : setModalComponent(
              <ManagePlayersModal
                teamId={team.teamId}
                onCloseClick={() => setModalComponent(null)}
              ></ManagePlayersModal>
            );
      },
      label: t('managePlayers'),
    });
  }

  const overviewItemProps = {
    title: ` ${team.name}`,
    secondaryActions,
  };
  if (isOwnTeam) {
    overviewItemProps.label = t(`Your team`);
  }

  const OverViewItemDetails = () => {
    return (
      <div
        data-teamId={_team.teamId}
        className="d-flex w-100 justify-content-end align-content-center align-items-center"
      >
        {team?._metadata?.source?.share?.resourceGroup?.targetResourceName && (
          <span style={{ marginRight: '1em', opacity: 0.5 }}>
            {team?._metadata?.source?.share?.resourceGroup?.targetResourceName}
          </span>
        )}
        <div className="overview-item-attribute mr-2">
          {(_team?.isShared() || team?.tags?.copyOf) && (
            <img
              className={`sharing-icon ${team?.tags?.copyOf ? 'in-use' : ''}`}
              src={exchangeIcon}
              alt="exchange icon"
            />
          )}
        </div>
        <b className={'team-shortcode mr-2'}>{team.shortCode}</b>
        <div
          className={
            'text-right mr-2 d-inline-flex align-items-center align-content-center'
          }
        >
          <b className={'mr-1'}>
            {`${team?.players ? Object.keys(team?.players).length : 0}`}{' '}
          </b>
          <i className={'i-user i-sm'} />
        </div>
      </div>
    );
  };
  overviewItemProps.details = () => <OverViewItemDetails />;
  if (typeof onClick === 'function') {
    overviewItemProps.onClick = onClick;
  }
  if (_team.label !== team.name) {
    overviewItemProps.label = _team.label;
  }
  if (isOwnTeam) {
    overviewItemProps.emphasis = true;
  }

  return <OverviewItem {...overviewItemProps}></OverviewItem>;
};
