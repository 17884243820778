import React, { useState, useEffect } from 'react';

import { ObservationInputOptions, ObservationInput } from '../ObservationInput';

import './training.live.scss';
import {
  CustomTagManager,
  RecordButton,
} from '../../../lib/ObservationPanel/Video/Panel';
import { Session } from '../../../domain/Session';
import { Modal } from '../../../lib';
import { InputTrigger } from '../components/InputTrigger';
import { Command, CommandBus } from '../../../infra/Messaging';
import { observer } from 'mobx-react';
import { InputCheckboxRadio } from '../../../lib/InputCheckBoxRadio';
import { useTranslation } from 'react-i18next';

const TrainingObservationInput = ({ observationContext, args }) => {
  const [players, setPlayers] = useState([]);
  const [filterPlayers, setFilterPlayers] = useState(false);
  const [activePlayers, setActivePlayers] = useState([]);

  useEffect(() => {
    (async () => {
      const currentSession = Session.current();
      await currentSession.isReady();
      await currentSession._currentResource.players.fetchIfEmpty();
      const _players = currentSession._currentResource.players.toArray();
      const sortedPlayers = _players.sort((a, b) =>
        a.person.firstName.localeCompare(b.person.firstName)
      );
      setPlayers(sortedPlayers); // Set initially sorted players
      setActivePlayers(sortedPlayers); // Default to all players active
    })();
  }, []);

  // Update activePlayers whenever players change
  useEffect(() => {
    setActivePlayers((prevActive) =>
      players.filter((p) =>
        prevActive.some(
          (active) => active.person.personId === p.person.personId
        )
      )
    );
  }, [players]);
  const { t } = useTranslation('module.observe.training');

  const toggleActivePlayer = (playerId) => {
    setActivePlayers((prevActivePlayers) =>
      prevActivePlayers.some((p) => p.person.personId === playerId)
        ? prevActivePlayers.filter((p) => p.person.personId !== playerId)
        : [
            ...prevActivePlayers,
            players.find((p) => p.person.personId === playerId),
          ]
    );
  };

  const updatePlayerOrder = (newOrder) => {
    setPlayers(newOrder); // Update players with new order
  };

  return (
    <>
      {filterPlayers && (
        <Modal
          onCloseClick={() => {
            setFilterPlayers(false);
          }}
          size={'md'}
        >
          <div className="modal-header">
            <h5>{t('filterPlayers')}</h5>
          </div>
          <PlayersFilter
            players={players}
            activePlayers={activePlayers}
            toggleActivePlayer={toggleActivePlayer}
            setPlayers={updatePlayerOrder}
          />
          <div className="modal-footer">
            <div className="actions">
              <a
                onClick={() => setFilterPlayers(false)}
                className={'btn btn-primary'}
              >
                {t('common:close')}
              </a>
            </div>
          </div>
        </Modal>
      )}
      <div className="container">
        <div className="row">
          <div className="training-actions">
            <FilterButton setFilterPlayers={setFilterPlayers} />
          </div>
        </div>
      </div>
      <div className="observation__training-live">
        {activePlayers.length > 0 && (
          <PlayerObservationInputs
            t={t}
            observationContext={observationContext}
            players={activePlayers} // Pass only the active players
          />
        )}
      </div>
      <div className="container pt-4">
        <RecordButton observationContext={observationContext} />
        <CustomTagManager observationContext={observationContext} />
      </div>
    </>
  );
};

const PlayerObservationInputs = observer(
  ({ players, observationContext, t }) => {
    const { observationLogger } = observationContext;
    const personRating =
      observationLogger.getCurrentPartialObservationAttributes('PLAYER');

    const cancelPersonRating = () => {
      const command = Command.create(
        'ObservationLogger.RemovePartialObservation',
        { groupId: 'PLAYER' }
      );
      CommandBus.dispatch(command);
    };

    let currentPlayer;
    if (personRating) {
      currentPlayer = players.find(
        (player) => player.person.personId === personRating.get('personId')
      );
    }

    if (!players.length) {
      return null;
    } else {
      return (
        <div className="container-fluid">
          <div className="row">
            {players.map((player, index) => {
              return (
                <div className={'col-md-3 col-4 pb-2 pt-2'} key={index}>
                  <InputTrigger
                    type={'start-point'}
                    observationCode="CUSTOM"
                    attributes={{
                      personId: player.person.personId,
                    }}
                    observationLogger={observationLogger}
                    description={`${player.person.firstName} ${player.person.lastName}`}
                    // description={`floep floep`}
                    durationBefore={7}
                    groupId="PLAYER"
                    render={({ active, trigger }) => (
                      <div
                        onClick={trigger}
                        className={`playercard ${active ? 'is-active' : ''}`}
                      >
                        <h2>{player.person.firstName}</h2>
                        <h3>{player.person.lastName}</h3>
                      </div>
                    )}
                  />
                </div>
              );
            })}
          </div>
          {personRating && (
            <Modal size={'md'} onCloseClick={cancelPersonRating}>
              <div className="container">
                <div className="modal-header">
                  <h5>
                    {`${t('logAction')} ${currentPlayer.person.firstName} ${
                      currentPlayer.person.lastName
                    }`}
                  </h5>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    {['positive', 'neutral', 'negative'].map((rating) => {
                      return (
                        <InputTrigger
                          InputTrigger
                          key={rating}
                          type="end-point"
                          observationCode="CUSTOM"
                          groupId="PLAYER"
                          description={`${currentPlayer.person.firstName} ${currentPlayer.person.lastName}`}
                          attributes={{ rating: rating }}
                          observationLogger={observationLogger}
                          durationAfter={5}
                          render={({ active, trigger }) => {
                            return (
                              <div
                                className={`training-rating mb-4 rating--${rating}`}
                                key={rating}
                                onClick={trigger}
                              >
                                {active} {t(`ratings.${rating}`)}
                              </div>
                            );
                          }}
                        />
                      );
                    })}
                  </div>
                  <div className="col-md-6">
                    <InputTrigger
                      InputTrigger
                      key={'unknown'}
                      type="end-point"
                      observationCode="CUSTOM"
                      groupId="PLAYER"
                      description={`${currentPlayer.person.firstName} ${currentPlayer.person.lastName}`}
                      attributes={{ rating: 'unknown' }}
                      observationLogger={observationLogger}
                      render={({ active, trigger }) => {
                        return (
                          <div
                            className={`training-rating mb-4 rating--${'unknown'}`}
                            key={'other'}
                            onClick={trigger}
                          >
                            {active} {t(`ratings.unknown`)}
                          </div>
                        );
                      }}
                    />
                  </div>
                </div>
                <div className="row modal-footer">
                  <div className="col-12 form-actions">
                    <a onClick={cancelPersonRating} class={'btn btn-link'}>
                      {t('common:close')}
                    </a>
                  </div>
                </div>
              </div>
            </Modal>
          )}
        </div>
      );
    }
  }
);

const options = new ObservationInputOptions(false, false, false, false, true);

export default new ObservationInput({
  name: 'training.live',
  component: TrainingObservationInput,
  options,
  title: 'Training',
  usedKeys: [],
});

const FilterButton = ({ setFilterPlayers }) => {
  const { t } = useTranslation('module.observe.training');
  return (
    <a
      onClick={() => setFilterPlayers((prev) => !prev)}
      className="btn btn-primary"
    >
      <div className="icon-filter">
        <span className="i-dark">
          <svg width="16px" height="17px" viewBox="0 0 16 17">
            <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g
                id="Wedstrijdvideo---Desktop---1170px-Copy-25"
                transform="translate(-1024.000000, -186.000000)"
                fill="#000000"
                fill-rule="nonzero"
              >
                <g
                  id="noun_filter_1985427_1A1A1A"
                  transform="translate(1024.000000, 186.000000)"
                >
                  <path
                    d="M15.6427117,0 L0.355743466,0 C0.220018047,0 0.0914360717,0.0799616031 0.0342885269,0.207173244 C-0.0228590179,0.334384886 -0.00857213172,0.483404237 0.0771491855,0.592442786 L5.33472331,7.26196741 C5.79190366,7.83987172 6.04192417,8.56679538 6.04192417,9.30825752 L6.04192417,13.5571263 C6.04192417,13.6588957 6.08478483,13.760665 6.15979098,13.8297227 L9.36005349,16.7374174 C9.4279162,16.7992059 9.51363752,16.8282828 9.59935883,16.8282828 C9.64936294,16.8282828 9.69936704,16.817379 9.74579942,16.7955713 C9.87438139,16.7374174 9.95653099,16.6065711 9.95653099,16.464821 L9.95653099,9.30825752 C9.95653099,8.56679538 10.2065515,7.83987172 10.6637319,7.26196741 L15.921306,0.592442786 C16.0070273,0.483404237 16.0248859,0.334384886 15.9641666,0.207173244 C15.9034474,0.0799616031 15.7784371,0 15.6427117,0 Z"
                    id="Shape"
                  ></path>
                </g>
              </g>
            </g>
          </svg>
        </span>
      </div>{' '}
      {t('filterPlayers')}
    </a>
  );
};

const PlayersFilter = ({ players, activePlayers, toggleActivePlayer }) => {
  const { t } = useTranslation('common');
  const toggleAll = (selectAll) => {
    if (selectAll) {
      // Add all players to activePlayers
      players.forEach((player) => {
        if (
          !activePlayers.some(
            (p) => p.person.personId === player.person.personId
          )
        ) {
          toggleActivePlayer(player.person.personId);
        }
      });
    } else {
      // Remove all players from activePlayers
      activePlayers.forEach((player) => {
        toggleActivePlayer(player.person.personId);
      });
    }
  };

  return (
    <div>
      <div className="actions mb-3">
        <button
          onClick={() => toggleAll(true)}
          className="btn btn-sm btn-primary mr-2"
        >
          {t('selectAll')}
        </button>
        <button
          onClick={() => toggleAll(false)}
          className="btn btn-sm btn-secondary"
        >
          {t('deselectAll')}
        </button>
      </div>
      {players.map((player, index) => {
        return (
          <div key={index}>
            <InputCheckboxRadio
              onChange={() => toggleActivePlayer(player.person.personId)}
              type="checkbox"
              checked={activePlayers.some(
                (p) => p.person.personId === player.person.personId
              )}
              label={`${player.person.firstName} ${player.person.lastName}`}
            />
          </div>
        );
      })}
    </div>
  );
};
/*
const _PlayerFilterAndOrder = ({
  players,
  activePlayers,
  toggleActivePlayer,
  setPlayers, // Pass a method to update the players' order
}) => {
  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return; // Drop outside a droppable area
    }

    const reorderedPlayers = Array.from(players);
    const [movedPlayer] = reorderedPlayers.splice(source.index, 1);
    reorderedPlayers.splice(destination.index, 0, movedPlayer);

    setPlayers(reorderedPlayers); // Update players order
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="playersList">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={`training-players-list ${
              snapshot.isDraggingOver ? 'dragging-over' : ''
            }`}
          >
            {players.map((player, index) => (
              <Draggable
                key={player.person.personId}
                draggableId={player.person.personId}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`d-flex player-item ${
                      snapshot.isDragging ? 'dragging' : ''
                    }`}
                  >
                    <InputCheckboxRadio
                      onChange={() =>
                        toggleActivePlayer(player.person.personId)
                      }
                      type="checkbox"
                      checked={activePlayers.some(
                        (p) => p.person.personId === player.person.personId
                      )}
                      label={`${player.person.firstName} ${player.person.lastName}`}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};
*/
