import React, { useEffect, useState } from 'react';

import { FFmpeg } from '@ffmpeg/ffmpeg';

import error from 'img/error.svg';

import audioFileImage from 'img/icons/audio_file.svg';
import videoFileImage from 'img/icons/video.svg';
import { useTranslation } from 'react-i18next';

import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import Logger from 'utils/Logger';
import { isDev } from 'lib/dev';

const compareVideoFileMetadata = (videoFiles, t) => {
  let error;
  const hasDuplicates = (arr) => arr.length !== new Set(arr).size;
  if (hasDuplicates(videoFiles.map((f) => `${f.type}.${f.name}.${f.size}`))) {
    return t('errors.duplicate');
  }
  // Bypass for single audio file only:
  if (videoFiles.length === 1) {
    return;
  }
  // Make sure all files are video files, else throw warning.

  // for (let i = 0; i < videoFiles.length; i++) {
  //   if (!videoFiles[i].type.includes('video')) {
  //     return (error = t('errors.invalidFileType', {
  //       fileName: videoFiles[i].name,
  //     }));
  //   }
  // }
  // Check if last modified is in sequence
  let modifiedDates = videoFiles.map((f) => f.lastModified);

  // Function to check if the array is sorted in increasing order
  const isSorted = (dates) => {
    for (let i = 1; i < dates.length; i++) {
      if (dates[i] < dates[i - 1]) {
        return false; // Not in sequence
      }
    }
    return true; // In sequence
  };

  // Check if the modifiedDates are in sequence
  if (!isSorted(modifiedDates)) {
    return (error = t('errors.sequence'));
  }
  // Check video files metadata
  for (let i = 0; i < videoFiles.length; i++) {
    if (i < videoFiles.length - 1) {
      const resolution1 = videoFiles[i].metadata?.resolution;
      const resolution2 = videoFiles[i + 1].metadata?.resolution;
      if (resolution1 !== resolution2) {
        return (error = t('errors.resolution', {
          r1: resolution1,
          r2: resolution2,
        }));
      } else {
      }
    }
  }

  return false;
};

export const FileUploadPreview = ({ files, onRemove, onReOrder }) => {
  const [errors, setErrors] = useState(null);

  const { t } = useTranslation('module.match.upload');

  useEffect(() => {
    setErrors(compareVideoFileMetadata(files, t));
  }, [files]);

  let totalduration = 0;
  files.length > 0 &&
    files
      ?.filter((f) => f.metadata)
      .forEach(
        (file) =>
          (totalduration += durationStringToSeconds(file.metadata.duration))
      );
  return (
    <div className={'match-upload-preview'}>
      <DragAndDrop onReOrder={onReOrder} content={files} onRemove={onRemove} />
      <div className={'d-flex'}>
        {totalduration > 0 &&
          `${t('totalDuration')} ${secondsToHHMMSS(totalduration)}`}
      </div>
      {errors && (
        <div className="userlogin__warning userlogin__warning--error">
          <b>{errors}</b>
          <br />
          {t('errors.helpText')}
        </div>
      )}
    </div>
  );
};

let ffmpegInstance; // Persistent FFmpeg instance

export const ParseVideoFile = async (file) => {
  isDev() && console.log('converting...', file);
  let metaData = [];

  try {
    // Initialize the FFmpeg instance only once
    if (!ffmpegInstance) {
      ffmpegInstance = new FFmpeg();
    }
    await ffmpegInstance.load();

    ffmpegInstance.on('log', (e) => {
      metaData.push(e.message);
    });

    await ffmpegInstance.createDir('/video');
    await ffmpegInstance.mount('WORKERFS', { files: [file] }, '/video');

    // Convert the video to an image
    await ffmpegInstance.exec([
      '-y',
      '-ss',
      '00:00:01',
      '-i',
      `/video/${file.name}`,
      '-vf',
      'select=eq(pict_type\\,I), scale=320:180',
      '-vsync',
      'vfr',
      '-vframes',
      '1',
      'out%03d.png',
    ]);

    const data = await ffmpegInstance.readFile('out001.png');

    // Create a Blob from the converted file and return a URL for it
    const videoBlob = new Blob([data?.buffer], { type: 'image/png' });
    file.previewImage = URL.createObjectURL(videoBlob);
    file.metadata = parseMetadata(metaData.join('\n'));
  } catch (e) {
    if (metaData.join('\n').includes('#0:0: Audio')) {
      file.previewImage = audioFileImage;
      file.metadata = parseMetadata(metaData.join('\n'));
    } else {
      console.warn(e);
      Logger.warning(`Cannot convert video, ${e}`);
      file.previewImage = videoFileImage;
      file.metadata = null;
    }
  } finally {
    ffmpegInstance.terminate();
  }

  if (!file.previewImage) {
    console.log(file);
    file.previewImage = error;
    file.error = true;
    return file;
  }

  return file;
};

// Helper function to parse the FFmpeg metadata logs
const parseMetadata = (logs) => {
  const metadata = {};

  // A basic parsing approach (this can be enhanced as per your needs)
  const durationMatch = logs.match(/Duration:\s(\d{2}:\d{2}:\d{2}\.\d{2})/);
  const bitrateMatch = logs.match(/bitrate:\s(\d+\skb\/s)/);
  const videoStreamMatch = logs.match(/Stream.*Video.*,\s(\d+x\d+).*?/);
  const audioStreamMatch = logs.match(/Stream.*Audio.*,\s(\d+\sHz),/);
  const fpsMatch = logs.match(/Stream.*Video.*?,\s.*?(\d+(?:\.\d+)?)\sfps/);

  if (durationMatch) metadata.duration = durationMatch[1];
  if (bitrateMatch) metadata.bitrate = bitrateMatch[1];
  if (videoStreamMatch) metadata.resolution = videoStreamMatch[1]; // Resolution
  if (fpsMatch) metadata.fps = fpsMatch[1]; // FPS
  if (audioStreamMatch) metadata.audioSampleRate = audioStreamMatch[1];
  return metadata;
};

const DragAndDrop = ({ content, onRemove, onReOrder }) => {
  const [items, setItems] = useState(content);
  // a little function to help us with reordering the result
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  useEffect(() => {
    setItems(content);
  }, [content]);

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const _items = reorder(
      items,
      result.source.index,
      result.destination.index
    );

    setItems(_items);
    // Tell uploader and uploadState files have reorderd.
    onReOrder(result.source.index, result.destination.index);
  };

  const grid = 6;
  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: '1px',
    margin: `0 0 12px 0`,
    borderRadius: '7px',

    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? 'lightblue' : 'transparent',
    padding: `${grid * 2} ${grid}`,
    width: '100%',
    marginTop: '1rem',
  });

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.map((item, index) => (
              <Draggable
                key={`${item.name}_${index}`}
                draggableId={`${item.name}_${index}`}
                index={index}
              >
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={getItemStyle(
                      snapshot.isDragging,
                      provided.draggableProps.style
                    )}
                  >
                    <DraggableClip
                      key={'i' + index}
                      preview={item}
                      onRemove={() => onRemove(item)}
                    />
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const DraggableClip = ({ preview, onRemove }) => {
  return (
    <div className={`clip draggableclip ${preview?.error ? 'is-error' : ''}`}>
      <div className="clip_l">
        <div
          className="clip_thumb"
          style={{
            backgroundImage: `url(${preview?.previewImage})`,
          }}
        ></div>
      </div>
      <div className="clip_m">
        <h4 className={'clip__overflow'}>{preview.name}</h4>
        <div className="clip_duration">
          <svg
            width="11"
            height="11"
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clipPath="url(#clip0_1008_600)">
              <path
                d="M5.49984 10.0837C8.03114 10.0837 10.0832 8.03163 10.0832 5.50033C10.0832 2.96902 8.03114 0.916992 5.49984 0.916992C2.96853 0.916992 0.916504 2.96902 0.916504 5.50033C0.916504 8.03163 2.96853 10.0837 5.49984 10.0837Z"
                stroke="#A4A4A4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.5 2.75V5.5L7.33333 6.41667"
                stroke="#A4A4A4"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_1008_600">
                <rect width="11" height="11" fill="white" />
              </clipPath>
            </defs>
          </svg>
          {preview?.metadata?.duration ?? '-'}

          <div className="clip_effect">{preview.metadata?.resolution}</div>
        </div>
      </div>
      <div className="clip_r">
        <button className="clip_button clip_button-delete" onClick={onRemove}>
          <svg
            width="15"
            height="15"
            viewBox="0 0 19 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.375 4.75H3.95833H16.625"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M15.0416 4.74967V15.833C15.0416 16.2529 14.8748 16.6557 14.5778 16.9526C14.2809 17.2495 13.8782 17.4163 13.4583 17.4163H5.54159C5.12166 17.4163 4.71893 17.2495 4.422 16.9526C4.12507 16.6557 3.95825 16.2529 3.95825 15.833V4.74967M6.33325 4.74967V3.16634C6.33325 2.74641 6.50007 2.34369 6.797 2.04676C7.09393 1.74982 7.49666 1.58301 7.91659 1.58301H11.0833C11.5032 1.58301 11.9059 1.74982 12.2028 2.04676C12.4998 2.34369 12.6666 2.74641 12.6666 3.16634V4.74967"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7.91675 8.70801V13.458"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.0833 8.70801V13.458"
              stroke="black"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

const durationStringToSeconds = (duration) => {
  var a = duration.split(':'); // split it at the colons
  // minutes are worth 60 seconds. Hours are worth 60 minutes.
  var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2];
  return seconds;
};

const secondsToHHMMSS = (seconds) => {
  return [
    parseInt(seconds / 60 / 60),
    parseInt((seconds / 60) % 60),
    parseInt(seconds % 60),
  ]
    .join(':')
    .replace(/\b(\d)\b/g, '0$1');
};
