import { useTranslation, withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import moment from 'moment';
import { Session } from '../../../../domain/Session';
import { MatchOverviewItem } from '../MatchOverviewItem';
import { ObservationInputNav } from '../../../observe/components/InputNav';
import { getLabel } from './index';
import _ from 'lodash';

import { gotoRoute } from '../../../route';

export const FutureMatchRow = withTranslation('module.match')(
  observer(
    class MatchRow extends Component {
      render() {
        const sportingEvent = this.props.sportingEvent;

        const scheduledAt = moment(sportingEvent.get('scheduledAt'));
        const today = !!scheduledAt.isSame(moment(), 'day');
        const active = sportingEvent.started();

        // Check if match is currently live (10 min before 10 min after)
        // todo: Fix check if sportingEvent is currently live
        const currentSession = Session.current();

        const primaryActions = [];
        if (
          this.props.onManageLineUp &&
          currentSession.isFeatureAvailable('manageLineUp')
        ) {
          primaryActions.push({
            onClick: async () => {
              const sportingEventId =
                await sportingEvent.getPrivilegedToSportingEventId('observe');
              this.props.onManageLineUp(sportingEventId);
            },
            icon: 'lineup',
            label: this.props.t('manageLineUp'),
          });
        }

        if (today) {
          if (currentSession.isFeatureAvailable('liveObserve')) {
            primaryActions.push(
              <ObservationInputNav
                key="observation-input-nav-observe"
                live={true}
                // urlParams={{ sportingEventId: sportingEvent.id }}
                urlParams={{ sportingEventId: sportingEvent.id }}
              >
                <span
                  data-action={'liveTagging'}
                  className="overview-item-action clickable"
                >
                  <i className="i-observe i-light" />
                  <span className="overview-item-action-label">
                    {this.props.t('liveObserve')}
                  </span>
                </span>
              </ObservationInputNav>
            );
          }

          if (
            currentSession.isFeatureAvailable('liveRecord') &&
            !sportingEvent.isLiveStreamAvailable() &&
            !sportingEvent.tags.automaticCameraConfig
          ) {
            primaryActions.push(
              <ObservationInputNav
                key="observation-input-nav-live"
                // urlParams={{ sportingEventId: sportingEvent.id }}
                urlParams={{ sportingEventId: sportingEvent.id }}
              >
                <span
                  data-action={'liveRecording'}
                  className="overview-item-action clickable"
                >
                  <i className="i-liverecord i-light" />
                  <span className="overview-item-action-label">
                    {this.props.t('videoObserveLive')}
                  </span>
                </span>
              </ObservationInputNav>
            );
          }

          if (
            currentSession.isFeatureAvailable('play') &&
            sportingEvent?.tags?.automaticCameraConfig &&
            sportingEvent.isLiveStreamAvailable()
          ) {
            primaryActions.push(
              <LiveView
                key={'sportingEventLiveStream'}
                sportingEvent={sportingEvent}
                t={this.props.t}
                type={'play'}
              />
            );
          }

          if (
            currentSession.isFeatureAvailable('liveObserve') &&
            sportingEvent?.tags?.automaticCameraConfig &&
            sportingEvent.isLiveStreamAvailable()
          ) {
            primaryActions.push(
              <LiveView
                key={'sportingEventLiveStream'}
                sportingEvent={sportingEvent}
                t={this.props.t}
              />
            );
          }

          if (
            this.props.onOpenReport &&
            currentSession.isFeatureAvailable('liveReport')
          ) {
            primaryActions.push({
              onClick: async () => {
                const sportingEventId =
                  await sportingEvent.getPrivilegedToSportingEventId('observe');
                this.props.onOpenReport(sportingEventId);
              },
              icon: 'stats',
              label: this.props.t('liveReport'),
            });
          }
        }
        const canOpenIntegrations =
          currentSession.isFeatureAvailable('broadcast'); // Permissions here?
        if (sportingEvent.automaticCameraConfig && canOpenIntegrations) {
          primaryActions.push({
            onClick: () => {
              // open integrations
              this.props.onOpenIntegrations();
            },
            icon: 'cast',
            label: 'Broadcast',
          });
        }

        // TODO: @koenvo check for some video ready state?
        // if (videoState === 'ready') {
        // if (
        //   sportingEvent.privilegedTo('observe') &&
        //   currentSession.currentPrivileges().hasPrivilege('CustomTag:view')
        // ) {

        // }
        // }

        const secondaryActions = [];

        if (currentSession.isFeatureAvailable('livestreamOverlay')) {
          secondaryActions.push({
            onClick: async () => {
              const sportingEventId =
                await sportingEvent.getPrivilegedToSportingEventId('observe');
              this.props.onLivestreamOverlay(sportingEventId);
            },
            label: 'Livestream overlay',
          });
        }
        if (currentSession.isFeatureAvailable('viewSportingEventDetails')) {
          secondaryActions.push({
            onClick: this.props.onOpenDetail,
            label: 'Details',
          });
        }

        if (currentSession.isFeatureAvailable('editSportingEvent')) {
          secondaryActions.push({
            onClick: this.props.onEditSportingEvent,
            label: _.upperFirst(
              this.props.t('editSportingEvent', {
                type: `${this.props.t(`type.${sportingEvent.type}`)}`,
              })
            ),
          });
        }
        if (currentSession.isFeatureAvailable('editShareSportingEvent')) {
          secondaryActions.push({
            onClick: this.props.onEditShareSportingEvent,
            label: this.props.t('editTags'),
          });
        }
        if (
          currentSession
            .currentPrivileges()
            .hasPrivilege('resourceGroup:set-resource-acl')
        ) {
          secondaryActions.push({
            onClick: this.props.onOpenSetACL,
            label: this.props.t('changePermissions'),
          });
        }

        if (sportingEvent.privilegedTo('destroy', true)) {
          if (!sportingEvent.isCopy()) {
            secondaryActions.push({
              onClick: this.props.onDeleteSportingEvent,
              label: _.upperFirst(
                this.props.t('deleteSportingEvent', {
                  type: `${this.props.t(`type.${sportingEvent.type}`)}`,
                })
              ),
            });
          } else {
            secondaryActions.push({
              onClick: () => this.props.onDeleteAnalysis(sportingEvent),
              label: this.props.t('deleteAnalysis'),
            });
          }
        }

        const label = sportingEvent?.isLive()
          ? 'LIVE'
          : getLabel(sportingEvent, this.props.t);

        const itemProps = {
          sportingEvent,
          emphasis: today,
          active,
          label,
          primaryActions,
          secondaryActions,
        };

        return <MatchOverviewItem {...itemProps} />;
      }
    }
  )
);

const LiveView = ({ sportingEvent, type = 'observe' }) => {
  const { t } = useTranslation('module.match');
  if (type === 'play') {
    return (
      <span
        data-action={'liveView'}
        className="overview-item-action clickable"
        onClick={() =>
          gotoRoute(`play.${sportingEvent.type ?? 'match'}`, {
            sportingEventId: sportingEvent.id,
          })
        }
      >
        <i className="i-livestream-watch" />
        <span className="overview-item-action-label">
          {t('livestreamWatch')}
        </span>
      </span>
    );
  }

  return (
    <ObservationInputNav
      key="observation-input-nav"
      // urlParams={{ sportingEventId: sportingEvent.id }}
      live={false}
      urlParams={{ sportingEventId: sportingEvent.id }}
    >
      <span
        data-action={'liveObserve'}
        className="overview-item-action clickable"
      >
        <i className="i-livestream-observe" />
        <span className="overview-item-action-label">
          {t('livestreamObserve')}
        </span>
      </span>
    </ObservationInputNav>
  );
};
