import React, { useMemo } from 'react';
import { observer } from 'mobx-react';

import { FormattedTimeObserver } from 'lib/FormattedTime';
import ScoreBoardNav from './ScoreBoardNav';

const DefaultScoreBoard = observer(({ observationContext }) => {
  return (
    <DefaultScoreBoardView
      homeTeamLabel={observationContext.homeTeam.shortLabel}
      homeTeamLogoUrl={observationContext.homeTeam.logoUrl}
      homeTeamScore={observationContext.homeGoalCounter}
      time={<FormattedTimeObserver timeFn={observationContext.clockTimeFn} />}
      awayTeamLabel={observationContext.awayTeam.shortLabel}
      awayTeamLogoUrl={observationContext.awayTeam.logoUrl}
      awayTeamScore={observationContext.awayGoalCounter}
      sportingEvent={observationContext.sportingEvent}
    />
  );
});

const DefaultScoreBoardView = ({
  homeTeamLabel,
  homeTeamLogoUrl,
  homeTeamScore,
  time,
  awayTeamLabel,
  awayTeamLogoUrl,
  awayTeamScore,
  sportingEvent,
}) => {
  const memoizedSportingEvent = useMemo(() => sportingEvent, [sportingEvent]);
  return (
    <div className="video-scoreboard">
      <ScoreBoardNav sportingEvent={memoizedSportingEvent} />
      <div className="video-scoreboard-team video-scoreboard-team-home">
        <div className="video-scoreboard-team-name">{homeTeamLabel}</div>
        <div
          className="video-scoreboard-team-icon"
          style={{
            backgroundImage: `url(${homeTeamLogoUrl})`,
          }}
        />
        <div className="video-scoreboard-team-score">{homeTeamScore}</div>
      </div>
      {time}
      <div className="video-scoreboard-team video-scoreboard-team-away">
        <div className="video-scoreboard-team-name">{awayTeamLabel}</div>
        <div
          className="video-scoreboard-team-icon"
          style={{
            backgroundImage: `url(${awayTeamLogoUrl})`,
          }}
        />
        <div className="video-scoreboard-team-score">{awayTeamScore}</div>
      </div>
    </div>
  );
};

export { DefaultScoreBoard, DefaultScoreBoardView };
