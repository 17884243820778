import React, { Fragment } from 'react';
import { DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { NavDropDown } from 'lib/NavDropdown';
import MediaQuery from 'react-responsive';
import moment from 'moment';

import './index.scss';

const Action = ({ icon, onClick, link, light, className, actionId }) => {
  const iconElement = (
    <i className={`i-${icon} i-${!light ? 'dark' : 'light'}`} />
  );
  if (link) {
    return (
      <a href={link} className={`overview-item-action ${className}`}>
        {iconElement}
      </a>
    );
  } else {
    return (
      <span
        onClick={onClick}
        data-action={actionId}
        className={`overview-item-action ${
          onClick ? 'clickable' : ''
        } ${className}`}
      >
        {iconElement}
      </span>
    );
  }
};

const ResponsiveOverviewItem = ({
  primaryActions,
  secondaryActions,
  ...props
}) => {
  return (
    <MediaQuery query="(orientation: portrait) and (max-width: 767px)">
      {(mediaQueryMatches) => {
        const itemProps = {
          primaryActions: mediaQueryMatches
            ? primaryActions.filter((a) => {
                return (
                  a.label === 'Play' || a?.key === 'sportingEventLiveStream'
                );
              }) // Show play button on mobile.
            : primaryActions,
          secondaryActions: primaryActions.concat(secondaryActions),
          ...props,
        };
        return <OverviewItem {...itemProps} />;
      }}
    </MediaQuery>
  );
};
ResponsiveOverviewItem.defaultProps = {
  primaryActions: [],
  secondaryActions: [],
};

const OverviewItem = ({
  itemDate,
  icon,
  title,
  features,
  emphasis,
  active,
  inactive,
  label,
  isDemo,
  primaryActions,
  secondaryActions,
  details,
  onClick,
}) => {
  const onClickHandler = (e) => {
    if (!!onClick) {
      // make sure we didn't click a dropdown or other action
      // and don't use while loop...
      let currentElement = e.target;
      for (let i = 0; i < 10; i++) {
        if (currentElement.classList.contains('overview-item-actions')) {
          return;
        }
        if (currentElement.classList.contains('overview-item-banners')) {
          return;
        }
        if (currentElement.classList.contains('overview-item')) {
          onClick();
          break;
        }
        currentElement = currentElement.parentElement;
      }
    }
  };
  return (
    <div
      className={classNames({
        emphasis,
        active,
        inactive,
        primaryActions,
        icon,
        onClick,
        isDemo,
      })}
      onClick={onClickHandler}
    >
      {label && <div className="overview-item-label">{label}</div>}
      {React.isValidElement(itemDate) && itemDate}
      {!React.isValidElement(itemDate) && itemDate && (
        <div className="overview-item-date">
          <div className="overview-item-date-day-of-month">
            {itemDate.format('DD')}
          </div>
          <div className="overview-item-date-month">
            {itemDate.format('MMM').replace(/\./g, '')}
          </div>
          {itemDate.format('Y') !== moment().format('Y') && (
            <div className="overview-item-date-year">
              {itemDate.format('YYYY').replace(/\./g, '')}
            </div>
          )}
          {itemDate.format('Y') === moment().format('Y') &&
            itemDate.isAfter(moment(), 'day') && (
              <div className="overview-item-date-time">
                {itemDate.format('H:mm').replace(/\./g, '')}
              </div>
            )}
        </div>
      )}
      {icon && (
        <div
          className="overview-item-icon"
          style={{
            backgroundImage: `url('${icon}')`,
          }}
        />
      )}
      {title && <div className="overview-item-title">{title}</div>}
      <div className="overview-item-details">{details && details()}</div>
      {features && <div className="overview-item-features">{features}</div>}
      {(primaryActions || secondaryActions) && ( // Only render component with actions
        <div className={`overview-item-actions`}>
          {primaryActions && (
            <div className="overview-item-actions-primary">
              {primaryActions.map((action, n) => {
                return React.isValidElement(action) ? (
                  <Fragment key={`primary_action-${n}`}>{action}</Fragment>
                ) : (
                  <Action
                    key={`primary_action-${n}`}
                    icon={action.icon}
                    onClick={action.onClick}
                    link={action.link}
                    className={action.className}
                    light={action.light}
                    actionId={action.id}
                  />
                );
              })}
            </div>
          )}
          {secondaryActions && secondaryActions.length > 0 && (
            <div className="overview-item-actions-secondary">
              <NavDropDown>
                <DropdownToggle color="link" className="overview-item-action">
                  <i className="i-dots" />
                </DropdownToggle>
                <DropdownMenu right>
                  {secondaryActions.map((action, n) => {
                    return (
                      <Fragment key={`secondary_action-${n}`}>
                        {n > 0 && <DropdownItem divider />}
                        {React.isValidElement(action) ? (
                          <DropdownItem>{action}</DropdownItem>
                        ) : (
                          <DropdownItem
                            data-action={action.id}
                            onClick={action.onClick}
                          >
                            {action.label}
                          </DropdownItem>
                        )}
                      </Fragment>
                    );
                  })}
                </DropdownMenu>
              </NavDropDown>
            </div>
          )}
        </div>
      )}
      {primaryActions && (
        <div className="overview-item-banners">
          {primaryActions.map((action, n) => (
            <div key={n} className="overview-item-banner">
              {React.isValidElement(action) ? (
                action
              ) : (
                <Action
                  icon={action.icon}
                  onClick={action.onClick}
                  link={action.link}
                  light={true}
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const classNames = ({
  emphasis,
  active,
  inactive,
  primaryActions,
  icon,
  onClick,
  isDemo,
}) => `
    overview-item
    ${
      (active || emphasis) && primaryActions.length > 0
        ? 'overview-item-has-banners'
        : ''
    }
    ${icon ? 'overview-item-with-icon' : ''}
    ${emphasis ? 'overview-item-em' : ''}
    ${inactive ? 'overview-item-inactive' : ''}
    ${active ? 'overview-item-active' : ''}
    ${onClick ? 'clickable' : ''}
    ${isDemo ? 'demo' : ''}
  `;

export { ResponsiveOverviewItem as OverviewItem };
