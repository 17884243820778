import { Breadcrumb, EventHint } from '@sentry/react';
import { Event } from '@sentry/types/dist/event';

export function shouldIgnoreError(error: Event, hint: EventHint): boolean {
  return (
    isRecaptchaBadTimeoutRejection(error, hint) ||
    isNetworkError(error) ||
    is418(error)
  );
}

// https://github.com/getsentry/sentry-javascript/issues/2514
function isRecaptchaBadTimeoutRejection(_: Event, hint: EventHint): boolean {
  return hint.originalException === 'Timeout';
}

const typeErrorFetchFailedValues = new Set([
  'Failed to fetch',
  'NetworkError when attempting to fetch resource.',
  'Load failed',
]);

function isNetworkError(error: Event): boolean {
  const exception = error.exception?.values?.[0];
  const now = Date.now();

  if (
    exception?.type !== 'TypeError' ||
    !typeErrorFetchFailedValues.has(exception.value as string)
  ) {
    return false;
  }

  if (!error.breadcrumbs) {
    return false;
  }

  // We go from the back since the last breadcrumb is most likely the erroneous one
  for (let i = error.breadcrumbs.length - 1; i >= 0; i--) {
    const breadcrumb = error.breadcrumbs[i];
    if (!breadcrumb) continue;

    // We only need to check the last 5s of breadcrumbs as any earlier breadcrumbs are definitely unrelated
    if (breadcrumb.timestamp && now - breadcrumb.timestamp * 1000 > 5000) {
      break;
    }

    if (isErroneousBreadcrumb(breadcrumb)) {
      return true;
    }
  }

  return false;
}

function isErroneousBreadcrumb(breadcrumb: Breadcrumb): boolean {
  if (
    breadcrumb.level !== 'error' ||
    (breadcrumb.category !== 'xhr' && breadcrumb.category !== 'fetch')
  ) {
    return false;
  }

  const url = breadcrumb.data?.url as string | undefined;
  if (!url) return false;

  return (
    url === 'urlThatIsOftenBlocked' ||
    url.startsWith('startOfUrlThatIsOftenBlocked')
  );
}

function is418(error: Event): boolean {
  return (
    error?.exception?.values[0]?.value === 'Request failed with status code 418'
  );
}
