import React, { Children, useState, useEffect } from 'react';

import { ObservationLogVideoPlayer } from 'lib/Observation/ObservationLogVideoPlayer';
import styles from './index.module.scss';
import { TimeSlider } from 'lib/TimeSlider/index';

import { ObservationDetailsNavigationButtons } from './navigation-buttons';
import { ObservationDetailsFormButtons } from './form-buttons';
import { Modal } from 'lib/Modal';
import uuidv4 from 'uuid/v4';

const Column = ({ title, children }) => {
  return (
    <>
      <h4>{title}</h4>
      {children}
    </>
  );
};

const Input = ({ label, children }) => {
  return (
    <div className="form-group">
      <label htmlFor="">{label}</label>
      {children}
    </div>
  );
};

const ObservationDetailsContainer = ({
  observationId,
  title,
  children,
  video,
  startTime,
  endTime,
  observationProps,
  observation,
  onUpdateObservation,
  onDelete,
  onClose,
}) => {
  // const fixedStartTime = startTime < 8000 ? startTime : 0;
  // const fixedEndTime = endTime < 8000 ? endTime : 0;
  const [newStartTime, setNewStartTime] = useState(startTime);
  const [newEndTime, setNewEndTime] = useState(endTime);
  const _currentObservationId = observationId || observation.observationId;

  const [currentObservationId, setcurrentObservationId] = useState(
    _currentObservationId
  );

  const [playerId] = useState(`observation-player-${uuidv4()}`);

  useEffect(() => {
    setNewStartTime(startTime);
    setNewEndTime(endTime);
    setcurrentObservationId(observationId || observation.observationId);
  }, [_currentObservationId, startTime, endTime]);

  const columns = Children.toArray(children);

  const updateObservationHandler = async (toUpdateObservationId) => {
    // check if the correct observation will be updated else skip changes.
    // Wrong input can occur when navigating through observations before it has fully loaded.
    if (toUpdateObservationId === currentObservationId) {
      onUpdateObservation({
        startTime: newStartTime,
        endTime: newEndTime,
        ...observationProps,
      });
    } else {
      console.warn(
        'Incorrect observationId, this probably happened when updating before context was loaded propely'
      );
    }
  };

  const playerObservation = {
    ...observation,
    startTime: newStartTime,
    endTime: newEndTime,
  };

  if (video) {
    return (
      <Modal size="full-screen" onCloseClick={onClose}>
        <div className={styles.container}>
          <div className={styles.firstColumn}>
            <div className="form-group">
              <ObservationLogVideoPlayer
                repeat
                video={video}
                observations={[playerObservation]}
                playerId={playerId}
                heightAttribute="height"
              />
            </div>
            <div className="form-group slider-container">
              <TimeSlider
                min={Math.max(0, startTime - 20)}
                max={Math.min(endTime + 20, video.duration)}
                startTime={newStartTime}
                endTime={newEndTime}
                onChange={([start, end]) => {
                  // WARN: yeahh....
                  if (Math.round(newStartTime) !== Math.round(start)) {
                    setNewStartTime(start);
                  }
                  if (Math.round(newEndTime) !== Math.round(end)) {
                    setNewEndTime(end);
                  }
                }}
              />
              <ObservationDetailsNavigationButtons
                onUpdate={updateObservationHandler}
                onDelete={onDelete}
              />
            </div>
            {columns[0]}
          </div>

          <div className={styles.secondColumn}>
            <h4>{title}</h4>
            {columns[1]}
            <ObservationDetailsFormButtons
              onUpdate={updateObservationHandler}
              onDelete={onDelete}
            />
            {columns[2]}
          </div>
        </div>
      </Modal>
    );
  } else {
    return (
      <Modal size="md" onCloseClick={onClose}>
        <div className={styles.container}>
          <div className={styles.fullWidthColumn}>
            <h4>{title}</h4>
            {columns[1]}
            <ObservationDetailsFormButtons
              onUpdate={updateObservationHandler}
              onDelete={onDelete}
            />
            {columns[0]}
          </div>
        </div>
      </Modal>
    );
  }
};

export { ObservationDetailsContainer, Input, Column };
