import moment from 'moment';
import RecSVG from '../../../../img/icons/autocam_icon.svg';
import ReactTooltip from 'react-tooltip';
import React, { useEffect, useState } from 'react';
import { Session } from 'domain/Session';
import { Trans, useTranslation } from 'react-i18next';
import Select from 'react-select';
import { getFields } from 'domain/AutomaticCamera/Field';
import { useQuery } from 'react-query';

const getDurationOptions = (baseduration) => {
  const durationOptions = [];
  const maxIndex = Math.floor(baseduration / 5) + 12;
  // Min 15 minutes, max `baseDuration` + one hour
  for (let i = 3; i < maxIndex; i++) {
    durationOptions.push({
      label: `${5 * i} min`,
      value: 5 * i,
    });
  }
  return durationOptions;
};

const CheckboxScheduling = ({
  callback,
  values,
  type = 'match',
  errors,
  validClassName,
}) => {
  const currentSession = Session.current();
  const isAvailable = currentSession.isFeatureAvailable('automaticCamera');
  const canUseTaggingData = currentSession.isFeatureAvailable(
    'automaticCameraUseTaggingData'
  );
  const canUseLivestream = currentSession.isFeatureAvailable(
    'automaticCameraUseLivestream'
  );
  const sportRules = currentSession.getSportRules();

  const timing = {
    before: sportRules.recordingBeforeMargin ?? 10,
    duration: sportRules.defaultRecordingDuration ?? 90,
    unit: 'minutes',
  };
  const { t } = useTranslation();

  const mergeValues = (newValue) => {
    return { ...values.automaticCameraConfig, ...newValue };
  };

  const [durationOptions, setDurationOptions] = useState(
    getDurationOptions(timing.duration)
  );

  const { data: fields } = useQuery(['fields'], () => getFields(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (fields?.length) {
      callback(mergeValues({ fieldId: 'unset' }));
    }
  }, [fields]);

  return (
    <div className={`schedule-row ${isAvailable ? 'enabled' : 'disabled'}`}>
      <div className="form-check">
        <input
          id={'auto'}
          type={'checkbox'}
          className="form-check-input"
          checked={
            values.automaticCameraConfig && values.automaticCameraConfig.record
              ? 'checked'
              : false
          }
          disabled={!isAvailable}
          onChange={(e) => {
            return (
              isAvailable &&
              callback(
                mergeValues(
                  e.target.checked
                    ? {
                        record: true,
                        // Always set recording duration when recording is true.
                        recordingDuration:
                          values.automaticCameraConfig?.recordingDuration ??
                          timing.duration,
                      }
                    : { record: false }
                )
              )
            );
          }}
        />
        <label htmlFor={'auto'} className="form-check-label">
          {t('module.match:recordingEnable', {
            type: `${t(`module.match:type.${type}`)}`,
          })}
        </label>
        {fields?.length > 0 && values.automaticCameraConfig?.record && (
          <div
            className={`form-group ${validClassName('fieldId')}`}
            style={{ paddingRight: '12px', marginTop: '12px' }}
          >
            <Select
              onChange={(v) => callback(mergeValues({ fieldId: v.value }))}
              id={'automaticCamera-select-field'}
              value={
                fields
                  .map(({ fieldId, name }) => ({
                    label: name,
                    value: fieldId,
                  }))
                  .filter(
                    (fieldOption) =>
                      fieldOption.value ===
                      values.automaticCameraConfig?.fieldId
                  )[0]
              }
              placeholder={'select a field'}
              required={true}
              options={fields.map(({ fieldId, name }) => ({
                label: name,
                value: fieldId,
              }))}
            />
            {errors?.fieldId && (
              <div className="invalid-feedback">
                {t('common.form:required-field')}
              </div>
            )}
          </div>
        )}

        {values.automaticCameraConfig?.record && (
          <div
            className="form-group"
            style={{ paddingRight: '12px', marginTop: '12px' }}
          >
            <Select
              onChange={(v) =>
                callback(mergeValues({ recordingDuration: v.value }))
              }
              value={
                durationOptions.filter(
                  (fieldOption) =>
                    fieldOption.value ===
                    values.automaticCameraConfig?.recordingDuration
                )[0]
              }
              placeholder={timing.duration + ' min'}
              options={durationOptions}
            />
          </div>
        )}
        {values.scheduledAt && values.automaticCameraConfig?.record && (
          <div className={'scheduling-helptext'}>
            <Trans
              i18nKey={'module.match:recordingTime'}
              values={{
                sTime: moment(values.scheduledAt)
                  .subtract(timing.before, timing.unit)
                  .format('HH:mm'),
                eTime: moment(values.scheduledAt)
                  .add(
                    values.automaticCameraConfig?.recordingDuration ??
                      timing.duration,
                    timing.unit
                  )
                  .format('HH:mm'),
              }}
            >
              Recording starts at <strong>sTime</strong> and ends at
              <b>eTime</b>.
            </Trans>
            {values.automaticCameraConfig?.field &&
              ` on ${values.automaticCameraConfig?.field.label}`}
          </div>
        )}

        {values.automaticCameraConfig?.record && canUseTaggingData && (
          <div className="form-group my-2">
            <div className="form-check">
              <input
                id={'useTaggingData'}
                type={'checkbox'}
                className="form-check-input"
                checked={values.automaticCameraConfig?.useTaggingData}
                onChange={(e) => {
                  return (
                    isAvailable &&
                    callback(mergeValues({ useTaggingData: e.target.checked }))
                  );
                }}
              />
              <label htmlFor={'useTaggingData'} className="form-check-label">
                {t('module.match:useTaggingData')}
              </label>
            </div>
          </div>
        )}

        {values.automaticCameraConfig?.record && canUseLivestream && (
          <div className="form-group my-2">
            <label>LiveStream URL</label>
            <input
              value={values.automaticCameraConfig?.livestreamUrl}
              placeholder={'rtmp://....'}
              type={'text'}
              className={'form-control'}
              onChange={(e) => {
                return (
                  isAvailable &&
                  callback(mergeValues({ livestreamUrl: e.target.value }))
                );
              }}
            />
          </div>
        )}

        {!isAvailable && (
          <div className={'scheduling-helptext'}>
            <Trans Trans i18nKey={'module.match:upgradeNeeded'}>
              <a href={'https://teamtvsport.com/products/automatic-camera/'}>
                upgrade your plan
              </a>
              To enable automatic recording.
            </Trans>
          </div>
        )}
        <div
          data-tip=""
          data-for={'schedule'}
          className={`addmatch-scheduling ${
            values.automaticCameraConfig?.record ? 'active' : ''
          }`}
        >
          <img
            className="scheduling-icon"
            src={RecSVG}
            alt="Automatic recording icon"
          />
        </div>
        <ReactTooltip
          id="schedule"
          delayHide={500}
          place="top"
          type="dark"
          effect="solid"
          clickable={true}
          className={'tooltip--styled'}
          backgroundColor="#495057"
        >
          {values.automaticCameraConfig?.record && (
            <>
              {' '}
              <p
                style={{
                  margin: '0px',
                  marginBottom: '4px',
                  lineHeight: '1.1',
                }}
              >
                {t('module.match:tooltipScheduled')}
              </p>
              <a
                target={'_blank'}
                href={'https://teamtvsport.com/products/automatic-camera/'}
                rel="noreferrer"
              >
                {t('common:learnMore')}
              </a>
            </>
          )}
          {!values.automaticCameraConfig?.record && isAvailable && (
            <>
              <p
                style={{
                  margin: '0px',
                  marginBottom: '4px',
                  lineHeight: '1.1',
                }}
              >
                {t('module.match:tooltipScheduledOff')}
              </p>
              <a
                target={'_blank'}
                href={'https://teamtvsport.com/products/automatic-camera/'}
                rel="noreferrer"
              >
                {t('common:learnMore')}
              </a>
            </>
          )}

          {!isAvailable && (
            <>
              <p
                style={{
                  margin: '0px',
                  marginBottom: '4px',
                  lineHeight: '1.1',
                }}
              >
                {t('module.match:tooltipScheduledDisabled')}
              </p>
              <a
                target={'_blank'}
                href={'https://teamtvsport.com/products/automatic-camera/'}
                rel="noreferrer"
              >
                {t('module.match:tooltipScheduledDisabledUpgrade')}
              </a>
            </>
          )}
        </ReactTooltip>
      </div>
    </div>
  );
};

export default CheckboxScheduling;
