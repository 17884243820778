import { Formik } from 'formik';
import { MinSecInput } from '../../highlights/clip-edit-view';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import videoCollection from 'domain/Video';
import ReactJWPlayer from 'react-jw-player';
import { SubmittingButton } from '../../../lib/SubmittingButton';
import { ReactPlayer } from '../../../lib/ReactPlayer';
import VideoAngleContextProvider from '../../../lib/VideoAngleContextProvider';

export const EditFragment = ({ videoFragment, callback, onCancel }) => {
  const initialValues = { ...videoFragment };
  const [video, setVideo] = useState(videoFragment.getVideo());
  const [angles, setAngles] = useState([]);
  const [currentAngle, setCurrentAngle] = useState(videoFragment.videoId);

  const videos = videoCollection.toArray((v) => v.toJS());

  useEffect(() => {
    let _angles = [];
    console.log('EditFragment', video);
    let otherAngles = video.getOtherAngles();
    console.log('EditFragment', otherAngles);
    if (otherAngles) {
      for (const angle of otherAngles) {
        for (const video of videos) {
          if (
            video.videoId === angle &&
            (video.state === 'ready' || video.has('livestream'))
          ) {
            _angles.push({
              label: video?.tags?.output_key ?? `stream ${_angles.length + 1}`,
              value: angle,
              thumbnail: video.thumbnailUrl,
              video: video,
            });
            break;
          }
        }
      }
      setAngles(_angles);
      for (const angle of _angles) {
        if (video.videoUri.includes(angle.value)) {
          setCurrentAngle(angle.value);
        }
      }
    }
  }, []);

  const validate = (values) => {
    let errors = {};
    if (!values.description || values.description === '') {
      errors.description = true;
    }
    if (
      (!values.startTime && values.startTime != 0) ||
      values.startTime <= -1
    ) {
      errors.startTime = true;
    }
    if (!values.endTime || values.endTime <= values.startTime) {
      errors.endTime = true;
    }
    if (values?.endTime > video.duration) {
      errors.endTime = true;
    }

    return errors;
  };

  const validClassName = ({ submitCount, errors, key }) => {
    return errors[key] ? 'is-invalid' : 'is-valid';
  };
  const { t } = useTranslation();

  return (
    <Formik initialValues={initialValues} validate={validate}>
      {({
        handleChange,
        values,
        submitCount,
        errors,
        setFieldValue,
        onReset,
        validateForm,
      }) => {
        const getPlaylist = (_video = video) => {
          return [
            {
              file: _video.getVideoFragmentUrl(
                Math.floor(values.startTime),
                Math.floor(values.endTime)
              ),
              videoFragment: _video.getVideoFragment(
                Math.floor(values.startTime),
                Math.floor(values.endTime)
              ),
            },
          ];
        };
        return (
          <form onSubmit={(e) => e.preventDefault()}>
            <div className="modal-header">
              <h5>
                {values?.videoFragmentId?.includes('copy')
                  ? t('module.highlights:copyClip')
                  : t('module.highlights:editClip')}
              </h5>
            </div>
            <div
              className={`form-group ${validClassName({
                errors,
                key: 'description',
                submitCount,
              })}`}
            >
              <label>{t('common:description')}</label>
              <input
                type={'text'}
                className="form-control"
                name={'description'}
                value={values.description}
                onChange={handleChange}
              />
              <div className="invalid-feedback">
                {t('common.form:required-field')}
              </div>
            </div>
            <div className="col-12 col-md-12 mb-3">
              {!errors.endTime && (
                <VideoAngleContextProvider>
                  <ReactPlayer
                    playerId="playerId2"
                    playlistItems={getPlaylist()}
                    onReady={() => {}}
                    noDimensions
                  />
                </VideoAngleContextProvider>
              )}
              {errors.endTime && (
                <div
                  style={{
                    aspectRatio: '16/9',
                    display: 'flex',
                    alignContent: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'black',
                    color: 'white',
                    alignItems: 'center',
                  }}
                >
                  <i className="i-warning i-light i-sm"></i>
                  {t('common.form:invalidDuration')}
                </div>
              )}
            </div>
            {angles && angles.length > 1 && (
              <div className="form-group">
                <label htmlFor="match">
                  {t('module.highlights:selectStream')}:
                </label>
                <div className="select-stream">
                  {angles.map((angle) => {
                    return (
                      <div
                        key={angle.value}
                        className={
                          angle.value === currentAngle ? 'selected' : ''
                        }
                        onClick={() => {
                          setCurrentAngle(angle.value);
                          setFieldValue('videoId', angle.value);
                          setVideo(angle.video);
                          window
                            .jwplayer('playerId2')
                            .load(getPlaylist(angle.video));
                        }}
                      >
                        <img src={angle.thumbnail} alt="" />
                        <span>{angle.label}</span>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {['startTime', 'endTime'].map((type) => (
              <div
                className={`form-group ${validClassName({
                  errors,
                  key: type,
                  submitCount,
                })}`}
              >
                <label>
                  {t(`module.highlights:${type.substring(0, type.length - 4)}`)}
                </label>
                <MinSecInput
                  totalSeconds={values[type]}
                  onChange={(value) => setFieldValue(type, value)}
                />
                <div className="invalid-feedback">
                  {t('common.form:invalidDuration')}
                </div>
              </div>
            ))}
            <div className="modal-footer">
              <div className="form-actions">
                <SubmittingButton
                  onClick={async () => await callback(values)}
                  className={'btn btn-primary'}
                >
                  {t('common:save')}
                </SubmittingButton>
                <button className={'btn btn-link'} onClick={onCancel}>
                  {t('common:cancel')}
                </button>
              </div>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};
