import AttributeBag from './AttributeBag';
import { observable, runInAction } from 'mobx';
import { EventBus, DomainEvent } from '../../infra/Messaging';

class TimerProxy {
  constructor(timeObservable) {
    this.timeObservable = timeObservable;
  }

  getTime() {
    return this.timeObservable.get();
  }
}

class EventBased {
  handleEvent(event) {
    throw 'Implemented me';
  }

  apply(event) {
    this.handleEvent(event);

    EventBus.dispatch(event);
  }
}

class ObservationLogger extends EventBased {
  constructor(observationLog, clock, attributesExtender) {
    super();

    this.clock = clock;

    this.resetHooks();

    this.observationLog = observationLog;

    this.partialObservations = {};
    this.partialObservationCounter = 0;
    this.previousTime = null;
    this.previousTimeDelta = 0;
    this.attributesExtender = attributesExtender;
    this.partialObservationIdPerGroup = observable.map();
  }

  handleEvent(event) {
    switch (event.eventType) {
      case 'ObservationLogger.ObservationAttributesSet':
        return this.whenObservationAttributesSet(event);
      case 'ObservationLogger.RangeObservationStarted':
        return this.whenRangeObservationStarted(event);
      case 'ObservationLogger.RangeObservationEnded':
        return this.whenRangeObservationEnded(event);
      case 'ObservationLogger.PartialObservationRemoved':
        return this.whenPartialObservationRemoved(event);
      case 'ObservationLogger.EmptyRangeObservationEnded':
        return this.whenEmptyRangeObservationEnded(event);
      case 'ObservationLogger.ObservationUpdated':
        break;
      default:
        debugger;
        throw "Don't know how to handle event";
    }
  }

  on(event, cb) {
    if (this._callbacks[event] === undefined) {
      this._callbacks[event] = [];
    }
    this._callbacks[event].push(cb);

    return () => {
      const idx = this._callbacks[event].indexOf(cb);
      this._callbacks[event].splice(idx, 1);
    };
  }

  removeHandler(event, cb) {
    const idx = this._callbacks[event].findIndex(cb);
    this._callbacks[event].splice(idx, 1);
  }

  resetHooks() {
    console.warn('Resetting hooks. Intended?');
    this._callbacks = {};
  }

  triggerEvent(event, ...args) {
    const cbs = this._callbacks[event] || [];
    let ret, e;
    for (let i = 0; i < cbs.length; i++) {
      const cb = cbs[i];
      try {
        const ret_ = cb(...args);
        if (ret_ !== undefined) {
          ret = ret_;
        }
      } catch (e) {
        if (i === cbs.length - 1) {
          throw e;
        } else {
          console.warn(e);
        }
      }
    }
    return ret;
  }

  getTime() {
    let currentTime;
    currentTime = this.clock.getTime().toFixed(6);
    if (this.previousTime !== currentTime) {
      this.previousTime = currentTime;
      this.previousTimeDelta = 0.000001;
    } else {
      currentTime = (parseFloat(currentTime) + this.previousTimeDelta).toFixed(
        6
      );
      this.previousTimeDelta += 0.000001;
    }
    return parseFloat(currentTime);
  }

  _getPartialObservationDuration(partialObservationId) {
    const { startTime } = this.partialObservations[partialObservationId];
    return this.getTime() - startTime;
  }

  _getPartialObservationAttributes(partialObservationId) {
    return this.partialObservations[partialObservationId].attributeBag;
  }

  getSnapshot() {
    const perGroup = {};
    const partialObservationIdPerGroup =
      this.partialObservationIdPerGroup.toJSON();
    for (const groupId in partialObservationIdPerGroup) {
      perGroup[groupId] = {
        currentObservationCode:
          partialObservationIdPerGroup[groupId].currentObservationCode,
        partialObservationId:
          partialObservationIdPerGroup[groupId].partialObservationId,
      };
    }
    console.log('snapshot', perGroup);
    return {
      partialObservations: Object.assign({}, this.partialObservations),
      partialObservationCounter: this.partialObservationCounter,
      previousTime: this.previousTime,
      previousTimeDelta: this.previousTimeDelta,
      partialObservationIdPerGroup: perGroup,
    };
  }

  restoreSnapshot(snapshot) {
    this.partialObservations = snapshot.partialObservations;
    this.partialObservationCounter = snapshot.partialObservationCounter;
    this.previousTime = snapshot.previousTime;
    this.previousTimeDelta = snapshot.previousTimeDelta;
    runInAction(() => {
      this.partialObservationIdPerGroup.replace(
        snapshot.partialObservationIdPerGroup
      );
    });
  }

  mapTime(time) {
    // by default do nothing
    return this.clock.mapTime(time);
  }

  whenRangeObservationStarted(event /*: RangeObservationStarted*/) {
    const partialObservationId = this.partialObservationCounter++;

    this.partialObservations[partialObservationId] = {
      code: event.code,
      attributeBag: new AttributeBag(event.attributes),
      startTime: event.time,
      triggerTime: event.triggerTime,
    };
    this.partialObservationIdPerGroup.set(event.groupId, {
      partialObservationId,
      currentObservationCode: event.code,
    });
  }

  whenRangeObservationEnded(event /*: RangeObservationEnded*/) {
    this.partialObservationIdPerGroup.delete(event.groupId);
    delete this.partialObservations[event.partialObservationId];
  }

  whenEmptyRangeObservationEnded(event /*: EmptyRangeObservationEnded*/) {
    this.partialObservationIdPerGroup.delete(event.groupId);
  }

  whenObservationAttributesSet(event /*: ObservationAttributesSet*/) {
    const { partialObservationId, currentObservationCode } =
      this.partialObservationIdPerGroup.get(event.groupId) || {};

    const { attributeBag } = this.partialObservations[partialObservationId];
    attributeBag.extend(new AttributeBag(event.attributes), true);
    this.partialObservationIdPerGroup.set(event.groupId, {
      partialObservationId,
      currentObservationCode,
    });
  }

  whenPartialObservationRemoved(event /*: PartialObservationRemoved*/) {
    delete this.partialObservations[event.partialObservationId];
    this.partialObservationIdPerGroup.delete(event.groupId);
  }

  setPointObservation(code, attributes, description) {
    this.startRangeObservation('__reserved_one_shot', code, attributes);
    this.endRangeObservation(
      '__reserved_one_shot',
      null,
      'start',
      undefined,
      description
    );
  }

  updateObservation(
    observationId,
    attributes,
    description,
    clockId,
    triggerTime,
    startTime,
    endTime
  ) {
    this.apply(
      DomainEvent.create('ObservationLogger.ObservationUpdated', {
        observationId,
        attributes: this.attributesExtender(attributes),
        description,
        clockId,
        triggerTime,
        startTime,
        endTime,
      })
    );
  }

  setCustomPointObservation(
    code,
    attributes,
    description,
    durationBefore,
    durationAfter
  ) {
    this.startRangeObservation(
      '__reserved_one_shot',
      code,
      attributes,
      durationBefore
    );
    this.endRangeObservation(
      '__reserved_one_shot',
      null,
      'start',
      undefined,
      description,
      durationBefore + durationAfter
    );
  }

  startRangeObservation(groupId, code, attributes, durationBefore = undefined) {
    this.triggerEvent('beforeObservationStarted', code, attributes);

    let triggerTime = this.getTime();
    let time;
    if (typeof durationBefore !== 'undefined') {
      time = Math.max(0, triggerTime - durationBefore);
    } else {
      time = triggerTime;
    }
    this.apply(
      DomainEvent.create('ObservationLogger.RangeObservationStarted', {
        groupId,
        code,
        attributes,
        time,
        triggerTime,
      })
    );

    this.triggerEvent('afterObservationStarted', code, attributes);
  }

  endRangeObservation(
    groupId,
    attributes,
    time,
    code_ = undefined,
    description = undefined,
    duration = undefined,
    durationAfter = undefined
  ) {
    const { partialObservationId } =
      this.partialObservationIdPerGroup.get(groupId) || {};

    if (partialObservationId === undefined) {
      this.apply(
        DomainEvent.create('ObservationLogger.EmptyRangeObservationEnded', {
          groupId,
        })
      );
    } else {
      let { code, attributeBag, startTime, triggerTime } =
        this.partialObservations[partialObservationId];
      if (typeof code_ !== 'undefined') {
        code = code_;
      }
      this.triggerEvent('beforeObservationAdded', code);

      let endTime;
      if (time === 'start') {
        endTime = startTime;
        if (typeof duration !== 'undefined') {
          endTime += duration;
        }
      } else if (time === 'current') {
        endTime = this.getTime();
      } else {
        throw 'Unknown value for time argument';
      }

      if (typeof durationAfter !== 'undefined') {
        endTime = triggerTime + durationAfter;
      }

      attributeBag.extend(new AttributeBag(attributes));

      if (typeof description === 'undefined') {
        description = this.triggerEvent(
          'descriptionRequested',
          code,
          attributeBag
        );
        if (description === false) {
          this.apply(
            DomainEvent.create('ObservationLogger.EmptyRangeObservationEnded', {
              groupId,
            })
          );
          return;
        }
      }

      if (typeof triggerTime !== 'undefined') {
        triggerTime = this.mapTime(triggerTime);
      } else {
        triggerTime = this.mapTime(startTime);
      }
      startTime = this.mapTime(startTime);
      endTime = this.mapTime(endTime);

      this.apply(
        DomainEvent.create('ObservationLogger.RangeObservationEnded', {
          groupId,
          partialObservationId,
          code,
          triggerTime,
          startTime,
          endTime,
          attributes: this.attributesExtender(attributeBag.toObject()),
          description,
        })
      );

      this.triggerEvent(
        'afterObservationAdded',
        code,
        attributeBag,
        triggerTime,
        startTime,
        endTime,
        description
      );
    }
  }

  removePartialObservation(groupId) {
    const { partialObservationId } =
      this.partialObservationIdPerGroup.get(groupId) || {};
    if (partialObservationId === undefined) {
      console.info('No observation started to add attributes to');
      return;
    }
    this.apply(
      DomainEvent.create('ObservationLogger.PartialObservationRemoved', {
        groupId,
        partialObservationId,
      })
    );
  }

  setObservationAttributes(groupId, code, attributes) {
    const { partialObservationId, currentObservationCode } =
      this.partialObservationIdPerGroup.get(groupId) || {};
    if (partialObservationId === undefined) {
      console.warn('No observation started to add attributes to');
      return;
    }
    if (currentObservationCode !== code) {
      throw 'code does not match';
    }

    this.apply(
      DomainEvent.create('ObservationLogger.ObservationAttributesSet', {
        groupId,
        attributes,
      })
    );
  }

  toggleRangeObservationExclusive(
    groupId,
    code,
    attributes,
    autoRestart = false
  ) {
    /* End any observation within the group and start a new one
     *   only start a new one when code is changed or
     *   autoRestart is turned on */
    const matchesCurrentObservationCodeAndAttributes =
      this.matchesCurrentObservationCodeAndAttributes(
        groupId,
        code,
        attributes
      );

    this.turnoffGroup(groupId);

    if (!matchesCurrentObservationCodeAndAttributes || autoRestart) {
      this.startRangeObservation(groupId, code, attributes);
    }
  }

  turnoffGroup(groupId) {
    this.endRangeObservation(groupId, null, 'current');
  }

  /**** QUERY FUNCTIONS *****/
  matchesCurrentObservationCodeAndAttributes(groupId, code, attributes) {
    const { currentObservationCode, partialObservationId } =
      this.partialObservationIdPerGroup.get(groupId) || {};
    if (partialObservationId !== undefined) {
      if (currentObservationCode === code) {
        const partialObservationAttributes =
          this._getPartialObservationAttributes(partialObservationId);
        for (let key in attributes) {
          if (
            !partialObservationAttributes.has(key) ||
            attributes[key] !== partialObservationAttributes.get(key)
          ) {
            return false;
          }
        }
        return true;
      }
    }
    return false;
  }

  getCurrentPartialObservationAttributes(groupId) {
    if (this.partialObservationIdPerGroup.has(groupId)) {
      return this._getPartialObservationAttributes(
        this.partialObservationIdPerGroup.get(groupId).partialObservationId
      );
    }
  }

  getCurrentPartialObservationDuration(groupId) {
    if (this.partialObservationIdPerGroup.has(groupId)) {
      return this._getPartialObservationDuration(
        this.partialObservationIdPerGroup.get(groupId).partialObservationId
      );
    }
  }

  getCurrentPossessionShots() {
    // return all shots in current Possession period
    if (this.partialObservationIdPerGroup.has('POSSESSION')) {
      let { startTime } =
        this.partialObservations[
          this.partialObservationIdPerGroup.get('POSSESSION')
            .partialObservationId
        ];
      startTime = this.mapTime(startTime);
      const endTime = this.mapTime(this.getTime());
      const observations = this.observationLog.toArray();
      return observations.filter((observation) => {
        return (
          observation.get('startTime') >= startTime &&
          observation.get('endTime') <= endTime &&
          observation.get('code') === 'SHOT'
        );
      });
    } else {
      return [];
    }
  }

  getCurrentPossessionTeamId() {
    if (this.partialObservationIdPerGroup.has('POSSESSION')) {
      let { attributeBag } =
        this.partialObservations[
          this.partialObservationIdPerGroup.get('POSSESSION')
            .partialObservationId
        ];
      return attributeBag.get('teamId');
    } else {
      return null;
    }
  }
}
//
//
// class VideoObservationLogger extends ObservationLogger
// {
//     constructor(sportingEvent, videoId, timeObservable)
//     {
//         super();
//
//         throw "FIX ME!!";
//
//         const logObservation = (code, attributes, startTime, endTime) => {
//             return sportingEvent.addVideoObservation(videoId, code, attributes, startTime, endTime);
//         };
//         this.init(logObservation, new TimerProxy(timeObservable), sportingEvent.observationLog);
//     }
// }
//
// class LiveObservationLogger extends ObservationLogger
// {
//     constructor(sportingEvent)
//     {
//         super();
//
//         window._observationLogger = this;
//
//         this.init({getTime() { return new Date() / 1000; }}, sportingEvent.getObservationCollection("U1"));
//     }
//
//     mapTime(time)
//     {
//         return (new Date(parseFloat(time) * 1000)).toISOString();
//     }
// }

export { ObservationLogger }; //, LiveObservationLogger, VideoObservationLogger};
