import React, { useEffect, useState } from 'react';
import HoverVideoPlayer from 'react-hover-video-player';
import VideoCollection from 'domain/Video';
import moment from 'moment';

import './index.scss';

const VideoCard = ({ videoId, title, children, onClick, date }) => {
  const [videoPath, setVideoPath] = useState('');
  const [vid, setVid] = useState(null);

  useEffect(() => {
    (async () => {
      const _vid = await VideoCollection.getOrFetch(videoId);
      setVid(_vid);
      const fragment = await _vid.getPreviewUrl();
      setVideoPath(fragment);
    })();
  }, [videoId]);

  if (!vid) {
    return null;
  }

  return (
    <div className="video-card" onClick={() => onClick()}>
      <div className="video-card__top">
        <img style={{ maxWidth: '100%' }} src={vid.thumbnailUrl} />
        {false && ( // Disabled because no more mp4's are loaded.
          <HoverVideoPlayer
            className="video-card-player"
            videoSrc={videoPath}
            crossOrigin={null}
            pausedOverlay={<div className="pausedoverlay"></div>}
            style={{
              width: '100%',
              paddingTop: '56.25%',
            }}
            renderThumbnail={true}
            sizingMode="container"
            loadingOverlay={
              <div className="loading-overlay">
                <div className="loading-spinner" />
                <img style={{ maxWidth: '100%' }} src={vid.thumbnailUrl} />
              </div>
            }
          />
        )}
        <div className="video-card-info">
          <h3>{title}</h3>
          {vid && (
            <div className="duration">
              {date
                ? moment(date).format('Y') < moment().format('Y') // Events this year show only DD MM,
                  ? moment(date).format('DD MMM Y')
                  : moment(date).format('DD MMM')
                : moment // No date? then we show video duration.
                    .unix(vid.duration)
                    .utc()
                    .format('HH:mm:ss')}
            </div>
          )}
        </div>
      </div>
      {children}
    </div>
  );
};
export default VideoCard;
