import React, { Component, Fragment, useEffect, useRef } from 'react';
import { observer, Observer } from 'mobx-react';
import { action, observable, runInAction, toJS } from 'mobx';

import SportingEventCollection from '../../../domain/SportingEvent';
import PersonCollection from '../../../domain/Person';
import { lineUpCollection } from '../../../domain/LineUp';
import VideoCollection from '../../../domain/Video';
import { CustomTagManager } from 'domain/CustomTag';

import { ObservationContext } from '../../../domain/ObservationLogger';
import { CommandHistory } from '../../../infra/Messaging';
import { registry as observationInputRegistry } from '../../../utils/ObservationInput';
import _ from 'lodash';
import { CommandBus, Command, HistoryObserver } from '../../../infra/Messaging';
import { asyncAction } from 'mobx-utils';
import Loading from 'lib/Loading';
import { Session } from '../../../domain/Session';
import logger from '../../../utils/Logger';
import { ObservationLogVideoPlayer } from 'lib/Observation/ObservationLogVideoPlayer';
import { ObservationTreeBuilderFactory } from 'lib/ObservationTree/ObservationTreeBuilderFactory';
import { ScoreBoard } from 'lib/Observation/ScoreBoard';
import { VideoPlayerGraph } from 'lib/video-player-graph';
import { withTranslation } from 'react-i18next';
import { EventPublisher } from 'utils/EventPublisher';
import { Page } from 'lib/Page';
import { Error, NotFound } from 'lib/PlaceHolder/index';
import { ObservationDetails } from './observation-details';
import { ObservationTree } from './observation-tree';
import { EditMatchInput } from 'modules/match/components/EditMatch';
import { confirmModal } from 'lib/Confirmation';
import { DataSyncView } from 'modules/data-sync/index';
import {
  ObservationsEditCollectionContextProvider,
  ObservationsEditCollectionContext,
} from 'modules/observe/edit-collection-context';
import { isDev } from 'lib/dev';
import { TagsFilter } from '../components/tags-filter';
import { LiveRecording } from '../../../lib/LiveRecording';
import videoCollection from '../../../domain/Video';
import {
  EncodeState,
  FileUploader,
  UploadState,
} from '../../../infra/Uploader';
import { Prompt } from 'react-router-dom';
import {
  EncodeProgress,
  UploadProgress,
} from '../../match/components/UploadVideo';
import { Interval } from '../../../lib/Interval';
import { Modal } from '../../../lib';
import { ScrollIntoView } from 'lib/ScrollIntoView';
import { NavDropDown } from '../../../lib/NavDropdown';
import { DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { ReportHTML } from '../components/ReportHTML';
import VideoAngleContextProvider from '../../../lib/VideoAngleContextProvider';
import uuidv4 from 'uuid/v4';

const OnBeforeUnloadAlert = () => {
  useEffect(() => {
    const onBeforeUnloadHandler = (e) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', onBeforeUnloadHandler);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnloadHandler);
    };
  }, []);
  return <Prompt when={true} message="You have unsaved changes!" />;
};

const CheckCopySharedData = withTranslation('module.observe.video.copyData')(
  ({ t, observationContext, sportingEvent }) => {
    useEffect(() => {
      (async () => {
        if (
          sportingEvent.isCopy() &&
          typeof sportingEvent.tags.observationsCopiedFrom === 'undefined'
        ) {
          const observationCollection =
            sportingEvent.original.getObservationCollection('U1');
          await observationCollection.fetch();

          let clockId = null;
          for (const clockId_ of ['U1', 'R2', 'R3']) {
            let observationCollection;
            try {
              observationCollection =
                sportingEvent.original.getObservationCollection(clockId_);
            } catch (e) {
              if (e === 'Clock not found') {
                continue;
              } else {
                throw e;
              }
            }

            if (
              observationCollection
                .toArray()
                .filter((observation) => observation.clockId === clockId_)
                .length > 0
            ) {
              clockId = clockId_;
              break;
            }
          }
          if (clockId !== null) {
            observationContext.clock.stop();
            const copyData = async () => {
              await sportingEvent.copyDataFromShared(clockId);
              // refresh the sync points
              await sportingEvent.fetch();
              // YES. SUPER UGLY
              window.location.reload();
            };

            confirmModal({
              title: t('title'),
              description: t('description'),
              actionHandler: copyData,
              actionLabel: t('cta'),
            });
          }
        }
      })();
    }, [observationContext.clock, sportingEvent]);
    return null;
  }
);

const SportingEventObservation = withTranslation('module.observe.video')(
  observer(
    class SportingEventObservation extends Component {
      constructor() {
        super();
        this.dataState = observable('loading');
        this.isTreeToggled = observable(false);
        this.editSportingEventModalOpen = observable(false);
        this.storeVideoTime = this.storeVideoTime.bind(this);
        this.state = {
          filter: { roles: [], sets: [] },
          uploadState: false,
          encodeState: false,
          reportHTML: null,
        };
        window.blaat = (e) => {
          this.setState({ preventNavigation: e });
        };
      }

      componentDidMount() {
        CommandHistory.instance().resetHistory();
        this.loadData();
        window.addEventListener('beforeunload', this.storeVideoTime);
      }

      get videoTimeKey() {
        return `com.teamtv.collection.videoObservarionTime.${this.observationContext.sportingEvent.id}`;
      }

      storeVideoTime() {
        if (!!this.observationContext && !!this.observationContext.clock) {
          const time = this.observationContext.clock.getTime();
          if (time) {
            window.localStorage.setItem(this.videoTimeKey, time);
          }
        }
      }

      componentWillUnmount() {
        window.removeEventListener('beforeunload', this.storeVideoTime);
        this.storeVideoTime();
      }

      loadData = asyncAction(function* () {
        try {
          this.dataState.set('loading');

          yield Session.current().isReady();

          yield CustomTagManager.fetchIfEmpty();

          try {
            this.sportingEvent = yield SportingEventCollection.getOrFetch(
              this.props.match.params.sportingEventId
            );
          } catch (e) {
            this.dataState.set('notFound');
            return;
          }
          yield VideoCollection.fetchIfEmpty();
          yield this.sportingEvent.syncVideos();

          yield this.sportingEvent.loadTeams();
          const lineUp =
            this.sportingEvent.has('lineUpId') &&
            lineUpCollection.getOrFetch(this.sportingEvent.get('lineUpId'));

          yield PersonCollection.fetchIfEmpty();

          const videoId = this.sportingEvent.mainVideoId();
          let clockId;

          if (videoId) {
            this.video = yield VideoCollection.getOrFetch(videoId);
            console.log(this.video);
            this.multiCam = this.sportingEvent.multiVideo();
            clockId = this.sportingEvent.clocks()[videoId].clockId;
          } else {
            this.video = null;
            this.multiCam = false;

            // Refetch sporting event to get latest info on live recording
            yield this.sportingEvent.fetch();
            this.isLiveRecording = this.sportingEvent.isLiveRecording();
            clockId = 'U1';
          }
          this.observationContext = new ObservationContext(
            this.sportingEvent,
            lineUp,
            clockId
          );
          yield this.observationContext.init();
          let players = this.sportingEvent.getAllPlayers();
          this.attributesOptions = {
            personId: players.map((player) => ({
              label: player.fullName,
              player: player,
              value: player.personId,
            })),
          };

          const videoTime = window.localStorage.getItem(this.videoTimeKey);
          if (this.video && !!videoTime) {
            this.observationContext.clock.setStartTime(parseFloat(videoTime));
          }

          this.dataState.set('loaded');

          const observationLogger = this.observationContext.observationLogger;

          observationLogger.on('afterObservationStarted', () => {
            EventPublisher.dispatch(
              'VIDEO_OBSERVATION_STARTED',
              observationLogger.observationLog.toArray().length
            );
          });

          // TODO MOVE THIS TO A GENERIC afterEdit Handlers, always stop the
          // clock when starting an observation edit
          if (
            Session.current().isFeatureAvailable('observeDirectDetails') &&
            this.video
          ) {
            observationLogger.on('afterObservationAdded', () => {
              setTimeout(() => {
                runInAction(() => {
                  this.observationContext.clock.pause();
                });
              }, 10);
            });
          }
          // throw new Error('test-error');
        } catch (e) {
          logger.error(e, { transactionName: 'Unable to start Video Tagging' });
          console.log(e);
          this.dataState.set('error');
        }
      });

      deleteObservation(observation) {
        const command = Command.create('ObservationLog.RemoveObservation', {
          observationId: observation.id,
        });
        CommandBus.dispatch(command);
        if (observation._mergedObservationId) {
          const command = Command.create('ObservationLog.RemoveObservation', {
            observationId: observation._mergedObservationId,
          });
          CommandBus.dispatch(command);
        }
      }

      updateObservation(
        observation,
        {
          attributes = null,
          description = null,
          triggerTime = null,
          startTime = null,
          endTime = null,
        }
      ) {
        let changedAttributes = {};
        if (attributes !== null) {
          for (const key of Object.keys(attributes)) {
            if (
              !_.isEqual(toJS(observation.attributes_[key]), attributes[key])
            ) {
              changedAttributes[key] = attributes[key];
            }
          }
        }
        const timeChanged =
          (startTime !== observation.startTime &&
            startTime !== observation.displayStartTime) ||
          (endTime !== observation.endTime &&
            endTime !== observation.displayEndTime);

        if (
          Object.keys(changedAttributes).length > 0 ||
          (observation.description !== description && description !== null) ||
          timeChanged
        ) {
          const command = Command.create(
            'ObservationLogger.UpdateObservation',
            {
              observationId: observation.id,
              attributes: changedAttributes,
              description:
                description !== observation.description ? description : null,
              clockId: observation.clockId,
              triggerTime:
                triggerTime !== observation.triggerTime ? triggerTime : null,
              startTime: timeChanged ? startTime : null,
              endTime: timeChanged ? endTime : null,
            }
          );
          CommandBus.dispatch(command);
        }
      }

      handleTreeToggle = action(() => {
        this.isTreeToggled.set(!this.isTreeToggled.get());
      });

      onEditSportingEvent = action((sportingEvent) => {
        this.editSportingEventId.set(sportingEvent.id);
      });

      onFilterChange = (filter) => this.setState({ filter });

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        } else if (this.dataState.get() === 'notFound') {
          return <NotFound />;
        }

        const currentSession = Session.current();

        const observationContext = this.observationContext;
        const observationInputKey = this.props.match.params.observationInputKey;
        const observationInput =
          observationInputRegistry.getObservationInputByKey(
            observationInputKey
          );
        const ObservationInputComponent = observationInput.component;

        const pageProps = {
          title: this.props.t('title'),
          breadcrumbs: [
            { path: '/', title: 'Home' },
            { path: '/match', title: this.props.t('module.match:matches') },
            {
              title: this.sportingEvent.label,
            },
          ],
        };

        const sportType = currentSession.sportType();

        const startLiveClock = async (startTime) => {
          await observationContext.sportingEvent.addVideo('live-recording');
          const videoClockId =
            observationContext.sportingEvent.clocks()['live-recording'].clockId;
          const commandLive = Command.create(
            'SportingEvent.AddSynchronizationPoint',
            {
              sportingEventId: this.sportingEvent.id,
              clockId: 'U1',
              type: 'START_PERIOD',
              key: '1',
              time: new Date(startTime).toISOString(),
            }
          );
          CommandBus.dispatch(commandLive);
          const commandVideo = Command.create(
            'SportingEvent.AddSynchronizationPoint',
            {
              sportingEventId: this.sportingEvent.id,
              clockId: videoClockId,
              type: 'START_PERIOD',
              key: '1',
              time: 0,
            }
          );
          CommandBus.dispatch(commandVideo);
          this.setState({ liveRecording: true });
        };

        const onRecordingFinishedCallback = async (file) => {
          const liveClock = observationContext.getClockById('U1');
          const commandLive = Command.create(
            'SportingEvent.AddSynchronizationPoint',
            {
              sportingEventId: this.sportingEvent.id,
              clockId: 'U1',
              type: 'END_PERIOD',
              key: '1',
              time: liveClock.mapTime(liveClock.getTime()),
            }
          );
          CommandBus.dispatch(commandLive);

          const clockId =
            observationContext.sportingEvent.clocks()['live-recording'].clockId;

          const duration = parseInt(observationContext.clock.getTime());
          const uploadState = new UploadState();

          const uploader = new FileUploader(
            async (videoId) => {
              this.video = videoCollection.build({
                mediaUrl: URL.createObjectURL(file), // + '#!.mp4',
                tenantId: currentSession.currentTenantId(),
                videoId: videoId,
                description: 'live-recording',
                content: {
                  duration,
                },
                mimeType: file.type,
              });

              observationContext.setClock(clockId);
              // fetch new observations from backend
              await observationContext.observationLog.fetch();

              this.setState({
                clockId: clockId,
                uploadState,
                preventNavigation: true,
              });
            },
            (videoId) => {
              const encodeState = new EncodeState();
              encodeState.startMonitoring(videoId);
              this.setState({ encodeState: encodeState });
              console.log(encodeState);
              const interval = window.setInterval(() => {
                if (encodeState.state === 'ready') {
                  this.setState({ preventNavigation: false });
                  window.clearInterval(interval);
                }
              }, 1000);
            },
            uploadState
          );
          uploader.addFile(file);

          // pass the clockId, so the 'live-recording' fake clock will be replaced
          await uploader.start(observationContext.sportingEvent, clockId);
          await observationContext.sportingEvent.fetch();

          this.setState({ uploadState, liveRecording: false });
        };

        const observationTreeBuilder = ObservationTreeBuilderFactory.factory(
          sportType,
          observationContext.sportingEvent.matchConfig,
          observationContext.sportingEvent.homeTeam,
          observationContext.sportingEvent.awayTeam,
          observationContext
        );

        const closeTreeWhenOpen = () => {
          if (this.isTreeToggled.get()) {
            this.handleTreeToggle();
          }
        };

        const filteredObservations = this.observationContext
          .observationsWithTime()
          .filter(
            (observation) =>
              this.state.filter['roles'].length === 0 ||
              this.state.filter['roles'].includes(observation.creatorRoleName)
          )
          .filter((observation) => {
            return (
              this.state.filter['sets'].length === 0 ||
              this.state.filter['sets'].includes(
                observation.attributes_.learningLineId
              ) ||
              this.state.filter['sets'].includes(
                observation.attributes_.phaseLearningLine
              )
            );
          });

        const playerId = `player-${uuidv4()}`;

        return (
          <VideoAngleContextProvider>
            <Page
              className={'page--observe-match'}
              noTopPadding
              fullWidth
              hideFooter
              {...pageProps}
            >
              <ScrollIntoView />

              <ObservationsEditCollectionContextProvider
                clock={this.observationContext.clock}
                observations={filteredObservations}
              >
                <div
                  className={`layout-with-sidebar observe-container ${
                    !this.video && 'layout--liverecord'
                  }`}
                >
                  <div className="main video-player-container">
                    {currentSession.isFeatureAvailable('liveObserve') && (
                      <>
                        <CheckCopySharedData
                          observationContext={this.observationContext}
                          sportingEvent={this.sportingEvent}
                        />
                        <DataSyncView
                          onSynced={() => {
                            this.observationContext.observationLog.reload();
                            EventPublisher.dispatch('LIVE_DATA_SYNCED');
                          }}
                          video={this.video}
                          observationContext={this.observationContext}
                        />
                      </>
                    )}
                    {currentSession.isFeatureAvailable('scoreBar') && (
                      <ScoreBoard
                        observationContext={this.observationContext}
                        sportType={sportType}
                      />
                    )}
                    {(this.state.liveRecording ||
                      this.video?.has('livestream')) && (
                      <Interval
                        interval={5}
                        fn={observationContext.observationLog.fetch}
                      />
                    )}
                    {!this.video && (
                      <>
                        {this.isLiveRecording && (
                          <Modal onCloseClick={() => window.history.back()}>
                            {this.props.t('liveRecording:alreadyRecording')}
                          </Modal>
                        )}
                        {!this.isLiveRecording && (
                          <LiveRecording
                            onStartRecording={startLiveClock}
                            onFinishRecording={onRecordingFinishedCallback}
                            extra={
                              // TODO: make sure we combine this with the ObservationDetails modal
                              // in ObservationLogVideoPlayer
                              <ObservationDetails
                                onClose={() =>
                                  this.video && observationContext.clock.play()
                                }
                                onDelete={(observation) =>
                                  this.deleteObservation(observation)
                                }
                                onUpdateObservation={this.updateObservation.bind(
                                  this
                                )}
                                sportType={sportType}
                                // video={this.video}
                                attributesOptions={this.attributesOptions}
                              />
                            }
                          />
                        )}
                      </>
                    )}
                    {this.video && (
                      <ObservationLogVideoPlayer
                        clock={this.observationContext.clock}
                        video={this.video}
                        multiCam={this.multiCam}
                        playerId={playerId}
                        extra={
                          <ObservationDetails
                            onClose={() =>
                              this.video && observationContext.clock.play()
                            }
                            onDelete={(observation) =>
                              this.deleteObservation(observation)
                            }
                            onUpdateObservation={this.updateObservation.bind(
                              this
                            )}
                            sportType={sportType}
                            video={this.video}
                            multiCam={this.multiCam}
                            attributesOptions={this.attributesOptions}
                          />
                        }
                      />
                    )}
                    {currentSession.isFeatureAvailable('viewObserveGraph') &&
                      false && (
                        <VideoPlayerGraph
                          observations={filteredObservations}
                          sportingEvent={observationContext.sportingEvent}
                          clock={observationContext.clock}
                          maxTime={this.video.duration}
                          observationInput={observationInput}
                        />
                      )}
                    <div className="button-wrapper">
                      <div className="video-observations relative width-100">
                        <ObservationsEditCollectionContext.Consumer>
                          {({ startEdit }) => (
                            <ObservationInputComponent
                              observationContext={observationContext}
                              args={observationInput.args}
                              onUndo={
                                HistoryObserver.instance().canUndo()
                                  ? CommandHistory.instance()
                                      .undoToPreviousTaggedHistoryItem
                                  : null
                              }
                              afterAdd={startEdit}
                            />
                          )}
                        </ObservationsEditCollectionContext.Consumer>
                      </div>
                    </div>
                    <div style={{ flexGrow: 0.1, display: 'flex' }} />
                  </div>
                  <div className="sidebar">
                    {this.state.uploadState && (
                      <div className="live-upload-state">
                        {!this.state.encodeState && this.state.uploadState && (
                          <UploadProgress
                            uploadState={this.state.uploadState}
                            isLiveRecording
                          />
                        )}
                        {this.state.encodeState && (
                          <EncodeProgress
                            encodeState={this.state.encodeState}
                            isLiveRecording
                          />
                        )}
                      </div>
                    )}
                    <div className="sidebar-header">
                      <div className="sidebar-header-title">
                        {this.props.t('tagOverviewLabel')}
                      </div>
                      <div className="sidebar-header-actions">
                        <span className="sidebar-header-action">
                          <TagsFilter
                            light
                            values={this.state.filter}
                            observationsFetcher={async () => {
                              await observationContext.observationLog.fetch();
                              return observationContext.observationsWithTime();
                            }}
                            onChange={this.onFilterChange}
                          />
                          {this.sportingEvent.privilegedTo('edit', true) && (
                            <span>
                              <button
                                className={'btn btn-link'}
                                onClick={action(() =>
                                  this.editSportingEventModalOpen.set(true)
                                )}
                              >
                                <i className={'i-settings i-light'}></i>
                              </button>
                            </span>
                          )}
                          {currentSession.isFeatureAvailable(
                            'exportReport'
                          ) && (
                            <span style={{ display: 'inline-flex' }}>
                              <NavDropDown>
                                <DropdownToggle
                                  tag={'a'}
                                  className={'btn btn-link'}
                                >
                                  <i className={'i-export i-light'}></i>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem
                                    onClick={() => {
                                      const report =
                                        this.sportingEvent.reportCollection.build(
                                          {
                                            reportId:
                                              currentSession.getDefaultDownloadReportId(),
                                          }
                                        );
                                      report.download();
                                    }}
                                  >
                                    Excel
                                  </DropdownItem>
                                  <DropdownItem
                                    onClick={async () => {
                                      const report =
                                        this.sportingEvent.reportCollection.build(
                                          {
                                            reportId:
                                              currentSession.getDefaultDownloadReportId(),
                                          }
                                        );
                                      // We expect observationInput.args to be a LearningLineId.
                                      // Only for SkillReflect.
                                      const reportHTML = await report.getHTML({
                                        // This is a bit confusing here, but `args` is a single value
                                        learning_line_id: observationInput.args,
                                      });
                                      this.setState({
                                        reportHTML,
                                      });
                                    }}
                                  >
                                    Report
                                  </DropdownItem>
                                </DropdownMenu>
                              </NavDropDown>
                            </span>
                          )}
                        </span>
                      </div>
                    </div>
                    <Observer>
                      {() => {
                        if (isDev()) {
                          console.time('observationTreeBuild');
                        }

                        const { rootObservations } =
                          observationTreeBuilder.build(filteredObservations);
                        if (isDev()) {
                          console.timeEnd('observationTreeBuild');
                        }

                        return (
                          <ObservationTree
                            rootObservations={rootObservations}
                            onEdit={(observationNode) => {
                              if (!!observationNode) {
                                observationContext.clock.pause();
                              }
                            }}
                            onSelect={(observationNode) => {
                              closeTreeWhenOpen();
                              observationContext.clock.setRate(1);
                              observationContext.clock.setTime(
                                observationNode.time.eventTime,
                                false,
                                true
                              );
                            }}
                            isToggled={this.isTreeToggled.get()}
                            // clockTime={observationContext.clockTimeFn()}
                          />
                        );
                      }}
                    </Observer>
                  </div>
                </div>
                {this.state.reportHTML && (
                  <ReportHTML
                    content={this.state.reportHTML}
                    onClose={() => {
                      this.setState({ reportHTML: null });
                    }}
                  />
                )}

                {this.editSportingEventModalOpen.get() && (
                  <EditMatchInput
                    sportingEvent={this.sportingEvent}
                    onReady={action(() =>
                      this.editSportingEventModalOpen.set(false)
                    )}
                    sportType={sportType}
                  />
                )}
              </ObservationsEditCollectionContextProvider>
              {this.state.preventNavigation && <OnBeforeUnloadAlert />}
            </Page>
          </VideoAngleContextProvider>
        );
      }
    }
  )
);

export default SportingEventObservation;
