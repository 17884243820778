import React, { Component } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { ObservationInputNav } from 'modules/observe/components/InputNav';

import { withTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { asyncAction } from 'mobx-utils';
import { Session } from 'domain/Session';
import SportingEventCollection from 'domain/SportingEvent';
import TeamCollection from 'domain/Team';
import VideoCollection from 'domain/Video';
import logger from 'utils/Logger';
import { gotoRoute } from '../../route';

import { Error, NoResults } from 'lib/PlaceHolder';

import { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import VideoCard from 'lib/VideoCard';
import noMatchesImage from 'img/no_matches.svg';
import { NoPermissions } from 'lib/PlaceHolder';

import { Link } from 'react-router-dom';
const SportingEventOverviewSmall = withTranslation('module.match')(
  observer(
    class SportingEventOverview extends Component {
      constructor(props) {
        super(props);
        this.dataState = observable('loading');
        this.visibleMatchCount = observable(1);
        this.sportingEvents = [];
      }

      componentWillMount() {
        this.loadData();
      }

      loadData = asyncAction(function* () {
        try {
          this.dataState.set('loading');

          yield Session.current().isReady();

          // preload some
          yield Promise.all([
            SportingEventCollection.fetchIfEmpty(),
            TeamCollection.fetchIfEmpty(),
            VideoCollection.fetchIfEmpty(),
          ]);

          const currentSession = Session.current();
          let rows = SportingEventCollection.getPastMatches()
            .filter((sportingEvent) => {
              return (
                sportingEvent.isMatchOf(currentSession.currentResource()) ||
                !(sportingEvent.isShared() || sportingEvent.isCopy())
              );
            })
            .filter(
              (sportingEvent) =>
                !sportingEvent.isLive() &&
                !sportingEvent.isLiveStreamAvailable()
            );

          rows.sort((a, b) => {
            return a.get('scheduledAt') === b.get('scheduledAt')
              ? 0
              : a.get('scheduledAt') > b.get('scheduledAt') ||
                b.get('scheduledAt') === false
              ? -1
              : 1;
          });
          rows = rows
            .filter((sportingEvent) => {
              const video = VideoCollection.get(sportingEvent.mainVideoId());
              return sportingEvent.hasVideo() && video?.state === 'ready';
            })
            .slice(0, 5);

          yield Promise.all(
            rows.map((s) => {
              const video = VideoCollection.get(s.mainVideoId());
              // make sure previewUrl is loaded BEFORE we remove skeleton
              return video.getPreviewUrl();
            })
          );

          this.sportingEvents = rows;

          this.dataState.set('loaded');
        } catch (e) {
          console.log(e);
          logger.error(e, {
            transactionName: 'Unable to load Matches on homepage',
          });
          this.dataState.set('error');
        }
      });
      get timeFilterName() {
        return 'past';
      }

      gotoPlay(sportingEventId) {
        gotoRoute('play.match', { sportingEventId });
      }

      render() {
        if (
          !Session.current()
            .currentPrivileges()
            .hasPrivilege('sporting-event:view')
        ) {
          return <NoPermissions resource={this.props.t('matches')} />;
        }
        if (this.dataState.get() === 'loading') {
          return (
            <div className={'skeleton-card-wrapper'}>
              <Skeleton count={5} duration={0.9} inline></Skeleton>
            </div>
          );
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        let noResultsDescription;
        noResultsDescription = this.props.t('noPlayedMatchesPlaceholder');

        // TODO: enable row length check again
        // Show only 5 most recent events that have video

        return (
          <>
            <Swiper
              spaceBetween={8}
              slidesPerView={1.25}
              navigation
              modules={[Navigation, Scrollbar, A11y]}
              breakpoints={{
                768: {
                  slidesPerView: 1.5,
                  spaceBetween: 12,
                },
                1200: {
                  slidesPerView: 2.25,
                  spaceBetween: 16,
                },
                1600: {
                  slidesPerView: 3.25,
                  spaceBetween: 16,
                },
              }}
            >
              {this.sportingEvents.map((sportingEvent) => {
                return (
                  <SwiperSlide
                    key={sportingEvent.id}
                    className={'swiper-slide'}
                  >
                    {sportingEvent.privilegedTo('observe') && (
                      <div className="vc-wrapper">
                        <ObservationInputNav
                          live={false}
                          urlParams={{ sportingEventId: sportingEvent.id }}
                          key="observation-input"
                        >
                          {sportingEvent.isDemo() && (
                            <span className="demo">
                              {sportingEvent.privilegedTo('observe') && (
                                <i className={'i-observe i-light i-xs'}></i>
                              )}{' '}
                              demo
                            </span>
                          )}
                          <VideoCard
                            videoId={sportingEvent.mainVideoId()}
                            title={sportingEvent.label}
                            onClick={() =>
                              sportingEvent.privilegedTo('observe')
                                ? null
                                : this.gotoPlay(sportingEvent.id)
                            }
                            date={sportingEvent.scheduledAt}
                          />
                        </ObservationInputNav>
                      </div>
                    )}
                    {!sportingEvent.privilegedTo('observe') && (
                      <div className="vc-wrapper">
                        {sportingEvent.isDemo() && (
                          <span className="demo">demo</span>
                        )}
                        <VideoCard
                          videoId={sportingEvent.mainVideoId()}
                          title={sportingEvent.label}
                          onClick={() => this.gotoPlay(sportingEvent.id)}
                          date={sportingEvent.scheduledAt}
                        />
                      </div>
                    )}
                  </SwiperSlide>
                );
              })}
              {Session.current().isFeatureAvailable('createSportingEvent') && (
                <SwiperSlide>
                  <div className={'slide-addmatch'}>
                    <Link
                      to={
                        window.isSkillReflect
                          ? '/recordings/overview#add'
                          : '/match/overview/all#add'
                      }
                    >
                      <div className={'slide-addmatch__inner'}>
                        <div className="slide-addmatch__button">
                          <i className="i-add i-sm"></i>
                          <span>
                            {this.props.t('module.actionList:addMatch')}
                          </span>
                        </div>
                      </div>
                    </Link>
                  </div>
                </SwiperSlide>
              )}
            </Swiper>
          </>
        );
      }
    }
  )
);

const MatchInfo = () => {
  return <SportingEventOverviewSmall />;
};
export default MatchInfo;
