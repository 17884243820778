import Command from '../../infra/Messaging/Command';
import { CommandBus, CommandHistory } from '../../infra/Messaging';
import { ObservationLogger } from './ObservationLogger';
import { MatchClockHelper } from './Clock';
import { groupObservationsPerTeam } from 'utils/observation';

class ObservationContext {
  static _instance = null;

  constructor(sportingEvent, lineUp, clockId, initObservationLogger = true) {
    this.sportingEvent = sportingEvent;
    this.homeTeam = this.sportingEvent.homeTeam;
    this.awayTeam = this.sportingEvent.awayTeam;

    this.setClock(clockId);

    this.observationLog = this.sportingEvent.getObservationCollection(
      this.clock
    );

    this.lineUp = lineUp;
    this.playDirection = 'home-away';

    this._callbacks = {};

    ObservationContext.setInstance(this);

    if (initObservationLogger) {
      this.observationLogger = new ObservationLogger(
        this.observationLog,
        this.clock,
        this.attributesExtender.bind(this)
      );
      // make sure match started again
      this.observationLogger.on('beforeObservationStarted', (code) => {
        if (code === 'POSSESSION' && this.clock.isLive()) {
          const [currentState, periodNr, startTime, endTime] =
            this.currentPeriod;
          if (this.currentState === 'NOT_STARTED') {
            this.startPeriod(periodNr);
          }
        }
      });
    }
  }

  setClock(clockId) {
    const clock = MatchClockHelper.getClockByClockId(
      this.sportingEvent,
      clockId
    );

    this.clock = clock;
    this.observationLog = this.sportingEvent.getObservationCollection(
      this.clock
    );
    if (this.observationLogger) {
      this.observationLogger.clock = clock;
      this.observationLogger.observationLog = this.observationLog;
    }
  }

  getClockById(clockId) {
    return MatchClockHelper.getClockByClockId(this.sportingEvent, clockId);
  }

  async init() {
    return this.observationLog.fetch();
  }

  attributesExtender(attributes) {
    const newAttributes = {};
    for (const attributeName of Object.keys(attributes)) {
      if (attributeName.toLowerCase().endsWith('personid')) {
        for (const player of this.homeTeam.players.toArray()) {
          if (player.personId === attributes[attributeName]) {
            newAttributes[attributeName.slice(0, -2)] = {
              personId: player.personId,
              firstName: player.firstName,
              lastName: player.lastName,
              number: player.number,
              gender: player.gender,
              team: {
                ground: 'home',
                teamId: this.homeTeam.id,
                name: this.homeTeam.label,
              },
            };
          }
        }
        for (const player of this.awayTeam.players.toArray()) {
          if (player.personId === attributes[attributeName]) {
            newAttributes[attributeName.slice(0, -2)] = {
              personId: player.personId,
              firstName: player.firstName,
              lastName: player.lastName,
              number: player.number,
              gender: player.gender,
              team: {
                ground: 'away',
                teamId: this.awayTeam.id,
                name: this.awayTeam.label,
              },
            };
          }
        }
      } else if (attributeName.toLowerCase().endsWith('teamid')) {
        if (attributes[attributeName] === this.homeTeam.id) {
          newAttributes[attributeName.slice(0, -2)] = {
            ground: 'home',
            teamId: this.homeTeam.id,
            name: this.homeTeam.label,
          };
        } else if (attributes[attributeName] === this.awayTeam.id) {
          newAttributes[attributeName.slice(0, -2)] = {
            ground: 'away',
            teamId: this.awayTeam.id,
            name: this.awayTeam.label,
          };
        }
      }
    }
    return Object.assign(attributes, newAttributes);
  }

  static instance() {
    return ObservationContext._instance;
  }

  static setInstance(instance) {
    ObservationContext._instance = instance;
  }

  on(event, cb) {
    if (this._callbacks[event] === undefined) {
      this._callbacks[event] = [];
    }
    this._callbacks[event].push(cb);

    return () => {
      const idx = this._callbacks[event].indexOf(cb);
      this._callbacks[event].splice(idx, 1);
    };
  }

  triggerEvent(event, ...args) {
    const cbs = this._callbacks[event] || [];
    let ret;
    for (let cb of cbs) {
      ret = cb(...args);
    }
    return ret;
  }

  togglePlayDirection() {
    this.playDirection =
      this.playDirection === 'home-away' ? 'away-home' : 'home-away';
    this.triggerEvent('playDirectionChanged', this.playDirection);
  }

  get goalCounter() {
    return this.homeGoalCounter + this.awayGoalCounter;
  }

  observations() {
    const time = this.clock.mapTime(this.clock.getTime());

    const observations = this.observationLog
      .toArray()
      .filter((observation) => observation.triggerTime <= time);
    const sortFn = (a, b) => {
      return a.triggerTime === b.triggerTime
        ? 0
        : a.triggerTime < b.triggerTime
        ? -1
        : 1;
    };
    observations.sort(sortFn);
    return observations;
  }

  // ordered by startTime ascending
  observationsWithTime() {
    const observations = this.observationLog
      .toArray()
      .filter(
        (observation) =>
          observation.get('startTime') !== 'un-mappable' &&
          observation.get('clockId') === this.clock.clockId()
      );

    const sortFn = (a, b) => {
      return a.triggerTime === b.triggerTime
        ? 0
        : a.triggerTime < b.triggerTime
        ? -1
        : 1;
    };
    observations.sort(sortFn);

    return observations;
  }

  observationsOfTeam(teamId, filteredByClock = true) {
    const observationPerTeam = this.observationsPerTeam(filteredByClock);
    return observationPerTeam[teamId] || [];
  }

  observationsPerTeam(filteredByClock = true) {
    const observations = filteredByClock
      ? this.observations()
      : this.observationsWithTime();
    return groupObservationsPerTeam(observations);
  }

  get timeObservations() {
    return this.observations().filter((observation) => {
      return observation.get('code').startsWith('TIME:');
    });
  }

  teamGoals(teamId) {
    const teamObservations = this.observationsOfTeam(teamId);
    return teamObservations.filter((observation) => {
      const code = observation.get('code');
      switch (code) {
        case 'GOAL':
          return true;
        case 'GOAL-CORRECTION':
          return true;
        case 'SHOT':
          return observation.get('attributes').result === 'GOAL';
      }
    }).length;
  }

  substitutions(teamId) {
    const observations = this.observations();
    return observations
      .filter(
        (observation) =>
          observation.get('code') === 'SUBSTITUTION' &&
          observation.get('attributes').teamId === teamId
      )
      .map((observation) => {
        const attributes = observation.get('attributes');
        return {
          inPersonId: attributes.inPersonId,
          outPersonId: attributes.outPersonId,
          position: attributes.position,
        };
      });
  }

  getTeamRoles(teamId) {
    let roles = this.lineUp.getTeamRoles(teamId);
    const substitutions = this.substitutions(teamId);

    for (const substitution of substitutions) {
      roles = roles.filter((role) => {
        return role.personId !== substitution.outPersonId;
      });
      roles.push(
        this.lineUp.createRole(
          teamId,
          substitution.inPersonId,
          substitution.position,
          'player'
        )
      );
    }
    return roles;
  }

  get homeGoalCounter() {
    return this.teamGoals(this.homeTeam.id);
  }

  get awayGoalCounter() {
    return this.teamGoals(this.awayTeam.id);
  }

  /* just some proxies */
  get periodCount() {
    return this.sportingEvent.get('type') === 'training'
      ? 1
      : this.sportingEvent.periodCount;
  }

  get currentPeriod() {
    return MatchClockHelper.getCurrentPeriod(this.sportingEvent, this.clock);
  }

  get currentState() {
    return MatchClockHelper.getCurrentState(this.sportingEvent, this.clock);
  }

  get currentTimeFn() {
    return MatchClockHelper.getCurrentTimeFn(
      this.sportingEvent,
      this.clock,
      () => this.timeObservations
    );
  }

  getLiveClockStartTime() {
    const liveClock = MatchClockHelper.getClockByClockId(
      this.sportingEvent,
      'U1'
    );
    if (liveClock) {
      const startOfPeriod1 = liveClock.findSynchronizationPointTime(
        'START_PERIOD',
        '1'
      );
      if (startOfPeriod1) {
        return startOfPeriod1;
      }
    }
    return undefined;
  }

  getPeriodLength(periodNr) {
    return MatchClockHelper.getPeriodLength(this.sportingEvent, periodNr);
  }

  get clockTimeFn() {
    if (
      this.clock
        .synchronizationPoints()
        .filter((s) => s.type === 'START_PERIOD').length > 0
    ) {
      return this.currentTimeFn;
    }
    return () => {
      return this.clock.getTime();
    };
  }

  /***************************************************************************************/
  /* ALL FUNCTIONS BELOW SHOULD GO TO A Non-Sport-Specific ObservationInput              */
  /***************************************************************************************/

  get periodLabelProps() {
    const [currentState, periodNr] = this.currentPeriod;

    return { periodNr, periodState: currentState };
  }

  get periodLabel() {
    const [currentState, periodNr, startTime, endTime] = this.currentPeriod;
    let timeFn;
    switch (currentState) {
      case 'NOT_STARTED':
        return `Start ${periodNr}e periode`;
      case 'STARTED':
        return `Einde ${periodNr}e periode`;
      case 'ENDED':
        return 'Afgelopen';
    }
  }

  startPeriod(periodNr) {
    const command = Command.create('SportingEvent.AddSynchronizationPoint', {
      sportingEventId: this.sportingEvent.id,
      clockId: this.clock.clockId(),
      type: 'START_PERIOD',
      key: periodNr,
      time: this.clock.mapTime(this.clock.getTime()),
    });
    CommandBus.dispatch(command);
  }

  togglePeriod() {
    const [currentState, periodNr, startTime, endTime] = this.currentPeriod;
    let timeFn;
    switch (currentState) {
      case 'NOT_STARTED': {
        this.startPeriod(periodNr);

        CommandHistory.instance().tagHistoryItem(`Start period ${periodNr}`, {
          showNotification: false,
        });

        this.triggerEvent('periodStarted', periodNr);
        break;
      }
      case 'STARTED': {
        const command = Command.create(
          'SportingEvent.AddSynchronizationPoint',
          {
            clockId: this.clock.clockId(),
            sportingEventId: this.sportingEvent.id,
            type: 'END_PERIOD',
            key: periodNr,
            time: this.clock.mapTime(this.clock.getTime()),
          }
        );
        CommandBus.dispatch(command);

        CommandHistory.instance().tagHistoryItem(`End period ${periodNr}`, {
          showNotification: false,
        });

        this.triggerEvent('periodEnded', periodNr);
        break;
      }
      case 'ENDED':
        break;
    }
  }

  getTeamNameWithPossession() {
    let name;
    if (this.hasPossession(this.homeTeam.id)) {
      name = this.homeTeam.get('name');
    } else if (this.hasPossession(this.awayTeam.id)) {
      name = this.awayTeam.get('name');
    } else {
      name = '';
    }
    return name;
  }

  hasPossession(teamId) {
    const possessionTeamId =
      this.observationLogger.getCurrentPossessionTeamId();

    // 1. check current entered data
    const currentEnteredPossession = possessionTeamId === teamId;

    // 2. check previous entered data
    const time = this.clock.mapTime(this.clock.getTime());

    // this.observations() is contains only observations started before current time
    const possessionObservations = this.observations().filter(
      (observation) =>
        observation.get('code') === 'POSSESSION' &&
        observation.get('attributes').teamId === teamId &&
        observation.get('endTime') >= time
    );
    const previousEnteredPossession = possessionObservations.length > 0;

    return currentEnteredPossession || previousEnteredPossession;
  }

  homeTeamPossession() {
    return this.hasPossession(this.homeTeam.id);
  }

  awayTeamPossession() {
    return this.hasPossession(this.awayTeam.id);
  }

  addGoalCorrection(teamName) {
    const team = teamName === 'home' ? this.homeTeam : this.awayTeam;
    const description = `Goal correctie ${team.label}`;
    this.observationLogger.setPointObservation(
      'GOAL-CORRECTION',
      { teamId: team.id },
      description
    );

    CommandHistory.instance().tagHistoryItem(description);
  }

  addSubstitution(teamId, inPersonId, outPersonId, position) {
    const description = 'Wissel';
    this.observationLogger.setPointObservation(
      'SUBSTITUTION',
      { teamId, inPersonId, outPersonId, position },
      description
    );

    CommandHistory.instance().tagHistoryItem(description);
  }

  addPenaltyGiven(type) {
    let penaltyType, description;
    switch (type) {
      case 'yellow-card':
        description = 'Gele kaart gegeven';
        penaltyType = 'YELLOW-CARD';
        break;
      case 'red-card':
        description = 'Rode kaart gegeven';
        penaltyType = 'RED-CARD';
        break;
      case 'free-ball':
        description = `Vrije bal ${this.getTeamNameWithPossession()} gegeven`;
        penaltyType = 'FREE-BALL';
        break;
      case 'penalty-shot':
        description = `Strafworp ${this.getTeamNameWithPossession()} gegeven`;
        penaltyType = 'PENALTY-SHOT';
        break;
      default:
        throw `Unknown penalty type ${type}`;
    }
    this.observationLogger.setPointObservation(
      'PENALTY-GIVEN',
      { type: penaltyType },
      description
    );

    CommandHistory.instance().tagHistoryItem(description);
  }

  addBallLoss() {
    const description = `Balverlies ${this.getTeamNameWithPossession()}`;
    this.observationLogger.setPointObservation('BALL-LOSS', {}, description);

    CommandHistory.instance().tagHistoryItem(description);
  }

  addObservationError() {
    const description = 'Invoer fout';
    this.observationLogger.setPointObservation(
      'OBSERVATION-ERROR',
      {},
      description
    );

    CommandHistory.instance().tagHistoryItem(description);
  }

  clock() {
    return this.clock;
  }
}

export { ObservationContext };
