import korfballVideoDefaultObservationInput from './inputs/korfball.video.default';
import korfballLiveDefaultObservationInput from './inputs/korfball.live.default';

import soccerLiveDefaultObservationInput from './inputs/soccer.live.default';
import soccerLivePassMapObservationInput from './inputs/soccer.live.passmap';
// import soccerLiveDefaultObservationInput from './inputs/soccer.live.default';
import soccerVideoDefaultObservationInput from './inputs/soccer.video.default';

import handballVideoDefaultObservationInput from './inputs/handball.video.default';
import handballLiveDefaultObservationInput from './inputs/handball.live.default';

import tennisVideoDefaultObservationInput from './inputs/tennis.video.default';

import hockeyLiveDefaultObservationInput from './inputs/hockey.live.default';
import hockeyVideoDefaultObservationInput from './inputs/hockey.video.default';
// import hockeyVideoLiteObservationInput from './inputs/hockey.video.lite';

import broadcastLiveDefaultObservationInput from './inputs/broadcast.live.default';

import developmentDefaultObservationInput from './inputs/development.default';
import learningLineDefaultObservationInput from './inputs/learning-line.default';

import otherObservationInput from './inputs/other.video.default';

import registry from './registry';
import trainingDefaultInput from './inputs/training.default';
import trainingLiveInput from './inputs/training.live';

registry.register(korfballVideoDefaultObservationInput);
registry.register(korfballLiveDefaultObservationInput);

// registry.register(soccerLiveHeerenveenObservationInput);
registry.register(soccerLivePassMapObservationInput);
registry.register(soccerLiveDefaultObservationInput);
registry.register(soccerVideoDefaultObservationInput);

registry.register(handballVideoDefaultObservationInput);
registry.register(handballLiveDefaultObservationInput);

registry.register(tennisVideoDefaultObservationInput);

registry.register(hockeyLiveDefaultObservationInput);
registry.register(hockeyVideoDefaultObservationInput);
// registry.register(hockeyVideoLiteObservationInput);

registry.register(broadcastLiveDefaultObservationInput);

registry.register(developmentDefaultObservationInput);
registry.register(learningLineDefaultObservationInput);
registry.register(otherObservationInput);
registry.register(trainingDefaultInput);
registry.register(trainingLiveInput);

export { registry };
