import React, {
  Component,
  useCallback,
  memo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { observer } from 'mobx-react';

import { Command, CommandBus } from '../../../infra/Messaging';

const getCommand = ({
  type,
  groupId,
  observationCode,
  attributes,
  durationBefore,
  durationAfter,
  autoRestart,
  description,
}) => {
  let command;
  if (
    type === 'toggle-range' ||
    type === 'start-range' ||
    type === 'start-point' ||
    type === 'set-attributes'
  ) {
    if (type === 'toggle-range') {
      command = Command.create(
        'ObservationLogger.ToggleRangeObservationExclusive',
        { groupId, code: observationCode, attributes }
      );
      if (autoRestart) {
        throw 'Disabled';
      }
    } else if (type === 'set-attributes') {
      command = Command.create('ObservationLogger.SetObservationAttributes', {
        groupId,
        code: observationCode,
        attributes,
      });
    } else if (type === 'start-point' || type === 'start-range') {
      command = Command.create('ObservationLogger.StartRangeObservation', {
        groupId,
        code: observationCode,
        attributes,
        durationBefore,
      });
    } else {
      throw `Unknown type: ${type}`;
    }
  } else if (
    type === 'point' ||
    type === 'custom-point' ||
    type === 'end-range' ||
    type === 'end-point'
  ) {
    if (type === 'point') {
      command = Command.create('ObservationLogger.SetPointObservation', {
        code: observationCode,
        attributes,
        description,
      });
    } else if (type === 'custom-point') {
      command = Command.create('ObservationLogger.SetCustomPointObservation', {
        code: observationCode,
        attributes,
        description,
        durationBefore,
        durationAfter,
      });
    } else if (type === 'end-point') {
      // used to add parameters to current point
      command = Command.create('ObservationLogger.EndRangeObservation', {
        groupId,
        attributes,
        code: observationCode,
        time: 'start',
        description,
        durationAfter,
      });
      // TODO: handle observationCode
    } else if (type === 'end-range') {
      command = Command.create('ObservationLogger.EndRangeObservation', {
        groupId,
        attributes,
        code: observationCode,
        time: 'current',
      });
      // TODO: handle observationCode
    } else {
      throw `Unknown type: ${type}`;
    }
  }
  return command;
};

const isActive = ({
  type,
  observationLogger,
  groupId,
  observationCode,
  attributes,
}) => {
  if (
    type === 'toggle-range' ||
    type === 'start-range' ||
    type === 'start-point' ||
    type === 'set-attributes'
  ) {
    return observationLogger.matchesCurrentObservationCodeAndAttributes(
      groupId,
      observationCode,
      attributes
    );
  } else if (
    type === 'point' ||
    type === 'custom-point' ||
    type === 'end-range' ||
    type === 'end-point'
  ) {
    return undefined;
  } else {
    throw `Unknown type: ${type}`;
  }
};

const InputTriggerInner = ({
  type,
  groupId,
  observationCode,
  autoRestart,
  attributes,
  description,
  durationBefore,
  durationAfter,
  active,
  render,
  disabled,
  triggerOnce = false,
}) => {
  const hasTriggered = useRef(false);

  const trigger = useCallback(() => {
    if (hasTriggered.current && triggerOnce) {
      console.log('already triggered');
      return false;
    }
    const command = getCommand({
      type,
      groupId,
      observationCode,
      attributes,
      durationBefore,
      durationAfter,
      autoRestart,
      description,
    });
    CommandBus.dispatch(command);

    hasTriggered.current = true;
  }, [
    type,
    groupId,
    observationCode,
    attributes,
    durationBefore,
    durationAfter,
    autoRestart,
    description,
    disabled,
    hasTriggered,
  ]);

  useEffect(() => {
    if (disabled === false) {
      if (hasTriggered.current && triggerOnce) {
        console.log('Reset triggered');
        hasTriggered.current = false;
      }
    }
  }, [disabled, hasTriggered, triggerOnce]);

  return render({ active, trigger, description, disabled });
};

const InputTrigger = observer(
  class InputTrigger extends Component {
    render() {
      const {
        type,
        groupId,
        observationCode,
        autoRestart,
        attributes,
        observationLogger,
        description,
        durationBefore,
        durationAfter,
      } = this.props;

      const active = isActive({
        type,
        observationLogger,
        groupId,
        observationCode,
        attributes,
      });

      return <InputTriggerInner {...this.props} active={active} />;
    }
  }
);

export { InputTrigger };
