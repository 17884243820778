import RecSVG from '../../../../img/icons/autocam_icon.svg';
import ReactTooltip from 'react-tooltip';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const AutomaticRecordingTooltip = () => {
  const { t } = useTranslation();
  return (
    <>
      <div
        data-tip=""
        data-for={'schedule'}
        className="overview-item-attribute"
      >
        <img className="scheduling-icon" src={RecSVG} alt="exchange icon" />
      </div>
      <ReactTooltip
        id="schedule"
        delayHide={500}
        place="right"
        type="dark"
        effect="solid"
        clickable={true}
        className={'tooltip--styled'}
        backgroundColor="#495057"
      >
        <p style={{ margin: '0px', marginBottom: '4px', lineHeight: '1.1' }}>
          {t('module.match:tooltipScheduled')}
        </p>
        <a
          target={'_blank'}
          href={t('module.match:automaticCameraUrl')}
          rel="noreferrer"
        >
          {t('common:learnMore')}
        </a>
      </ReactTooltip>
    </>
  );
};
