import { useTranslation, withTranslation } from 'react-i18next';
import { Modal } from '../../../lib';
import { useEffect, useState } from 'react';
import { TeamComponent } from './overview';
import TeamCollection from '../../../domain/Team';
import { Session } from '../../../domain/Session';
import { SubmittingButton } from '../../../lib/SubmittingButton';
import teamCollection from '../../../domain/Team';
import { NewPlayerInput, PlayerCard } from '../../lineup/components';
import apiClient from '../../../utils/ApiClient';
import personCollection from '../../../domain/Person';
import { getMembers } from '../../../domain/ResourceGroup';
import SweetAlert from '../../../lib/sweetAlert';

export const deleteTeam = async (teamId, callback = () => {}) => {
  await apiClient.delete(`/teams/${teamId}`, {});
  await callback();
};

export const unMergeTeam = async (
  teamName,
  canonicalTeamId,
  callback = () => {}
) => {
  let _team = await teamCollection.get(canonicalTeamId);
  let newAliases = _team.aliases().filter((name) => name !== teamName);
  await apiClient.post(`/teams/${canonicalTeamId}/setAsAliases`, {
    aliases: newAliases,
  });
  await callback();
};

export const unMergeTeams = async (canonicalTeamId, callback = () => {}) => {
  await apiClient.post(`/teams/${canonicalTeamId}/setAsAliases`, {});
  await callback();
};

const mergeTeams = async (teamNames, canonicalTeamId, callback = () => {}) => {
  await apiClient.post(`/teams/${canonicalTeamId}/setAsAliases`, {
    aliases: teamNames,
  });
  await callback();
};

export const deletePlayerFromTeam = async (
  teamId,
  personId,
  callBack = () => {}
) => {
  await apiClient.delete(`/teams/${teamId}/players/${personId}`, {});
  await callBack();
};

const findOrCreateMember = async (person) => {
  // check if person already exists when adding account.
  let members = await getMembers();
};

export const addPlayerToTeam = async (teamId, person, callback = () => {}) => {
  // Email is set => create member and person add to team
  // Email is not set => create person and add to team
  //   * @Put("/persons/{aPersonId}") ||  * @Post("/persons")
  let _person = await apiClient.post('/persons', {
    ...person,
  });

  let personId = _person.data.personId;

  let _player = await apiClient.post(`/teams/${teamId}/players`, {
    personId,
    ...person,
  });

  return _player;
};

export const MergeTeamsModal = ({ teams, onClose, callback }) => {
  const [canonicalTeam, setCanonicalTeam] = useState(teams[0]);
  const otherTeams = teams.filter(
    (team) => team.teamId !== canonicalTeam.teamId
  );

  const { t } = useTranslation('module.settings.club');
  return (
    <Modal onCloseClick={onClose}>
      <div className="modal-header">
        <h5>{t('merge')}</h5>
      </div>
      <div className="modal-body">
        {t('mergeTeamsDescription', { canonicalTeam })}
        <div className="parent-team-wrapper mt-4">
          {canonicalTeam?._metadata.source?.share && (
            <div className="userlogin__warning mb-4">
              <label>{t('exchangeTeamCanonicalWarning')}</label>
              <p>{t('exchangeTeamCanonicalWarningBody')}</p>
            </div>
          )}
          <TeamComponent team={canonicalTeam} />
        </div>
        {otherTeams.map((team) => (
          <div className={'sub-team-wrapper'} style={{ paddingLeft: '2rem' }}>
            <TeamComponent
              onClick={() => setCanonicalTeam(team)}
              team={team}
            ></TeamComponent>
          </div>
        ))}
      </div>
      <div className="modal-footer">
        <div className="form-actions">
          <SubmittingButton
            className="btn btn-primary"
            onClick={async () =>
              mergeTeams(
                otherTeams.map((t) => t.name),
                canonicalTeam.teamId,
                callback
              )
            }
          >
            {t('common:save')}
          </SubmittingButton>
          <button
            onClick={onClose}
            disabled={canonicalTeam?._metadata.source?.share}
            className="btn btn-link"
          >
            {t('common:cancel')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const createTeam = async (teamName) => {
  let newTeamId = TeamCollection.nextIdentity();
  let currentSession = Session.current();
  return await TeamCollection.create({
    teamId: newTeamId,
    name: teamName,
    sportType: currentSession.sportType(),
  });
};

export const CreateTeamModal = ({ input, onCloseClick, callback }) => {
  const [value, setValue] = useState(input);
  const { t } = useTranslation('module.settings.club');
  return (
    <Modal onCloseClick={onCloseClick}>
      <div className="modal-header">
        <h5>{t('addTeam')}</h5>
      </div>
      <div className="modal-body">
        <div className="form-row">
          <label>{t('teamName')}</label>
          <input
            onChange={(e) => setValue(e.target.value)}
            type="text"
            value={value}
            className="form-control"
            placeholder={t('teamName')}
          />
        </div>
      </div>
      <div className="modal-footer">
        <div className="action">
          <SubmittingButton
            className={'btn btn-primary'}
            onClick={async () => {
              await createTeam(value);
              onCloseClick();
            }}
          >
            {t('addTeam')}
          </SubmittingButton>
          <button onClick={onCloseClick} className="btn btn-link">
            {t('commonm:cancel')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export const ManagePlayersModal = ({ teamId, onCloseClick }) => {
  const [loading, setLoading] = useState(true);
  const [team, setTeam] = useState([]);
  const [addPlayer, setAddPlayer] = useState(false);
  // const [players, setPlayers] = useState([]);
  const { t } = useTranslation('module.settings.club');

  useEffect(() => {
    (async () => {
      const _team = teamCollection.get(teamId);
      setTeam(_team);
      setLoading(false);
    })();
  }, [teamId]);

  const refetch = async () => {
    await teamCollection.fetch();
    const _team = teamCollection.get(teamId);
    setTeam(_team);
  };

  let currentSession = Session.current();
  const isOwnTeam = currentSession.currentResource().teamId == team.teamId;

  if (loading) {
    return null;
  }

  if (addPlayer) {
    return (
      <Modal onCloseClick={() => setAddPlayer(false)}>
        <NewPlayerInput
          onAdd={async (firstName, lastName, gender, number, email = null) => {
            let _person = {
              firstName,
              lastName,
              gender,
              number,
              email,
            };
            console.log(_person.email);
            if (_person.email) {
              // Create account etc..
              await findOrCreateMember(_person);
            } else {
              await addPlayerToTeam(teamId, _person);
            }
            await refetch();
            setAddPlayer(false);
            // Add member
          }}
          createAccount={isOwnTeam}
        />
      </Modal>
    );
  }

  return (
    <Modal size={'lg'} onCloseClick={onCloseClick}>
      <div className="modal-header ">
        <h5>
          {team.name} {t('managePlayers')}
        </h5>
      </div>
      <div className="modal-body edit-players">
        <div className="form-row">
          <div className="col-md-6 col-lg-4 col-6 mb-2">
            <div
              onClick={() => {
                setAddPlayer(true);
              }}
              className={'playercard add-player'}
            >
              <h4>{t('addPlayer')}</h4>
            </div>
          </div>
          <PlayerRows teamId={teamId} players={team.players} />
        </div>
        {false &&
          !team.aliasOf() && // todo: show and manage players from aliases
          team?.aliases()?.map((alias, index) => {
            if (alias.playerCount > 0) {
              return (
                <div className="form-row" key={index}>
                  <h6>Players from: {alias.name}</h6>
                  <div className="row">
                    <PlayerRows teamId={alias.team} players={alias.players} />
                  </div>
                </div>
              );
            }
          })}
      </div>
      <div className="modal-footer">
        <div className="action">
          <button onClick={onCloseClick} className="btn btn-link">
            {t('common:cancel')}
          </button>
        </div>
      </div>
    </Modal>
  );
};

const PlayerRows = ({ teamId, players, callback = () => {} }) => {
  const [_players, setPlayers] = useState([]);
  const { t } = useTranslation('module.settings.club');
  useEffect(() => {
    (async () => {
      const __players = await players.fetch();
      setPlayers(__players);
    })();
  }, []);

  const refetch = async () => {
    const __players = await players.fetch();
    setPlayers(__players);
  };

  if (_players.length === 0) {
    return null;
  }

  return _players?.map((player, index) => {
    let playerCardProps = {
      number: player?.number ?? 'X',
      firstName: player?.person?.firstName,
      lastName: player?.person?.lastName,
      profilePictureUrl: player?.person?.profilePictureUrl,
      onRemovePlayer: () => {
        let fullName = player?.person?.fullName;
        SweetAlert({
          title: t('removePlayer'),
          // text: `Remove ${fullName ?? 'speler'} from {teamname?}`,
          text: t('removePlayerFrom', { playerName: fullName }),
          dangerMode: true,
          buttons: [t('common:cancel'), t('common:confirm')],
        }).then(async (willDelete) => {
          if (willDelete) {
            await deletePlayerFromTeam(teamId, player.personId, refetch);
          } else {
            refetch();
          }
        });
      },
    };
    return (
      <div className={'col-md-6 col-lg-4 col-6 mb-2'} key={index}>
        <PlayerCard {...playerCardProps} />
      </div>
    );
  });
};
