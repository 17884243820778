import { createTransformer, extendObservable, action } from 'mobx';

import asyncLimit from 'lib/asyncLimit';

import ObservationCollection from '../Observation';
import TeamCollection from '../Team';
import VideoCollection from '../Video';
import ReportCollection from '../Report';
import { PersistentCollection, Model } from '../PersistentCollection';
import moment from 'moment';
import { now } from 'mobx-utils';
import { MatchClockHelper } from '../ObservationLogger/Clock';
import { TimeMapper } from 'domain/ObservationLogger/Clock';
import { Session } from 'domain/Session';
import {isDev} from "../../lib/dev";

class EventStream extends Model {
  get primaryKey() {
    return 'eventStreamId';
  }
}

class EventStreamCollection extends PersistentCollection {
  model() {
    return EventStream;
  }

  url() {
    return this.url_;
  }
}

let sportingEventCollection = null;

class SportingEvent extends Model {
  constructor(attributes) {
    super(attributes);

    this.observationCollections = {};
    this._reportCollection = null;
    this.observationCollection = null;
    this._eventStreamCollection = null;

    this._observationTransformers = {};
  }

  get primaryKey() {
    return 'sportingEventId';
  }

  initVideoUpload(files, clockId) {
    return this.rpc('initVideoUpload', {
      files,
      clockId,
    });
  }

  addVideo(videoId) {
    return this.rpc('addVideo', { videoId }).then(() => this.fetch());
  }

  async _getLocalTeamId(team) {
    const currentSession = Session.current();

    const shareTeamId = this.get('_metadata').source.share.attributes.teamId;
    if (shareTeamId === team.id) {
      return currentSession.currentResource().id;
    } else {
      for (const localTeam of TeamCollection.toArray()) {
        if (localTeam.tags.copyOf === team.id) {
          return localTeam.id;
        }
      }
      // no local copy yet
      const localTeam = await TeamCollection.create({
        name: team.name,
        sportType: team.sportType,
        tags: {
          copyOf: team.id,
        },
      });
      return localTeam.teamId;
    }
  }

  clocks() {
    return this.get('clocks');
  }

  get observationLog() {
    if (this.observationCollection === null) {
      this.observationCollection = new ObservationCollection(
        `${this.url()}/observations/U1`
      );
      this.observationCollection.setObservationLogId(
        this.get('observationLogId')
      );
    }
    return this.observationCollection;
  }

  get eventStreams() {
    if (this._eventStreamCollection === null) {
      this._eventStreamCollection = new EventStreamCollection(
        `${this.url()}/eventStreams`
      );
    }
    return this._eventStreamCollection;
  }

  getCachedObservationLogProjection(destinationClock) {
    if (
      typeof this._observationTransformers[destinationClock.clockId()] ===
      'undefined'
    ) {
      this._observationTransformers[destinationClock.clockId()] =
        createTransformer((observationLogModels) => {
          return TimeMapper.mapObservations(
            observationLogModels.slice(),
            destinationClock,
            this
          );
        });
    }

    return this._observationTransformers[destinationClock.clockId()](
      this.observationLog.models
    );
  }

  getObservationCollection(clockId) {
    const destinationClock = MatchClockHelper.getClockByClockId(this, clockId);
    clockId = destinationClock.clockId();

    // TODO Cleanup this garbage!
    return {
      reload: action(() => {
        //force refresh all observers
        this.observationLog.models.push(1);
        this.observationLog.models.pop();
      }),
      getMappingStats: () =>
        this.getCachedObservationLogProjection(destinationClock).mappingStats,
      toArray: () =>
        this.getCachedObservationLogProjection(destinationClock)
          .projectedObservations,
      getRawObservations: () => this.observationLog.toArray(),
      getClockOwner: () => this,
      fetchIfEmpty: () => this.observationLog.fetchIfEmpty(),
      fetch: () => this.observationLog.fetch(),
      addBulkObservation: (...args) => {
        const observationLog = new ObservationCollection(
          `${this.url()}/observations/${clockId}`
        );
        return observationLog.addBulkObservation(...args);
      },
      build: (...args) => {
        const observationLog = new ObservationCollection(
          `${this.url()}/observations/${clockId}`
        );
        return observationLog.build(...args);
      },
      add: (...args) => {
        return this.observationLog.add(...args);
      },
      get: (id) => {
        return this.observationLog.get(id);
      },
      remove: (observationId) => {
        return this.observationLog.remove(observationId);
      },
      nextIdentity: () => this.observationLog.nextIdentity(),
    };
  }

  markReportsAsStale() {
    this.reportCollection.toArray().map((report) => {
      report.markAsStale();
    });
  }

  getClockSourceByClockId(clockId) {
    for (const [clockSource, clock] of Object.entries(this.clocks())) {
      if (clock.clockId === clockId) {
        return clockSource;
      }
    }
  }

  addSynchronizationPoint(clockId, type, key, time) {
    const clocks = this.get('clocks');
    const clockSource = this.getClockSourceByClockId(clockId);
    const synchronizationPoints = clocks[clockSource].synchronizationPoints;
    synchronizationPoints.push({
      type,
      key,
      time,
    });

    this.markReportsAsStale();
  }

  removeSynchronizationPoint(clockId, type, key) {
    const clocks = this.get('clocks');
    const clockSource = this.getClockSourceByClockId(clockId);
    const synchronizationPoints = clocks[clockSource].synchronizationPoints;

    const index = synchronizationPoints.findIndex(
      ({ type: sType, key: sKey }) => sType === type && sKey === key
    );
    synchronizationPoints.splice(index, 1);

    this.markReportsAsStale();
  }

  getVideoClock() {
    const videoId = this.mainVideoId();
    if (videoId === null) {
      return null;
    }

    return this.clocks()[videoId];
  }

  getVideoSynchronizationPoints() {
    const videoId = this.mainVideoId();
    if (videoId === null) {
      return [];
    }

    const videoClock = this.clocks()[videoId];
    if (!videoClock) {
      // Shared video but local data
      return [];
    }
    return videoClock.synchronizationPoints.slice();
  }

  getTeamNames() {
    const name = this.get('name');
    let splitChars = ' - ';
    if (name.indexOf('|') !== -1) {
      splitChars = '|';
    }
    if (name.indexOf(' VS ') !== -1) {
      splitChars = ' VS ';
    }
    return name.split(splitChars).map((e) => e.trim());
  }

  hasTeams() {
    return (
      (this.has('homeTeamId') && this.get('homeTeamId') !== 'not_set') ||
      (this.getTeamNames().length > 1 &&
        Session.current().isFeatureAvailable('teamNames'))
    );
  }
  get homeTeamId() {
    return this.has('homeTeamId') ? this.get('homeTeamId') : 'not_set';
  }
  get awayTeamId() {
    return this.has('awayTeamId') ? this.get('awayTeamId') : 'not_set';
  }

  get type() {
    return this.has('type') ? this.get('type') : 'match';
  }

  get videoIds() {
    return this.has('videoIds') ? this.get('videoIds') : [];
  }

  get homeTeam() {
    const teamId = this.has('homeTeamId') ? this.get('homeTeamId') : 'not_set';
    if (teamId === 'not_set') {
      const [name, _] = this.getTeamNames();

      return TeamCollection.build({
        teamId: 'home',
        name: name || '',
        appearance: {},
      });
    } else {
      if (!TeamCollection.get(teamId)) {
        const [name, _] = this.getTeamNames();
        console.log('cannot get', teamId, name);
        return TeamCollection.build({
          teamId,
          name: name || '',
          appearance: {},
        });
      }
      return TeamCollection.get(teamId);
    }
  }

  get awayTeam() {
    const teamId = this.has('awayTeamId') ? this.get('awayTeamId') : 'not_set';
    if (teamId === 'not_set') {
      const [_, name] = this.getTeamNames();
      return TeamCollection.build({
        teamId: 'away',
        name: name || '',
        appearance: {},
      });
    } else {
      if (!TeamCollection.get(teamId)) {
        const [_, name] = this.getTeamNames();
        console.log('cannot get', teamId, name);
        return TeamCollection.build({
          teamId: teamId,
          name: name|| '',
          appearance: {},
        });
      }
      return TeamCollection.get(teamId);
    }
  }

  async loadTeams() {
    const promises = [];
    const homeTeamId = this.has('awayTeamId') && this.get('awayTeamId')
    const awayTeamId = this.has('awayTeamId') && this.get('awayTeamId')
    if (homeTeamId !== 'not_set') {
      promises.push(
        (async () => {
          const team = await TeamCollection.getOrFetch(this.get('homeTeamId'));
          if(!team){
            const [ name,_] = this.getTeamNames();
            isDev() && console.log('cannot fetch', homeTeamId, name);
            TeamCollection.build({
              teamId: homeTeamId,
              name: name || '',
              appearance: {},
            });
          }
          await team.players.fetchIfEmpty();
        })()
      );
    }
    if (awayTeamId !== 'not_set') {
      promises.push(
        (async () => {
          const team = await TeamCollection.getOrFetch(this.get('awayTeamId'));
          if(!team){
            const [ name,_] = this.getTeamNames();
            isDev() && console.log('cannot fetch', awayTeamId, name);
            TeamCollection.build({
              teamId: awayTeamId,
              name: name || '',
              appearance: {},
            });
          }
          await team.players.fetchIfEmpty();
        })()
      );
    }
    if (this.type === 'training') {
      promises.push(
        (async () => {
          await Session.current()._currentResource.players.fetchIfEmpty();
        })()
      );
    }

    return Promise.all(promises);
  }

  getAllPlayers = () => {
    if (this.type === 'match') {
      return [
        ...this.homeTeam.players.toArray(),
        ...this.awayTeam.players.toArray(),
      ];
    }
    if (this.type === 'training') {
      return [...Session.current()._currentResource.players.toArray()];
    }
    return [];
  };

  get label() {
    if (!this.hasTeams()) {
      return this.get('name');
    }
    return `${this.homeTeam.label} - ${this.awayTeam.label}`;
  }

  get scheduledAt() {
    return this.get('scheduledAt');
  }

  get reportCollection() {
    if (this._reportCollection === null) {
      this._reportCollection = new ReportCollection(`${this.url()}/reports`);
    }
    return this._reportCollection;
  }

  async getReport(reportId, refetchIfStale = true) {
    const report = await this.reportCollection.getOrFetch(reportId);
    if (report.isStale() && refetchIfStale) {
      await report.fetch();
    }
    return report;
  }

  get periodCount() {
    if (this.matchConfig?.periodCount) {
      return this.matchConfig.periodCount;
    }

    const currentSession = Session.current();
    const sportRules = currentSession.getSportRules();
    return sportRules.periodCount;

    if (currentSession) {
      const resourceGroupId = currentSession.resourceGroupId();
      if (
        [
          '70d47ed0-4d96-11ea-b9bf-1d6c6c8d59d7',
          '10659a3c-f00a-11e9-aa95-374f49580ed0',
          'fc035cc8-4351-11ea-87af-9135e054c0da',
        ].indexOf(resourceGroupId) !== -1
      ) {
        return 4;
      }
    }
    if (window.isSkillReflect) {
      return 1;
    }
    return 2;
  }

  get substituteCount() {
    return 8;
  }

  privilegedTo(action, onlyCheckOriginal = false) {
    if (!this.has('_metadata')) {
      return false;
    }

    const privileges = this.get('_metadata').privileges;
    if (privileges.length === 0) {
      // Hint: when view privilege is not included this
      //       item should never be here.. this means
      //       its created client side and we cannot check privileges
      return true;
    }
    const privilege = `sporting-event:${action}`;

    const privileged = privileges.indexOf(privilege) !== -1;
    // Dissallow video uploading for automatic camera recordings..
    if (
      action === 'init-upload' &&
      this.has('tags') &&
      this.get('tags').automaticCameraConfig?.record
    ) {
      return false;
    }

    if (!privileged && this.isShared()) {
      const currentSession = Session.current();
      const privileges = currentSession.currentPrivileges();

      // Our account has privileges to this action and we
      // are able to create a new sportingEvent.
      if (
        privileges.hasPrivilege(privilege) &&
        privileges.hasPrivilege('sporting-event:create') &&
        !onlyCheckOriginal // don't create a new one to delete it afterwards
      ) {
        return true;
      }
    }
    return privileged;
  }

  getPrivilegedToSportingEventId = asyncLimit(
    async (action, onlyLocal = false) => {
      /**
       * onlyLocal can be used to make sure we create a copy in the following case:
       * 1. We are part of an Exchange
       * 2. We are allowed to observe on the exchange matches
       * 3. We are going to do video tagging
       *
       * This would normally result in video tagging on the match from the Exchange. The onlyLocal argument
       * forces to create a local copy anyway, and do the tagging there.
       */
      if (!this.has('_metadata')) {
        return this.id;
      }

      const privileges = this.get('_metadata').privileges;
      if (privileges.length === 0) {
        // Hint: when view privilege is not included this
        //       item should never be here.. this means
        //       its created client side and we cannot check privileges
        return this.id;
      }
      const privilege = `sporting-event:${action}`;

      const privileged = privileges.indexOf(privilege) !== -1;

      if (privileged && !(this.isShared() && onlyLocal)) {
        return this.id;
      } else if (this.isShared()) {
        return await this.rpc('getPrivilegedToSportingEventId', {
          action,
          copyData: this.isDemo(),
        });
      }
    },
    1
  );

  isMatchOf(currentTeam) {
    if (this.isDemo()) {
      return true;
    }

    if (this.isShared()) {
      const shareTeamId = this.get('_metadata').source.share.attributes.teamId;
      if (
        shareTeamId === this.homeTeam.id ||
        shareTeamId === this.awayTeam.id
      ) {
        return true;
      }
    }

    return (
      this.homeTeam.isSameAs(currentTeam) || this.awayTeam.isSameAs(currentTeam)
    );
  }

  started() {
    return MatchClockHelper.isStarted(this);
  }

  ended() {
    return !this.isLive() && MatchClockHelper.isEnded(this);
  }

  isFuture() {
    const today = moment();
    return (
      !this.ended() &&
      moment(this.get('scheduledAt')).isSameOrAfter(today, 'day')
    );
  }

  mainSynchronizationPoints() {
    const clock = MatchClockHelper.getClockByClockId(this, 'main');
    return clock.synchronizationPoints();
  }

  probablyEnded() {
    const now = moment();
    const estimatedEndOfMatch = moment(this.get('scheduledAt')).add(
      1.5,
      'hours'
    );
    return this.ended() || estimatedEndOfMatch.isBefore(now) || this.hasVideo();
  }

  mainClock(videoFirst = false) {
    return MatchClockHelper.getClockByClockId(this, 'main', videoFirst);
  }

  isArchived() {
    return !this.privilegedTo('view-video', true);
  }

  mainVideoId() {
    const videoIds = this.get('videoIds');
    if (videoIds.length > 0) {
      // Check for ready video with main feed id
      for (const videoId of videoIds) {
        const video = VideoCollection.get(videoId);
        if (
          !!video &&
          (video.isReady || video.has('livestream')) &&
          video.tags?.is_main_feed
        ) {
          return video.id;
        }
      }
      // Check for ready video
      for (const videoId of videoIds) {
        const video = VideoCollection.get(videoId);
        if (!!video && video.isReady) {
          return video.id;
        }
      }

      // Check for non-ready existing video
      for (const videoId of videoIds) {
        const video = VideoCollection.get(videoId);
        if (!!video) {
          return video.id;
        }
      }
      // fallback
      return videoIds[videoIds.length - 1];
    } else {
      if (this.isCopy()) {
        return this.original.mainVideoId();
      } else {
        return null;
      }
    }
  }

  readyVideoIds() {
    const videoIds = this.get('videoIds');
    let readyVideoIds = [];
    if (videoIds.length > 0) {
      // Check for ready video
      for (const videoId of videoIds) {
        const video = VideoCollection.get(videoId);
        if (!!video && video.isReady) {
          readyVideoIds.push(video.id);
        }
      }
    }
    return readyVideoIds;
  }

  isLiveRecording() {
    return this.mainVideoId() === 'live-recording';
  }
  hasMultiVideo() {
    return this.get('videoIds').length > 1;
  }

  multiVideo() {
    if (this.hasMultiVideo()) {
      const readyVideoIds = this.get('videoIds').filter((videoId) => {
        const video = VideoCollection.get(videoId);
        return video?.isReady;
      });
      if (readyVideoIds.length > 1) {
        return readyVideoIds;
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  hasVideo() {
    if (
      this.has('videoIds') &&
      this.get('videoIds').length > 0 &&
      !this.isLive()
    ) {
      return true;
    } else {
      if (this.isCopy()) {
        return this.original.hasVideo();
      } else {
        return false;
      }
    }
  }
  livestreamVideoIds() {
    const videoIds = this.get('videoIds');
    let liveStreamVideoIds = [];
    if (videoIds.length > 0) {
      // Check for ready video
      for (const videoId of videoIds) {
        const video = VideoCollection.get(videoId);
        if (!!video && video.has('livestream')) {
          liveStreamVideoIds.push(video.id);
        }
      }
    }
    return liveStreamVideoIds;
  }

  isLive() {
    return this.livestreamVideoIds().length > 0;
  }

  isLiveStreamAvailable() {
    return this.livestreamVideoIds().length > 0;
  }

  async syncVideos() {
    if (this.isCopy()) {
      const videoIds = this.get('videoIds');
      const originalVideoMissing = !!this.original
        .get('videoIds')
        .find((videoId) => videoIds.indexOf(videoId) === -1);

      // check of original has video
      if (originalVideoMissing) {
        await this.rpc('syncVideos');
        await this.fetch();
      }
    }
  }

  deleteVideo(videoId, refresh = true) {
    return this.rpc('deleteVideo', { videoId }).then(
      () => refresh && this.fetch()
    );
  }

  async setMatchConfig(matchConfig) {
    const response = await this.rpc('setMatchConfig', { matchConfig });

    // Update the tags within this instance. The `refresh` method replaces the entire
    // this object with all its references. This breaks the tagging.
    this.set({
      tags: {
        ...this.tags,
        matchConfig,
      },
    });
    return response;
  }

  setAutomaticCameraConfig(automaticCameraConfig, refresh = true) {
    return this.rpc('setAutomaticCameraConfig', { automaticCameraConfig }).then(
      () => refresh && this.fetch()
    );
  }
  reschedule(scheduledAt, refresh = true) {
    return this.rpc('reschedule', { scheduledAt }).then(
      () => refresh && this.fetch()
    );
  }

  copyDataFromShared(clockId) {
    return this.rpc('copyDataFromShared', { clockId });
  }

  toMetaData() {
    return {
      sportingEventId: this.id,
      label: this.label,
      scheduledAt: this.scheduledAt,

      // breaks for non-match
      homeTeam: {
        teamId: this.homeTeam.id,
        label: this.homeTeam.label,
      },
      awayTeam: {
        teamId: this.awayTeam.id,
        label: this.awayTeam.label,
      },
    };
  }

  get matchConfig() {
    return this.tags.matchConfig || this.tags.matchConfiguration;
  }

  get automaticCameraConfig() {
    return this.tags.automaticCameraConfig;
  }

  hasScore() {
    return !!this.tags.score;
  }

  getScore() {
    return this.tags.score;
  }

  async setACL(allowedRoleNames) {
    const currentResourceGroup = Session.current().currentResourceGroup();
    await currentResourceGroup.setResourceACL(`sporting-event:${this.id}`, {
      allowedRoleNames,
    });
    if (this.mainVideoId()) {
      try {
        await currentResourceGroup.setResourceACL(
          `video:${this.mainVideoId()}`,
          {
            allowedRoleNames,
          }
        );
      } catch (e) {
        // probably a share video
      }
    }
    await this.fetch();
  }

  get allowedRoleNames() {
    const acl = this.get('_metadata').acl;
    if (!acl) {
      return null;
    } else {
      return acl.rules.map((rule) => rule.identityPattern.attributes.roleName);
    }
  }

  async setObservationLogACL(aclKey) {
    return this.rpc('setObservationLogACL', { aclKey });
  }

  async getObservationLogACL(aclKey) {
    return this.rpc('getObservationLogACL', { aclKey });
  }
}

class SportingEventCollection extends PersistentCollection {
  url() {
    return '/sportingEvents';
  }

  model() {
    return SportingEvent;
  }

  getFutureMatches() {
    return this.uniqueArray().filter((sportingEvent) => {
      return sportingEvent.isFuture();
    });
  }

  getUserMadeMatches() {
    return this.uniqueArray().filter((sportingEvent) => {
      return !sportingEvent.isDemo() && !sportingEvent.isShared();
    });
  }

  getPastMatches() {
    const sportingEvents = this.uniqueArray().filter((sportingEvent) =>
      sportingEvent.probablyEnded()
    );
    sportingEvents.sort((a, b) => {
      return a.get('scheduledAt') === b.get('scheduledAt')
        ? 0
        : a.get('scheduledAt') > b.get('scheduledAt') ||
          b.get('scheduledAt') === false
        ? -1
        : 1;
    });
    return sportingEvents;
  }
}

sportingEventCollection = new SportingEventCollection();

console.log('init sport collection');
window.sportingEventCollection = sportingEventCollection;

export default sportingEventCollection;
