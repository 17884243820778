import uuidv4 from 'uuid/v4';

import { Collection, Model as RestModel, apiClient } from 'mobx-rest';
import { autorun } from 'mobx';
import logger from 'utils/Logger';

class Model extends RestModel {
  isShared() {
    return (
      this.has('_metadata') &&
      this.get('_metadata').source.type === 'SharedResourceGroup'
    );
  }

  isDemo() {
    return (
      (this.isShared() && this.shareRole() === 'demo') ||
      this.original?.shareRole() === 'demo'
    );
  }

  shareRole() {
    if (this.has('_metadata') && this.get('_metadata').source.share) {
      return this.get('_metadata').source.share.role;
    }
    return null;
  }

  get original() {
    if (this.isCopy()) {
      return this.collection.get(this.tags.copyOf);
    }
    return null;
  }

  get tags() {
    return this.has('tags') ? this.get('tags') : {};
  }

  isCopied() {
    return (
      !!this.getLocalCopy() || (!!this._isCopiedShare && this._isCopiedShare())
    );
  }

  getLocalCopy() {
    for (const item of this.collection.toArray()) {
      // console.log(se.tags.copyOf);
      if (item.tags.copyOf === this.id) {
        return item;
      }
    }
    return false;
  }

  isCopy() {
    const _isCopy = !!this.tags.copyOf;
    if (_isCopy) {
      if (!this.collection.get(this.tags.copyOf)) {
        //logger.error(
        //  `SportingEvent ${this.id} is copy of non-existing ${this.tags.copyOf}`
        //);
        return false;
      }
    }
    return _isCopy;
  }

  async getRequest(path) {
    return await apiClient().get(`${this.url()}/${path}`).promise;
  }
}

class PersistentCollection extends Collection {
  storeName() {
    // TODO: when the collection is created with incorrect url (when parent had no id yet for example, so DevelopmentPlan)
    //       stuff might go wrong.
    return `store:${this.url()}`;
  }

  static emptyCollection() {}

  async getOrFetch(id) {
    let obj = this.get(id);
    if (obj === undefined) {
      const primaryKey = new (this.model())().primaryKey;
      obj = this.add([{ [primaryKey]: id }])[0];
      try {
        await obj.fetch();
      } catch (e) {
        this.remove([id]);
        throw e;
      }
    }
    if (typeof obj.tags !== 'undefined') {
      if (!!obj.tags.copyOf) {
        try {
          await this.getOrFetch(obj.tags.copyOf);
        } catch (e) {
          console.error(`Could not load copy of ${id} => ${obj.tags.copyOf}`);
        }
      }
    }
    return Promise.resolve(obj);
  }

  getWithoutCreate(id) {
    return new (this.model({ id }))();
  }

  getOrCreate(id) {
    let obj = this.get(id);
    if (obj === undefined) {
      const primaryKey = new (this.model())().primaryKey;
      obj = this.add([{ [primaryKey]: id }])[0];
    }
    return obj;
  }

  setUrl(url) {
    this.url_ = url;
  }

  constructor(url) {
    super();

    // TODO: cleanup this crap
    if (typeof url !== 'undefined') {
      this.setUrl(url);
    }

    this._fetched = false;

    return;

    this.reactionOnRestore = false;
    this.restore();

    autorun(() => {
      const models = this.models.toJS();

      if (this.reactionOnRestore) {
        this.reactionOnRestore = false;
        return;
      }

      const rawData = [];
      for (const model of models) {
        rawData.push(model.toJS());
      }
      window.localStorage.setItem(this.storeName(), JSON.stringify(rawData));
    });
  }

  restore() {
    this.reactionOnRestore = true;
    const data = window.localStorage.getItem(this.storeName());
    if (data !== null) {
      const models = JSON.parse(data);

      this.reset(models);
    }
  }

  async ensureLoaded() {
    console.log('fetching', this.storeName());
    if (this.isEmpty() || !this._fetched) {
      if (!!this._fetching_promise) {
        return await this._fetching_promise;
      } else {
        this._fetching_promise = this.fetch();
        const data = await this._fetching_promise;
        this._fetching_promise = null;
        this._fetched = true;
        return data;
      }
    } else {
      return Promise.resolve(true);
    }
  }

  fetchIfEmpty() {
    return this.ensureLoaded();
  }

  loading() {
    return this.isRequest('fetching');
  }

  isLoading() {
    return this.loading();
  }

  nextIdentity() {
    return uuidv4();
  }

  addNew(obj) {
    return this.add([obj])[0];
  }

  async rpc(method, body) {
    const { promise, abort } = apiClient().post(
      `${this.url()}/${method}`,
      body || {}
    );
    return promise;
  }

  uniqueArray() {
    // return local copy if available
    return this.toArray().filter((item) => {
      return !item.isCopied();
    });
  }

  uniqueArrayEditable() {
    // return local copy if available
    return this.toArray().filter((item) => {
      return !item.isCopied() && !item.isShared() && !item.aliasOf();
    });
  }
}

export { PersistentCollection, Model };
