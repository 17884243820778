import React, { Component, Fragment } from 'react';
import { observable, action } from 'mobx';
import { observer } from 'mobx-react';
import { asyncAction } from 'mobx-utils';
import momentjs from 'moment';

import { Modal } from 'lib/Modal';

import logger from 'utils/Logger';

import Loading from 'lib/Loading';

import clubPlaceholderIcon from 'img/icons/club_placeholder.svg';

import { OverviewItem } from 'lib/OverviewItem';
import { TeamManagementNew, PersonManagementNew } from './new';

import ResourceGroupCollection from 'domain/ResourceGroup';
import { Session } from 'domain/Session';
import { AuthAwareAdapterProxy } from 'utils/AuthAwareAdapterProxy';
import { withTranslation } from 'react-i18next';
import { Page } from 'lib/Page';
import { Error } from 'lib/PlaceHolder';
import { getRoutePath } from 'modules/route';
import sweetAlert from 'lib/sweetAlert';

const ClubTeamManagementOverview = withTranslation('module.settings.club')(
  observer(
    class TeamManagement extends Component {
      constructor() {
        super();
        this.dataState = observable('loading');

        // this.createCustomTagsDialogIsOpen = observable(false);

        this.createTeamDialogIsOpen = observable(false);
      }

      openCreateTeamDialog = action(() => {
        this.createTeamDialogIsOpen.set(true);
      });

      closeCreateTeamDialog = action(() => {
        this.createTeamDialogIsOpen.set(false);
      });

      // openCreateCustomTagsDialog = action(() => {
      //   this.createCustomTagsDialogIsOpen.set(true);
      // });

      // closeCreateCustomTagsDialog = action(() => {
      //   this.createCustomTagsDialogIsOpen.set(false);
      // });

      componentWillMount() {
        this.loadData();
      }

      loadData = asyncAction(function* () {
        try {
          this.dataState.set('loading');

          yield Session.current().isReady();

          const adminResourceGroupId =
            Session.current().getClubAdminResourceGroupId();

          yield AuthAwareAdapterProxy.withUseResourceGroup(
            adminResourceGroupId,
            () => ResourceGroupCollection.fetchIfEmpty()
          );

          this.dataState.set('loaded');
        } catch (e) {
          logger.error(e, {
            transactionName: 'Unable to load team overview',
          });
          this.dataState.set('error');
        }
      });

      addResourceGroup(attributes) {
        const adminResourceGroupId =
          Session.current().getClubAdminResourceGroupId();

        return AuthAwareAdapterProxy.withUseResourceGroup(
          adminResourceGroupId,
          () =>
            ResourceGroupCollection.create(attributes, { optimistic: false })
        );
      }

      addTeam(teamName) {
        return this.addResourceGroup({ name: teamName });
      }

      addAdmin(email) {
        const adminResourceGroupId =
          Session.current().getClubAdminResourceGroupId();

        AuthAwareAdapterProxy.withUseResourceGroup(
          adminResourceGroupId,
          async () => {
            const resourceGroup = new (ResourceGroupCollection.model())({
              resourceGroupId: adminResourceGroupId,
            });
            resourceGroup.collection = ResourceGroupCollection;
            await resourceGroup.members.create({
              firstName: '',
              lastName: '',
              email,
              roleNames: ['admin'],
            });
          }
        );
      }

      addMemberToCurrentRG(email) {
        const resourceGroup = new (ResourceGroupCollection.model())({
          resourceGroupId: Session.current().resourceGroupId(),
        });
        resourceGroup.collection = ResourceGroupCollection;
        resourceGroup.members.create({
          firstName: '',
          lastName: '',
          email,
          roleNames: ['Admin'],
        });
      }

      listMembersOfCurrentRG() {
        const resourceGroup = new (ResourceGroupCollection.model())({
          resourceGroupId: Session.current().resourceGroupId(),
        });
        resourceGroup.collection = ResourceGroupCollection;
        resourceGroup.members.fetch();
      }

      addAdmin(email) {
        const adminResourceGroupId =
          Session.current().getClubAdminResourceGroupId();

        AuthAwareAdapterProxy.withUseResourceGroup(
          adminResourceGroupId,
          async () => {
            const resourceGroup = new (ResourceGroupCollection.model())({
              resourceGroupId: adminResourceGroupId,
            });
            resourceGroup.collection = ResourceGroupCollection;
            await resourceGroup.members.create({
              firstName: '',
              lastName: '',
              email,
              roleNames: ['admin'],
            });
          }
        );
      }

      listAdmins() {
        const adminResourceGroupId =
          Session.current().getClubAdminResourceGroupId();

        AuthAwareAdapterProxy.withUseResourceGroup(
          adminResourceGroupId,
          async () => {
            const resourceGroup = new (ResourceGroupCollection.model())({
              resourceGroupId: adminResourceGroupId,
            });
            resourceGroup.collection = ResourceGroupCollection;
            await resourceGroup.members.fetch();
            for (const member of resourceGroup.members.toArray()) {
              console.log(`${member.fullName} - ${member.id}`);
            }
          }
        );
      }

      removeAdmin(email) {
        const adminResourceGroupId =
          Session.current().getClubAdminResourceGroupId();

        AuthAwareAdapterProxy.withUseResourceGroup(
          adminResourceGroupId,
          async () => {
            const resourceGroup = new (ResourceGroupCollection.model())({
              resourceGroupId: adminResourceGroupId,
            });
            resourceGroup.collection = ResourceGroupCollection;
            await resourceGroup.members.fetchIfEmpty();
            await resourceGroup.members
              .toArray()
              .find((member) => member.get('email') === email)
              .destroy();
            console.log(`${email} removed`);
          }
        );
      }

      onDelete(team) {
        sweetAlert({
          title: this.props.t('deleteTeamConfirmTitle'),
          text: this.props.t('deleteTeamConfirmMessage', { team }),
          dangerMode: true,
          buttons: [
            this.props.t('common:cancel'),
            this.props.t('common:confirm'),
          ],
        }).then((willDelete) => {
          if (willDelete) {
            this._onDelete(team);
          }
        });
      }

      _onDelete(resourceGroup) {
        const adminResourceGroupId =
          Session.current().getClubAdminResourceGroupId();

        return AuthAwareAdapterProxy.withUseResourceGroup(
          adminResourceGroupId,
          () => resourceGroup.destroy()
        );
      }

      onJoin(resourceGroup) {
        const currentSession = Session.current();
        const adminResourceGroupId =
          currentSession.getClubAdminResourceGroupId();

        const joinResourcegroup = async () =>
          await AuthAwareAdapterProxy.withUseResourceGroup(
            adminResourceGroupId,
            () => resourceGroup.join()
          );

        if (!currentSession.isMemberOf(resourceGroup.id)) {
          sweetAlert({
            title: this.props.t('joinTeamTitle'),
            text: this.props.t('joinTeamBody', {
              teamName: resourceGroup.label,
            }),
            dangerMode: false,
            buttons: [this.props.t('common:cancel'), this.props.t('join')],
          }).then((confirmed) => {
            if (confirmed) {
              joinResourcegroup().then(() => {
                currentSession.setResourceGroupId(
                  resourceGroup.id,
                  getRoutePath('user-management.team', { roleFilter: 'all' })
                );
              });
            }
          });

          // not needed because setResourceGroupId will refresh everything
          //await currentSession.reloadSettings();
        } else {
          currentSession.setResourceGroupId(
            resourceGroup.id,
            getRoutePath('user-management.team', { roleFilter: 'all' })
          );
        }
      }

      // _deleteCustomTag(customTag) {
      //   customTag.destroy();
      // }

      // deleteCustomTag(customTag) {
      //   confirmModal({
      //     title: 'Teamtag verwijderen',
      //     body: `Weet je zeker dat je ${customTag.description} wilt verwijderen?`,
      //     actionLabel: 'Teamtag verwijderen',
      //     actionHandler: () => this._deleteCustomTag(customTag),
      //   });
      // }

      render() {
        if (this.dataState.get() === 'loading') {
          return <Loading type={'fullscreen'} />;
        } else if (this.dataState.get() === 'error') {
          return <Error />;
        }

        window.addMemberToCurrentRG = (email) =>
          this.addMemberToCurrentRG(email);
        window.listMembersOfCurrentRG = () => this.listMembersOfCurrentRG();

        window.addAdmin = (email) => this.addAdmin(email);
        window.listAdmins = () => this.listAdmins();
        window.removeAdmin = (email) => this.removeAdmin(email);

        const currentSession = Session.current();

        const resourceGroups = ResourceGroupCollection.sorted();

        const pageProps = {
          title: this.props.t('title'),
          breadcrumbs: [
            { path: '/', title: 'Home' },
            { title: this.props.t('title') },
          ],
          primaryAction: {
            title: this.props.t('addTeam'),
            onClick: () => this.openCreateTeamDialog(),
          },
        };

        return (
          <Page {...pageProps}>
            {resourceGroups.map((resourceGroup) => (
              <ResourceGroupOverviewItem
                key={resourceGroup.id}
                resourceGroup={resourceGroup}
                onDelete={() => this.onDelete(resourceGroup)}
                onJoin={() => this.onJoin(resourceGroup)}
              />
            ))}

            {this.createTeamDialogIsOpen.get() && (
              <Modal onCloseClick={() => this.closeCreateTeamDialog()}>
                {!currentSession.isFeatureAvailable('addTrainee') && (
                  <TeamManagementNew
                    clubName={currentSession.getClubAdminResourceGroupName()}
                    onCancel={() => this.closeCreateTeamDialog()}
                    onAdd={(attributes) => {
                      this.addResourceGroup(attributes);
                      this.closeCreateTeamDialog();
                    }}
                  />
                )}
                {currentSession.isFeatureAvailable('addTrainee') && (
                  <PersonManagementNew
                    clubName={currentSession.getClubAdminResourceGroupName()}
                    onCancel={() => this.closeCreateTeamDialog()}
                    personType="trainee"
                    onAdd={(attributes) => {
                      this.addResourceGroup(attributes);
                      this.closeCreateTeamDialog();
                    }}
                  />
                )}
              </Modal>
            )}
          </Page>
        );
      }
    }
  )
);

export const ResourceGroupOverviewItem = withTranslation(
  'module.settings.club'
)((props) => {
  const { resourceGroup, t } = props;
  //
  // let daysPending = momentjs().diff(momentjs(team.updated), 'days');
  // if (daysPending === 0) {
  //   daysPending = 'vandaag';
  // } else if (daysPending === 1) {
  //   daysPending = 'gisteren';
  // } else {
  //   daysPending += ' dagen geleden';
  // }

  const details = () => (
    <>
      <div className="teams-overview-item-members-total">
        {t('member', { count: resourceGroup.memberCount })}
      </div>
      {/*<div className="overview-item-meta teams-overview-item-updated-at">*/}
      {/*Laatste wijziging {daysPending}.*/}
      {/*</div>*/}
    </>
  );

  const secondaryActions = [
    // {
    //   label: 'Trainers beheren',
    //   onClick: console.debug,
    // },
    // {
    //   label: 'Team bewerken',
    //   onClick: console.debug,
    // },
    {
      label: t('deleteTeam'),
      onClick: props.onDelete,
    },
  ];

  const overviewItemProps = {
    ...props,
    details,
    title: resourceGroup.label,
    icon: clubPlaceholderIcon,
    secondaryActions,
    onClick: props.onJoin,
  };

  return (
    <div className="teams-overview-item">
      <OverviewItem {...overviewItemProps} />
    </div>
  );
});

export { ClubTeamManagementOverview };
