import React, { Component, Fragment, useState, useEffect } from 'react';

import { observer } from 'mobx-react';
import { observable, runInAction, action, autorun } from 'mobx';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { Modal } from 'lib/Modal';
import { Page } from 'lib/Page';

import './lineup.scss';

import { withTranslation } from 'react-i18next';
import sweetAlert from 'lib/sweetAlert';
import { ButtonGroupSelect } from '../../../lib/ButtonGroupSelect';
import Loading from 'lib/Loading';
import { InputCheckboxRadio } from '../../../lib/InputCheckBoxRadio';
import { SubmittingButton } from '../../../lib/SubmittingButton';

export const NewPlayerInput = withTranslation('module.observe.live')(
  observer(
    class NewPlayer extends Component {
      constructor() {
        super();

        this.values = observable({
          gender: 'male',
          firstName: '',
          lastName: '',
          number: '',
          email: '',
        });
        this.hasError = observable(false);
        this.createAccount = observable(false);
      }

      onClose(e) {
        e.preventDefault();
        this.reset();
        this.props.onClose();
      }

      reset = action(() => {
        this.hasError.set(false);
        this.values['firstName'] = '';
        this.values['lastName'] = '';
        this.values['gender'] = 'male';
        this.values['number'] = '';
        this.values['email'] = '';
      });

      handleSubmit = action(async () => {
        // if (!this.values.gender || (!this.values.number && !this.values.name)) {
        //     this.hasError.set(true);
        //     return;
        // }
        await this.props.onAdd(
          this.values.firstName,
          this.values.lastName,
          this.values.gender,
          this.values.number,
          this.createAccount.get() ? this.values.email : null
        );
        this.reset();
      });

      handleChange = action((name, value) => {
        this.hasError.set(false);
        this.values[name] = value;
      });

      render() {
        return (
          <Fragment>
            <div className="row">
              <div className="col-12">
                <b>{`${this.props.t('addPlayerTitle')}`}</b>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <div className="modal-input">
                  <div className="icon user-icon" />
                  <input
                    type="text"
                    placeholder={`${this.props.t('playerFirstname')}`}
                    className="with-icon"
                    value={this.values['firstName']}
                    name="firstName"
                    onChange={(e) =>
                      this.handleChange('firstName', e.target.value)
                    }
                  />
                </div>
                <div className="modal-input">
                  <input
                    type="text"
                    placeholder={`${this.props.t('playerLastname')}`}
                    className="with-icon-offset"
                    value={this.values['lastName']}
                    name="lastName"
                    onChange={(e) =>
                      this.handleChange('lastName', e.target.value)
                    }
                  />
                </div>

                <div className="modal-input">
                  <div className="icon back-icon" />
                  <input
                    type="text"
                    placeholder={`${this.props.t('playerNumber')}`}
                    value={this.values['number']}
                    name="number"
                    onChange={(e) =>
                      this.handleChange('number', e.target.value)
                    }
                  />
                </div>
                <div className="modal-input invoer-margin-wrapper">
                  <span
                    className={`clickable gender-select male relative ${
                      this.values.gender === 'male' ? 'font-heavy' : ''
                    }`}
                    onClick={() => this.handleChange('gender', 'male')}
                  >
                    {`${this.props.t('male')}`}
                  </span>
                  <span
                    className={`clickable gender-select female relative ${
                      this.values.gender === 'female' ? 'font-heavy' : ''
                    }`}
                    onClick={() => this.handleChange('gender', 'female')}
                  >
                    {`${this.props.t('female')}`}
                  </span>
                </div>
                {this.props?.createAccount && (
                  <div className="modal-input">
                    <div className={'with-icon-offset'}>
                      <InputCheckboxRadio
                        onChange={action(() => {
                          this.createAccount.set(!this.createAccount.get());
                        })}
                        label={'Create account for player'}
                        checked={this.createAccount.get()}
                      />
                    </div>
                  </div>
                )}
                {this.createAccount.get() && (
                  <div className="modal-input">
                    <div className="icon email-icon" />
                    <input
                      type="text"
                      placeholder={`Email`}
                      className="with-icon"
                      value={this.values['email']}
                      name="email"
                      onChange={(e) =>
                        this.handleChange('email', e.target.value)
                      }
                    />
                  </div>
                )}
                <div className="modal-input invoer-margin-wrapper">
                  <SubmittingButton
                    className="btn btn-primary"
                    onClick={async () => await this.handleSubmit()}
                  >
                    {`${this.props.t('addPlayer')}`}
                  </SubmittingButton>
                </div>
              </div>
              {/*<div className="col-4">*/}
              {/*<div className="modal-input">*/}
              {/*Upload avatar*/}
              {/*</div>*/}
              {/*<div className="avatar-upload width-100 relative">*/}
              {/*<div className="avatar-wrapper relative">*/}
              {/*<div className="avatar-inner">*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*</div>*/}
              {/*</div>*/}
            </div>
          </Fragment>
        );
      }
    }
  )
);

const LineUpInput = withTranslation('module.observe.live')(
  observer(
    class LineUpInput extends Component {
      constructor() {
        super();
        this.activeTeamName = observable('home');
        this.addPlayerDialogIsOpen = observable(false);
        this.droppableZoneRef = React.createRef();
      }

      componentWillMount() {
        this.props.sportingEvent.homeTeam.players.fetchIfEmpty();
        this.props.sportingEvent.awayTeam.players.fetchIfEmpty();
      }
      get activeTeam() {
        return this.activeTeamName.get() === 'home'
          ? this.props.sportingEvent.homeTeam
          : this.props.sportingEvent.awayTeam;
      }

      setActiveTeamName = action((name) => {
        this.activeTeamName.set(name);
      });

      callEventHandler(eventName, args) {
        const eventHandler = this.props['on' + eventName];
        if (eventHandler) {
          eventHandler(args);
        }
      }

      handleDragEnd(source, destination, teamId, personId, roles) {
        let command;

        const sourcePosition = source.split('-')[0];
        if (!destination) {
          // remove player from position
          if (sourcePosition === 'UNASSIGNED') {
            // source is entire team: ignore
          } else {
            if (sourcePosition === 'substitute') {
              this.callEventHandler('RemoveSubstitute', { teamId, personId });
            } else {
              this.callEventHandler('RemovePlayer', { teamId, personId });
            }
          }
        } else {
          const targetPosition = destination.split('-')[0];
          if (targetPosition === sourcePosition) {
            return;
          }

          // 1. Check if we are replacing someone
          switch (targetPosition) {
            case 'substitute': {
              // prevent duplicate const
              const matchingRoles = roles.filter(
                (role) => role.roleName === 'substitute'
              );
              const targetIndex = parseInt(destination.split('-')[1]);
              const matchingRole = matchingRoles[targetIndex - 1];
              if (typeof matchingRole === 'undefined') {
                if (sourcePosition === 'UNASSIGNED') {
                  this.callEventHandler('AddSubstitute', { teamId, personId });
                } else {
                  this.callEventHandler('MovePlayerToSubstitute', {
                    teamId,
                    personId,
                  });
                }
              } else {
                if (sourcePosition === 'UNASSIGNED') {
                  // from UNASSIGNED to Substitute
                  this.callEventHandler('SwapUnAssignedWithSubstitute', {
                    teamId,
                    inPersonId: personId,
                    outPersonId: matchingRole.person.id,
                  });
                } else {
                  this.callEventHandler('SwapSubstituteWithPlayer', {
                    teamId,
                    inPersonId: matchingRole.person.id,
                    outPersonId: personId,
                    position: sourcePosition,
                  });
                }
              }

              break;
            }
            case 'UNASSIGNED':
              // UNASSIGNED is not a droparea
              throw 'Unassigned is not a droparea';
              break;
            default: {
              const matchingRoles = roles.filter(
                (role) =>
                  role.roleName === 'player' && role.position === targetPosition
              );
              const matchingRole = matchingRoles[0];
              if (typeof matchingRole === 'undefined') {
                if (sourcePosition === 'UNASSIGNED') {
                  this.callEventHandler('AddPlayer', {
                    teamId,
                    personId,
                    position: targetPosition,
                  });
                } else if (sourcePosition === 'substitute') {
                  this.callEventHandler('MoveSubstituteToPlayer', {
                    teamId,
                    personId,
                    position: targetPosition,
                  });
                } else {
                  this.callEventHandler('ChangePlayerPosition', {
                    teamId,
                    personId,
                    position: targetPosition,
                  });
                }
              } else {
                if (sourcePosition === 'UNASSIGNED') {
                  this.callEventHandler('SwapUnAssignedWithPlayer', {
                    teamId,
                    inPersonId: personId,
                    outPersonId: matchingRole.person.id,
                    position: targetPosition,
                  });
                } else if (sourcePosition === 'substitute') {
                  this.callEventHandler('SwapSubstituteWithPlayer', {
                    teamId,
                    inPersonId: matchingRole.person.id,
                    outPersonId: personId,
                    position: targetPosition,
                  });
                } else {
                  this.callEventHandler('SwapPlayerWithPlayer', {
                    teamId,
                    inPersonId: personId,
                    inPosition: targetPosition,
                    outPersonId: matchingRole.person.id,
                    outPosition: sourcePosition,
                  });
                }
              }
            }
          }
        }
        return command;
      }

      noScroll() {
        if (this.droppableZoneRef?.current?.offsetTop) {
          // Move to drop area
          window.scrollTo(0, this.droppableZoneRef.current.offsetTop - 75);
        }
      }

      onDragStart(draggable) {
        if (
          !this.props.inModal &&
          draggable?.source?.droppableId.indexOf('UNASSIGNED') !== -1 // Only scroll when selecting unassigned.
        ) {
          this.noScroll();
          // add listener to disable scroll
          window.addEventListener('scroll', this.noScroll);
        }
      }

      onDragEnd(result) {
        if (!this.props.inModal) {
          window.removeEventListener('scroll', this.noScroll);
        }

        // console.log('dragEnd', result);
        const team = this.activeTeam;

        const roles = this.props.teamRoles[team.id];
        const { source, destination, draggableId: personId } = result;

        this.handleDragEnd(
          source.droppableId,
          destination && destination.droppableId,
          team.id,
          personId,
          roles
        );
      }

      addPlayer = action((firstName, lastName, gender, number) => {
        this.closeAddPlayerDialog();

        this.callEventHandler('CreateAndAddToTeam', {
          firstName,
          lastName,
          gender,
          teamId: this.activeTeam.id,
          number,
        });
      });

      swapAttackDefence = action(() => {
        this.callEventHandler('SwapAttackDefence', {
          teamId: this.activeTeam.id,
        });
      });

      openAddPlayerDialog = action(() => {
        this.addPlayerDialogIsOpen.set(true);
      });

      closeAddPlayerDialog = action(() => {
        this.addPlayerDialogIsOpen.set(false);
      });

      getPlayerDropArea(team, roles) {
        return ({ position, style }) => {
          let matchingRoles;
          matchingRoles = roles.filter(
            (role) => role.roleName === 'player' && role.position === position
          );
          const matchingRole = matchingRoles[0];

          const positionFulfilled = typeof matchingRole !== 'undefined';

          return (
            <Droppable
              ignoreContainerClipping
              droppableId={`${position}-${team.id}`}
              direction="horizontal"
            >
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  className={`player-square ${
                    !positionFulfilled ? 'empty' : ''
                  }`}
                  style={getListStyle(
                    snapshot.isDraggingOver,
                    positionFulfilled,
                    style
                  )}
                >
                  {positionFulfilled && (
                    <Draggable
                      key={matchingRole.person.id}
                      draggableId={matchingRole.person.id}
                      index={0}
                    >
                      {(provided, snapshot) => (
                        <PlayerCard
                          firstName={matchingRole.person.firstName}
                          lastName={matchingRole.person.lastName}
                          number={matchingRole.player.number}
                          profilePictureUrl={
                            matchingRole.person.profilePictureUrl
                          }
                          ref_={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        />
                      )}
                    </Draggable>
                  )}
                  {!positionFulfilled && <PlayerCard empty={true} />}
                </div>
              )}
            </Droppable>
          );
        };
      }

      getSubstituteDropArea(teamId, roles) {
        return ({ index }) => {
          let matchingRoles;
          matchingRoles = roles.filter(
            (role) => role.roleName === 'substitute'
          );

          const matchingRole = matchingRoles[index - 1];

          const positionFulfilled = typeof matchingRole !== 'undefined';

          return (
            <Droppable droppableId={`substitute-${index}-${teamId}`}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  style={getListStyle(
                    snapshot.isDraggingOver,
                    positionFulfilled
                  )}
                >
                  {positionFulfilled && (
                    <Draggable
                      key={matchingRole.person.id}
                      draggableId={matchingRole.person.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                        >
                          <PlayerCard
                            firstName={matchingRole.person.firstName}
                            lastName={matchingRole.person.lastName}
                            profilePictureUrl={
                              matchingRole.person.profilePictureUrl
                            }
                            number={matchingRole.player.number}
                          />
                        </div>
                      )}
                    </Draggable>
                  )}
                  {!positionFulfilled && <PlayerCard empty={true} />}
                </div>
              )}
            </Droppable>
          );
        };
      }

      getUnAssignedPersonsArea(team, unAssignedPlayers) {
        if (unAssignedPlayers.length > 0) {
          const handleDelete = (person) => {
            sweetAlert({
              title: this.props.t('deletePlayer'),
              text: this.props.t('sureToDelete'),
              dangerMode: true,
              buttons: [
                this.props.t('common:cancel'),
                this.props.t('common:delete'),
              ],
            }).then((confirm) => {
              return confirm && team.players.get(person.id).destroy();
            });
          };

          return (
            <Droppable
              direction="horizontal"
              droppableId={`UNASSIGNED-${team.id}`}
              isDropDisabled={true}
            >
              {(provided, snapshot) => (
                <div
                  className="player-carousel relative"
                  ref={provided.innerRef}
                  style={getUnAssignedListStyle(snapshot.isDraggingOver)}
                >
                  {unAssignedPlayers.map((player, index) => (
                    <Draggable
                      key={player.id}
                      draggableId={player.person.id}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getUnAssignedItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}
                          className="player-square"
                        >
                          <PlayerCard
                            firstName={player.person.firstName}
                            lastName={player.person.lastName}
                            onRemovePlayer={() => handleDelete(player.person)}
                            profilePictureUrl={player.person.profilePictureUrl}
                            number={player.get('number')}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          );
        } else {
          return (
            <div className="player-carousel relative">
              <div className="flex-content">
                <div className="text-center">
                  <span
                    className="font-heavy"
                    onClick={() => this.openAddPlayerDialog()}
                  >
                    {this.props.t('addPlayerTextPartA')}
                  </span>{' '}
                  {this.props.t('addPlayerTextPartB')}
                </div>
              </div>
            </div>
          );
        }
      }

      render() {
        const homeTeam = this.props.sportingEvent.homeTeam;
        const awayTeam = this.props.sportingEvent.awayTeam;
        const LayoutComponent = this.props.layout;

        const activeTeam =
          this.activeTeamName.get() === 'home' ? homeTeam : awayTeam;
        const allPlayers = activeTeam.players.toArray();

        const roles = this.props.teamRoles[activeTeam.id];

        const teamPersonIds = roles.map((role) => {
          return role.person.id;
        });

        const unAssignedPlayers = allPlayers.filter((player) => {
          return (
            player.person && teamPersonIds.indexOf(player.person.id) === -1
          );
        });

        const activeUnAssigned = this.getUnAssignedPersonsArea(
          activeTeam,
          unAssignedPlayers
        );

        const pageProps = {
          primaryAction: {
            title: this.props.t('addPlayerButton'),
            onClick: () => this.openAddPlayerDialog(),
          },
          filters: [
            {
              title: `${this.props.t('lineUp')} ${homeTeam.label}`,
              isActive: this.activeTeamName.get() === 'home',
              onClick: () => this.setActiveTeamName('home'),
            },
            {
              title: `${this.props.t('lineUp')} ${awayTeam.label}`,
              isActive: this.activeTeamName.get() === 'away',
              onClick: () => this.setActiveTeamName('away'),
            },
          ],
          hideFooter: true,
          hideInset: !!this.props.inModal,
          ...(this.props.pageProps || {}),
        };
        const ButtonGroup = () => {
          const values = [
            {
              label: `${this.props.t('lineUp')} ${homeTeam.label}`,
              value: 'home',
            },
            {
              label: `${this.props.t('lineUp')} ${awayTeam.label}`,
              value: 'away',
            },
          ];
          return (
            <ButtonGroupSelect
              options={values}
              onChange={(v) => this.setActiveTeamName(v)}
              value={this.activeTeamName.get()}
              radio
            />
          );
        };

        return (
          <Page {...pageProps}>
            <div style={{ maxHeight: 'calc(100vh - 40px)' }}>
              {/*<AspectRatio aspectRatio={16 / 11}>*/}
              <DragDropContext
                onDragStart={this.onDragStart.bind(this)}
                onDragEnd={this.onDragEnd.bind(this)}
              >
                <div className="relative full-lg">{activeUnAssigned}</div>
                <div className="formation-select">
                  <ButtonGroup />
                </div>
                <div
                  ref={this.droppableZoneRef}
                  className="formation-field relative"
                >
                  <LayoutComponent
                    sportingEvent={this.props.sportingEvent}
                    roles={roles}
                    team={activeTeam}
                    PlayerDropArea={this.getPlayerDropArea(activeTeam, roles)}
                    SubstituteDropArea={this.getSubstituteDropArea(
                      activeTeam.id,
                      roles
                    )}
                    onSwapAttackDefence={
                      !this.props.onSwapAttackDefence
                        ? null
                        : () => {
                            this.swapAttackDefence();
                          }
                    }
                    doneButton={this.props.doneButton}
                  />
                </div>
              </DragDropContext>
              {/*</AspectRatio>*/}
            </div>
            {this.addPlayerDialogIsOpen.get() && (
              <Modal onCloseClick={() => this.closeAddPlayerDialog()}>
                <NewPlayerInput onAdd={this.addPlayer.bind(this)} />
              </Modal>
            )}
          </Page>
        );
      }
    }
  )
);

//
// const KorfbalLineUpInput2 = ({team, PlayerDropArea, SubstituteDropArea}) => {
//     return <div>
//         <div style={{textAlign: "center", color: "#fff"}}><h3>Aanval {team.get('name')}</h3></div>
//         <PlayerDropArea position="ATTACK:1" />
//         <PlayerDropArea position="ATTACK:2" />
//         <PlayerDropArea position="ATTACK:3" />
//         <PlayerDropArea position="ATTACK:4" />
//         <div style={{clear: "both"}} />
//         <div style={{textAlign: "center", color: "#fff"}}><h3>Verdediging {team.get('name')}</h3></div>
//         <div style={{clear: "both"}} />
//         <PlayerDropArea position="DEFENCE:1" />
//         <PlayerDropArea position="DEFENCE:2" />
//         <PlayerDropArea position="DEFENCE:3" />
//         <PlayerDropArea position="DEFENCE:4" />
//         <div style={{clear: "both"}} />
//         <div style={{textAlign: "center", color: "#fff"}}><h3>Wissels {team.get('name')}</h3></div>
//         <SubstituteDropArea index={1} />
//         <SubstituteDropArea index={2} />
//         <SubstituteDropArea index={3} />
//     </div>;
// };

const KorfballLineUpLayout = withTranslation('module.observe.live')(
  ({
    sportingEvent,
    roles,
    team,
    PlayerDropArea,
    SubstituteDropArea,
    doneButton,
    t,
    onSwapAttackDefence,
  }) => {
    const [loading, setLoading] = useState(false);
    const attackFirstRole = roles.filter((role) => {
      return role.position === 'ATTACK:1' && role.roleName === 'player';
    })[0];
    const defenceFirstRole = roles.filter((role) => {
      return role.position === 'DEFENCE:1' && role.roleName === 'player';
    })[0];

    useEffect(() => {
      setLoading(false);
    }, [roles]);
    return (
      <div>
        {onSwapAttackDefence && (
          <div className={'formation-swap'}>
            <button
              onClick={() => {
                if (!loading) {
                  onSwapAttackDefence();
                  setLoading(true);
                }
              }}
              className={'btn btn-primary'}
              disabled={loading}
            >
              {loading && (
                <Loading type={'spinner'} color={'white'} size={11} />
              )}
              <i className={'i-flip i-light'} />
              <div>{t('swapPlayers')}</div>
            </button>
          </div>
        )}
        <div key="attack" className="formation-field-half field--attack">
          <div className="flex-content">
            <div className="player-squares">
              <div className="player-squares-text relative break-after">
                <div className="field-leader">
                  <div className="player-thumb">
                    {attackFirstRole && attackFirstRole.person.code}
                  </div>
                  <div className="player-name">
                    {t('section')}{' '}
                    <b>{attackFirstRole && attackFirstRole.person.label}</b>
                  </div>
                </div>
                <div className="relative float-right">{t('attack')}</div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <PlayerDropArea position="ATTACK:1" />
                </div>
                <div className="col-md-6">
                  <PlayerDropArea position="ATTACK:2" />
                </div>
                <div className="col-md-6">
                  <PlayerDropArea position="ATTACK:3" />
                </div>
                <div className="col-md-6">
                  <PlayerDropArea position="ATTACK:4" />
                </div>
              </div>
              <div className="break-after" style={{ marginBottom: '75px' }} />
            </div>
          </div>
        </div>

        <div key="defence" className="formation-field-half field--defence">
          <div className="flex-content">
            <div className="player-squares">
              <div className="player-squares-text relative break-after">
                <div className="field-leader">
                  <div className="player-thumb">
                    {defenceFirstRole && defenceFirstRole.person.code}
                  </div>
                  <div className="player-name">
                    {t('section')}{' '}
                    <b>{defenceFirstRole && defenceFirstRole.person.label}</b>
                  </div>
                </div>
                <div className="relative float-right">{t('defence')}</div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <PlayerDropArea position="DEFENCE:1" />
                </div>
                <div className="col-md-6">
                  <PlayerDropArea position="DEFENCE:2" />
                </div>
                <div className="col-md-6">
                  <PlayerDropArea position="DEFENCE:3" />
                </div>
                <div className="col-md-6">
                  <PlayerDropArea position="DEFENCE:4" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ clear: 'both' }} />
        <div className={'formation-swap'}>{doneButton}</div>
      </div>
    );
  }
);

const getItemStyle = (isDragging, draggableStyle) => {
  const style = {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    WebkitUserSelect: 'none',

    zIndex: 10,
    // padding: grid * 2,
    // margin: `0 0 ${grid}px 0`,
    // styles we need to apply on draggables
    ...draggableStyle,
  };
  if (!isDragging) delete style['transform'];
  else style['background'] = '#dcdbdb';
  return style;
};

const getListStyle = (isDraggingOver, positionFulfilled, style) => ({
  background: isDraggingOver ? 'rgba(40,232,158,0.29)' : '',
  cursor: positionFulfilled ? 'pointer' : '',
  ...(style || {}),
});

const getUnAssignedItemStyle = (isDragging, draggableStyle) => {
  const style = {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    WebkitUserSelect: 'none',
    zIndex: 10,
    // styles we need to apply on draggables
    ...draggableStyle,
  };
  if (isDragging) style['background'] = '#dcdbdb';
  return style;
};

const getUnAssignedListStyle = (isDraggingOver, style) => ({
  ...(style || {}),
});

export const PlayerCard = ({
  number = 'X',
  firstName,
  lastName,
  profilePictureUrl,
  empty,
  onRemovePlayer,
  ref_,
  t,
  ...props
}) => {
  if (empty) {
    return (
      <div className="player-square-inner">
        <div className="player-thumb" />
        <div className="player-name text-center">Drag a player here</div>
      </div>
    );
  } else {
    return (
      <div className="player-square-inner" ref={ref_} {...props}>
        {!!onRemovePlayer && (
          <div className="delete-button" onClick={onRemovePlayer}>
            <i className="i-delete" />
          </div>
        )}
        <div
          className="number-icon"
          style={!onRemovePlayer ? { top: '8px' } : {}}
        >
          {number}
        </div>
        <div className="drag-icon" />
        <div
          className="player-thumb"
          style={{ backgroundImage: `url(${profilePictureUrl})` }}
        />
        <div className="player-name text-center">
          {firstName}
          <br />
          <b>{lastName}</b>
        </div>
      </div>
    );
  }
};

export { LineUpInput, KorfballLineUpLayout };
