import { Model, Collection } from 'mobx-rest';

import { PersistentCollection } from './PersistentCollection';

import SportingEventCollection from 'domain/SportingEvent';
import api from '../utils/ApiClient';
import logger from '../utils/Logger';
import { Session } from './Session';

const PLACEHOLDER_URL =
  'https://web-assets.teamtv.nl/global/video/no-video-final.mp4';

class Video extends Model {
  get primaryKey() {
    return 'videoId';
  }

  getFragmentUrl(startTime, endTime) {
    return this.rpc('getFragmentUrl', { startTime, endTime }).then(
      (mediaUrl) => {
        return mediaUrl;
      }
    );
  }

  async getPreviewUrl() {
    if (!this.previewUrl) {
      if (!this.has('hasHLs') || !this.get('hasHls')) {
        this.previewUrl = PLACEHOLDER_URL;
      } else {
        this.previewUrl = await this.getFragmentUrl(
          0,
          Math.min(5, this.duration)
        );
      }
    }
    return this.previewUrl;
  }

  getVideoFragment(startTime, endTime, description = null, labels = null) {
    if (startTime > this.duration) {
      console.warn(
        `Could not get video fragment: ${startTime} of ${this.duration}`
      );
    }
    return {
      startTime: parseInt(Math.max(0, startTime)),
      endTime: parseInt(Math.min(this.duration, endTime)),
      videoId: this.id,
      description,
      labels,
      livestream: this.has('livestream'),
    };
  }
  getVideoFragmentUrlByVideoFagment(videoFragment) {
    if (this?.mediaUrl) {
      if (this.mediaUrl.indexOf('blob') !== -1) {
        return `${this.mediaUrl}#t=${videoFragment.startTime},${videoFragment.endTime}`;
      }
      return `${this.mediaUrl}&start=${videoFragment.startTime}&end=${videoFragment.endTime}`;
    } else {
      logger.warning(`video does not have a media url`, {
        videoId: `${this.videoId}`,
      });
      return PLACEHOLDER_URL;
    }
  }

  getVideoFragmentUrl(startTime, endTime) {
    const start = parseInt(Math.max(0, startTime));
    const end = parseInt(endTime);
    return `${this.mediaUrl}&start=${start}&end=${end}`;
  }

  get mediaUrl() {
    const currentSession = Session.current();

    if (this.has('livestream')) {
      if (!currentSession.cameraAtLocalClubToken()) {
        logger.error('No token supplied for livestream');
        console.warn('No token supplied for livestream');
        return `${this.get('mediaUrl')}&token=null`;
      }
      return `${this.get(
        'mediaUrl'
      )}&token=${currentSession.cameraAtLocalClubToken()}`;
    }
    if (this.has('mediaUrl')) {
      return this.get('mediaUrl');
    }
  }

  get thumbnailUrl() {
    if (this.has('livestream') && this.get('livestream')) {
      // todo: create image from eyedle backend.
      // Generate the SVG data URL
      const svgDataUrl = (text, width = 320, height = 180) => {
        const svg = `<svg width="640" height="354" viewBox="0 0 320 177" fill="none" xmlns="http://www.w3.org/2000/svg">
<mask id="mask0_1275_209" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="320" height="177">
<rect width="320" height="177" fill="#5650A3"/>
</mask>
<g mask="url(#mask0_1275_209)">
<rect x="-5" y="-160.283" width="343" height="337.283" fill="#5650A3"/>
<text transform="matrix(0.869626 -0.493711 0.506316 0.862348 57.8086 249.778)" fill="white" fill-opacity="0.1" xml:space="preserve" style="white-space: pre" font-family="LAto, sans-serif" font-size="58" font-weight="bold" letter-spacing="0em"><tspan x="0.230469" y="57.446">${text.toUpperCase()}${text.toUpperCase()}${text.toUpperCase()}</tspan></text>
<text transform="matrix(0.869626 -0.493711 0.506316 0.862348 -327.441 150.281)" fill="white" fill-opacity="0.1" xml:space="preserve" style="white-space: pre" font-family="LAto, sans-serif" font-size="58" font-weight="bold" letter-spacing="0em"><tspan x="0.230469" y="57.446">${text.toUpperCase()}${text.toUpperCase()}${text.toUpperCase()}</tspan></text>
<text transform="matrix(0.869626 -0.493711 0.506316 0.862348 -173.191 168.161)" fill="white" fill-opacity="0.1" xml:space="preserve" style="white-space: pre" font-family="LAto, sans-serif" font-size="58" font-weight="bold" letter-spacing="0em"><tspan x="0.230469" y="57.446">${text.toUpperCase()}${text.toUpperCase()}${text.toUpperCase()}</tspan></text>
<text transform="matrix(0.869626 -0.493711 0.506316 0.862348 -75.1914 217.328)" fill="white" fill-opacity="0.1" xml:space="preserve" style="white-space: pre" font-family="LAto, sans-serif" font-size="58" font-weight="bold" letter-spacing="0em"><tspan x="0.230469" y="57.446">${text.toUpperCase()}${text.toUpperCase()}${text.toUpperCase()}</tspan></text>
<text transform="translate(45 71.7834)" fill="white" xml:space="preserve" style="white-space: pre" font-family="LAto, sans-serif" font-size="28" font-weight="bold" letter-spacing="0em"><tspan x="0.257812" y="27.836">${text.toUpperCase()}</tspan></text>
</g>
</svg>


`;
        return `data:image/svg+xml;base64,${btoa(svg)}`;
      };

      return svgDataUrl(
        this.has('name') ? this.get('name') : this.get('tags')?.output_key
      );
    }
    return this.has('thumbnailUrl') && this.get('thumbnailUrl');
  }

  async clipThumbnailUrl(startTime, size) {
    const { data: clipThumbnailUrl } = await api.get(
      `videos/${this.videoId}/getClipThumbnail`,
      {
        params: {
          time: startTime,
          size: size,
        },
      }
    );
    return clipThumbnailUrl || this.thumbnailUrl;
  }

  get state() {
    if (
      !this.has('mediaUrl') &&
      this.has('state') &&
      this.get('state') === 'ready'
    ) {
      // console.log(this);
      return 'expired';
    }
    return this.has('state') ? this.get('state') : 'no-state';
  }

  getObservationsDownloadUrl(description, observations) {
    const clips = observations.map((observation) => {
      let startTime = observation.startTime;
      let endTime = observation.endTime;
      startTime = parseInt(Math.max(0, startTime));
      endTime = parseInt(Math.min(this.get('content').duration, endTime));
      return {
        startTime,
        endTime,
      };
    });
    return this.rpc('getObservationsDownloadUrl', { description, clips });
  }

  getDownloadUrl() {
    return this.rpc('getDownloadUrl', {});
  }

  get parts() {
    return this.get('parts').toJS();
  }

  get tags() {
    return this.has('tags') ? this.get('tags') : {};
  }

  get duration() {
    let duration = this.get('content').duration;
    if (!duration) {
      // TODO: Calculate based on something.
      duration = 3600 * 24;
    }
    return duration;
  }

  get tenantId() {
    return this.get('tenantId');
  }

  get videoId() {
    return this.get('videoId');
  }

  get videoUri() {
    return this.get('videoUri');
  }

  get description() {
    return this.has('description') && this.get('description');
  }

  get name() {
    if (this.description.indexOf('sporting-event') !== -1) {
      const [se, ...name_] = this.description.split(' ');
      return name_.join(' ');
    } else {
      return this.description;
    }
  }

  getOtherAngles() {
    if (this.tags && this.tags?.sportingEventId) {
      const sportingEvent = SportingEventCollection.get(
        this?.tags?.sportingEventId
      );
      if (sportingEvent?.videoIds && sportingEvent?.videoIds?.length > 1) {
        this.angles = sportingEvent.videoIds
          .toJSON()
          .filter((v) => v != 'filtered');
        if (this.angles.length > 0) {
          return this.angles;
        } else {
          return false;
        }
      }
    }
    return false;
  }

  isVideoFragmentFullVideo(videoFragment) {
    return (
      videoFragment.isFullMatch ||
      (videoFragment.startTime === 0 && videoFragment.endTime === this.duration)
    );
  }

  privilegedTo(action) {
    if (!this.has('_metadata')) {
      return false;
    }

    const privileges = this.get('_metadata').privileges;
    return privileges.indexOf(`video:${action}`) !== -1;
  }

  get isReady() {
    return (
      this.state === 'ready' ||
      (this.has('livestream') && this.get('livestream').url)
    );
  }
}

class Placeholder {
  getVideoFragmentUrlByVideoFagment() {
    return PLACEHOLDER_URL;
  }

  getOtherAngles() {
    return false;
  }
}

class VideoCollection extends PersistentCollection {
  url() {
    return '/videos';
  }

  model() {
    return Video;
  }

  getOrPlaceholder(videoId) {
    let video = this.get(videoId);
    if (!video || !video.mediaUrl) {
      video = new Placeholder();
    }
    return video;
  }
}

const videoCollection = new VideoCollection();
window.videoCollection = videoCollection;

export default videoCollection;
