import React, { useMemo } from 'react';
import { Session } from '../../../domain/Session';
import { useTranslation } from 'react-i18next';
import { gotoRoute } from '../../../modules/route';
import { withRouter } from 'react-router-dom';
import { ObservationInputNav } from '../../../modules/observe/components/InputNav';

const ScoreBoardNav = React.memo(({ sportingEvent, match }) => {
  const { t } = useTranslation();
  const currentSession = Session.current();

  // Memoize buttons to prevent recalculation unless dependencies change
  const buttons = useMemo(() => {
    const btns = [];

    if (
      sportingEvent.privilegedTo('view') &&
      (sportingEvent.hasVideo() || sportingEvent.isLiveStreamAvailable())
    ) {
      btns.push({
        label: 'View',
        onClick: () =>
          gotoRoute(`play.${sportingEvent.type ?? 'match'}`, {
            sportingEventId: sportingEvent.id,
          }),
        icon: 'play',
        active: match.path.includes('play/match'),
      });
    }

    if (sportingEvent.privilegedTo('observe')) {
      if (match.path.includes('observe')) {
        btns.push({
          label: 'Observe',
          onClick: () => {},
          icon: 'observe',
          active: match.path.includes('observe'),
        });
      } else {
        btns.push({
          label: 'Observe',
          render: (
            <ObservationInputNav
              live={
                !(
                  sportingEvent.hasVideo() ||
                  sportingEvent.isLiveStreamAvailable()
                )
              }
              urlParams={{ sportingEventId: sportingEvent.id }}
              key="observation-input"
            >
              <i className="i-observe i-light i-sm" />
            </ObservationInputNav>
          ),
          icon: 'observe',
          active: match.path.includes('observe'),
        });
      }
    }

    if (
      currentSession.isFeatureAvailable('liveReport') ||
      currentSession.isFeatureAvailable('viewReport')
    ) {
      btns.push({
        label: 'Report',
        onClick: () =>
          gotoRoute('reporting.detail', { sportingEventId: sportingEvent.id }),
        icon: 'stats',
        active: match.path.includes('reporting'),
      });
    }

    return btns;
  }, [sportingEvent, match.path, t, currentSession]);

  return (
    <div className="d-flex position-absolute scoreboard-nav">
      {buttons
        .sort((a) => (a.active ? -1 : 1))
        .map((button) =>
          button?.render ? (
            <div
              className={`scoreboard-nav__button ${
                button.active ? 'is-active' : ''
              }`}
              key={button.label}
            >
              {button.render}
            </div>
          ) : (
            <div
              className={`scoreboard-nav__button ${
                button.active ? 'is-active' : ''
              }`}
              key={button.label}
              onClick={button.onClick}
            >
              <i className={`i-${button.icon} i-light i-sm`} />
            </div>
          )
        )}
    </div>
  );
});

export default withRouter(ScoreBoardNav);
