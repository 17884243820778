import React, { Component, Fragment, useEffect, useRef, useState } from 'react';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';

import {
  EncodeState,
  FileUploader,
  UploadState,
} from '../../../infra/Uploader';
import { Modal } from 'lib/Modal';

import { QuoteCarousel } from 'lib/QuoteCarousel';
import { withTranslation } from 'react-i18next';
import { EventPublisher } from 'utils/EventPublisher';
import { MatchOverviewItem } from 'modules/match/components/MatchOverviewItem';
import sweetAlert from 'lib/sweetAlert';
import { SubmittingButton } from 'lib/SubmittingButton';
import SportingEvent from '../../../domain/SportingEvent';
import SportingEventCollection from '../../../domain/SportingEvent';
import { FileUploadPreview } from './FileUploadPreview';

const FileSelect = withTranslation('module.match.upload')(
  observer(
    ({
      files,
      onAddFile,
      onRemoveFile,
      onReOrder,
      onStart,
      sportingEvent,
      type,
      t,
    }) => {
      return (
        <Fragment>
          <div className="row">
            <div className="col-12">
              <b>{t('title')}</b>
            </div>
          </div>
          {type !== 'assignment' && (
            <>
              <div className="row upload-texts">
                <div className="col-6 col-md-3 pb-4" id="upload-1">
                  <div className="upload-icon text-center">
                    <svg
                      viewBox="0 0 55 55"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        id="Page-1"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          id="Wedstrijd-video-upload---Desktop---1170px"
                          transform="translate(-235.000000, -249.000000)"
                          fillRule="nonzero"
                        >
                          <g
                            id="Group-4"
                            transform="translate(173.000000, 249.000000)"
                          >
                            <g
                              id="noun_File_1912838_1A1A1A"
                              transform="translate(62.000000, 0.000000)"
                            >
                              <path
                                d="M5.18518519,43.7962963 L0,43.7962963 L0,2.63040819 C0,1.18368368 1.16666667,0 2.59259259,0 L42.7777778,0 L42.7777778,5.26081637 L5.18518519,5.26081637 L5.18518519,43.7962963 Z"
                                id="Shape"
                                fill="var(--primary-color)"
                              />
                              <path
                                d="M51.752381,9.16666667 C51.752381,9.16666667 41.8261905,9.19261986 31.8738095,9.21857305 C26.1119048,9.24452624 21.502381,9.27047943 18.1761905,9.29643262 C16.2904762,9.29643262 14.7452381,9.32238581 13.6452381,9.348339 C11.3142857,9.37429219 10.7642857,9.37429219 9.97857143,10.2826538 C9.32380952,11.0352963 9.32380952,11.3207814 9.29761905,13.7084749 C9.29761905,14.7985089 9.27142857,16.3816535 9.27142857,18.2502831 C9.2452381,21.9875425 9.2452381,26.9705549 9.21904762,31.9276142 C9.19285714,41.8676859 9.16666667,51.7818045 9.16666667,51.7818045 C9.16666667,53.5466214 10.6071429,55 12.4142857,55 L51.752381,55 C53.5333333,55 55,53.5466214 55,51.7818045 L55,12.3848622 C55,10.5940921 53.5595238,9.16666667 51.752381,9.16666667 Z M30.9571429,36.2358437 L23.8333333,36.2358437 C22.3928571,36.2358437 21.2142857,35.0679502 21.2142857,33.6405247 C21.2142857,32.2130993 22.3928571,31.0452057 23.8333333,31.0452057 L30.9309524,31.0452057 C32.3714286,31.0452057 33.55,32.2130993 33.55,33.6405247 C33.55,35.0679502 32.397619,36.2358437 30.9571429,36.2358437 Z M40.3333333,26.840789 L23.8333333,26.840789 C22.3928571,26.840789 21.2142857,25.6728954 21.2142857,24.24547 C21.2142857,22.8180445 22.3928571,21.650151 23.8333333,21.650151 L40.3333333,21.650151 C41.7738095,21.650151 42.952381,22.8180445 42.952381,24.24547 C42.952381,25.6728954 41.7738095,26.840789 40.3333333,26.840789 Z"
                                id="Shape"
                                fill="#1A1A1A"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className="text-center">{t('selectFiles')}</div>
                </div>
                <div className="col-6 col-md-3 pb-4 text-center" id="upload-2">
                  <div className="upload-icon text-center">
                    <svg
                      viewBox="0 0 60 48"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        id="Page-1"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          id="Wedstrijd-video-upload---Desktop---1170px"
                          transform="translate(-447.000000, -253.000000)"
                        >
                          <g
                            id="Group-4"
                            transform="translate(173.000000, 249.000000)"
                          >
                            <g
                              id="noun_Browser_1617695_1A1A1A"
                              transform="translate(274.000000, 4.000000)"
                            >
                              <path
                                d="M57,0 L3,0 C1.3416,0 0,1.3416 0,3 L0,45 C0,46.6584 1.3416,48 3,48 L57,48 C58.6584,48 60,46.6584 60,45 L60,3 C60,1.3416 58.6584,0 57,0 Z M39,35.4024 L35.3994,39 L29.1654,32.7714 L27.2994,39 L21,21 L39,27.3048 L32.7654,29.1678 L39,35.4024 Z M57,12 L3,12 L3,3 L57,3 L57,12 Z"
                                id="Shape"
                                fill="#000000"
                                fillRule="nonzero"
                              />
                              <path
                                d="M51,7.5 C51,6.6738 51.6708,6 52.5,6 C53.3292,6 54,6.6738 54,7.5 C54,8.3322 53.3292,9 52.5,9 C51.6708,9 51,8.3322 51,7.5 Z"
                                id="Path"
                                fill="var(--primary-color)"
                              />
                              <path
                                d="M45,7.5 C45,6.6738 45.6708,6 46.5,6 C47.3292,6 48,6.6738 48,7.5 C48,8.3322 47.3292,9 46.5,9 C45.6708,9 45,8.3322 45,7.5 Z"
                                id="Path"
                                fill="var(--primary-color)"
                              />
                              <path
                                d="M39,7.5 C39,6.6738 39.6708,6 40.5,6 C41.3292,6 42,6.6738 42,7.5 C42,8.3322 41.3292,9 40.5,9 C39.6708,9 39,8.3322 39,7.5 Z"
                                id="Path"
                                fill="var(--primary-color)"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className="text-center">{t('duringUploadMessage')}</div>
                </div>
                <div className="col-6 col-md-3 pb-4 text-center" id="upload-3">
                  <div className="upload-icon text-center">
                    <svg
                      viewBox="0 0 60 55"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        id="Page-1"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g
                          id="Wedstrijd-video-upload---Desktop---1170px"
                          transform="translate(-663.000000, -249.000000)"
                          fillRule="nonzero"
                        >
                          <g
                            id="Group-4"
                            transform="translate(173.000000, 249.000000)"
                          >
                            <g
                              id="noun_process_876181_1A1A1A"
                              transform="translate(490.000000, 0.000000)"
                            >
                              <path
                                d="M45.75,20.2416667 C45.6168514,19.7125324 45.1183963,19.3590182 44.575,19.4083333 L40.1416667,19.8083333 C39.4490117,18.9431735 38.6511917,18.1677484 37.7666667,17.5 L38.1,13.0666667 C38.1443068,12.5283196 37.7911724,12.0374628 37.2666667,11.9083333 L31.6666667,10.3416667 C31.1517973,10.1994553 30.6087386,10.4447076 30.375,10.925 L28.3916667,14.95 C27.2734513,15.082296 26.1777601,15.3625239 25.1333333,15.7833333 L21.4166667,13.2833333 C20.9727373,12.9855691 20.3805761,13.0413019 20,13.4166667 L15.9166667,17.55 C15.5394597,17.9263583 15.4834584,18.5178726 15.7833333,18.9583333 L18.2833333,22.6833333 C17.8534442,23.7507885 17.5730179,24.8724937 17.45,26.0166667 L13.5,27.9583333 C13.0189942,28.1917314 12.7709108,28.7333219 12.9083333,29.25 L14.4083333,34.75 C14.5463495,35.2684396 15.031246,35.617286 15.5666667,35.5833333 L19.9416667,35.2583333 C20.266652,35.7358515 20.631871,36.1847085 21.0333333,36.6 C21.4325801,37.0006394 21.8585591,37.373719 22.3083333,37.7166667 L22.0166667,42.025 C21.9801027,42.5593278 22.3308521,43.0433619 22.85,43.175 L28.3333333,44.675 C28.4329658,44.6871249 28.5337009,44.6871249 28.6333333,44.675 C29.0494092,44.6737709 29.4308322,44.4429939 29.625,44.075 L31.6666667,40.1833333 C32.7942302,40.0578915 33.8990932,39.7774694 34.95,39.35 L38.6666667,41.85 C39.1150734,42.1278628 39.6971854,42.0511774 40.0583333,41.6666667 L44.1666667,37.5916667 C44.5478345,37.2146758 44.6073016,36.6200045 44.3083333,36.175 L41.8083333,32.4583333 C42.2347975,31.4070965 42.5151839,30.3023742 42.6416667,29.175 L46.725,27.125 C47.2085303,26.8940786 47.4576134,26.3503055 47.3166667,25.8333333 L45.75,20.2416667 Z M24.5083333,27.5 C24.5083333,24.3980007 27.0230007,21.8833333 30.125,21.8833333 C33.2269993,21.8833333 35.7416667,24.3980007 35.7416667,27.5 C35.7416667,30.6019993 33.2269993,33.1166667 30.125,33.1166667 C27.0230007,33.1166667 24.5083333,30.6019993 24.5083333,27.5 Z"
                                id="Shape"
                                fill="var(--primary-color)"
                              ></path>
                              <path
                                d="M13.025,44.5083333 C6.33044604,37.8301914 4.16871225,27.849698 7.5,19 L7.8,20.3083333 C7.97469057,21.0648287 8.64859692,21.6004978 9.425,21.6 C9.55117421,21.6003505 9.67698016,21.5863721 9.8,21.5583333 C10.2311215,21.4591275 10.6051143,21.1925903 10.8395797,20.8174457 C11.0740451,20.442301 11.1497446,19.9893305 11.05,19.5583333 L9.74166667,13.85 C9.64246085,13.4188785 9.37592366,13.0448857 9.000779,12.8104203 C8.62563434,12.5759549 8.17266383,12.5002554 7.74166667,12.6 L2.03333333,13.9083333 C1.13587061,14.1131389 0.574361072,15.0067039 0.779166667,15.9041667 C0.983972261,16.8016294 1.87753728,17.3631389 2.775,17.1583333 L4.9,16.6666667 C0.222743562,27.5269413 3.04492315,40.162787 11.8984336,48.001004 C20.7519441,55.839221 33.6366928,57.1090812 43.85,51.15 L42.1833333,48.275 C32.7379911,53.7951103 20.7575151,52.2474752 13.025,44.5083333 Z"
                                id="Shape"
                                fill="#1A1A1A"
                              ></path>
                              <path
                                d="M57.1916667,37.8333333 L55.2166667,38.2833333 C59.8785819,27.3745681 56.9908183,14.704754 48.0621739,6.89361318 C39.1335295,-0.917527677 26.1922933,-2.09556578 16,3.975 L17.7083333,6.83333333 C26.5133334,1.57863988 37.6812022,2.48212679 45.5270091,9.08387996 C53.372816,15.6856331 56.1721386,26.5346596 52.5,36.1083333 L52.175,34.675 C51.9701944,33.7775373 51.0766294,33.2160278 50.1791667,33.4208334 C49.281704,33.625639 48.7201944,34.519204 48.925,35.4166667 L50.2333333,41.125 C50.4080239,41.8814953 51.0819303,42.4171645 51.8583333,42.4166667 C51.9845075,42.4170172 52.1103135,42.4030388 52.2333333,42.375 L57.9416667,41.0666667 C58.5222233,40.9341807 58.9879993,40.5020353 59.1635414,39.9330156 C59.3390834,39.3639958 59.1977226,38.7445493 58.792708,38.3080155 C58.3876935,37.8714818 57.7805566,37.6841806 57.2,37.8166667 L57.1916667,37.8333333 Z"
                                id="Shape"
                                fill="#1A1A1A"
                              ></path>
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className="text-center">{t('duringProcessMessage')}</div>
                </div>
                <div className="col-6 col-md-3 pb-4 text-center" id="upload-4">
                  <div className="upload-icon text-center">
                    <svg
                      viewBox="0 0 55 55"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        id="Page-1"
                        stroke="none"
                        strokeWidth="1"
                        fill="none"
                        fillRule="evenodd"
                      >
                        <g transform="translate(-880.000000, -249.000000)">
                          <g
                            id="Group-4"
                            transform="translate(173.000000, 249.000000)"
                          >
                            <g
                              id="noun_Check_1722530_1A1A1A"
                              transform="translate(707.000000, 0.000000)"
                            >
                              <path
                                d="M49.5004942,27.3771237 C49.5004942,39.5327658 39.6011966,49.4760466 27.5006243,49.4760466 C15.3994017,49.4760466 5.49945375,39.5327658 5.49945375,27.3771237 C5.49945375,12.68063 19.6640217,2.12073597 33.5502601,6.1084079 L37.9507544,1.68901524 C19.3928715,-4.52465669 0,6.93532939 0,27.3771629 C0,42.5694294 12.3755853,55 27.5006503,55 C42.6244147,55 55,42.5694294 55,27.3771629 L49.5005202,27.3771629 L49.5004942,27.3771237 Z"
                                id="Shape"
                                fill="#000000"
                              />
                              <path
                                d="M53.3612502,12.0745331 L26.0290283,39.5183956 C21.0647347,34.9899199 16.5562642,29.9904796 11.7591113,25.2856682 L17.168199,19.7251752 L26.0810525,28.4731799 L47.8903838,6.57544019 L53.3612502,12.0745331 Z"
                                id="Path"
                                fill="var(--primary-color)"
                              />
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                  </div>
                  <div className="text-center">{t('readyToStartMessage')}</div>
                </div>
              </div>

              <div className="match-overview-card-wrapper">
                <MatchOverviewItem sportingEvent={sportingEvent} />
              </div>
            </>
          )}
          <div className="row">
            <div className="col-12">
              <FileUploadPreview
                files={files.toJS()?.map((f) => f)}
                onRemove={onRemoveFile}
                onReOrder={onReOrder}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="upload-field break-after relative width-100 text-center">
                <input
                  type="file"
                  id="file-select"
                  style={{ display: 'none' }}
                  onChange={(e) => {
                    onAddFile(e.target.files[0]);
                    e.target.value = '';
                  }}
                />
                <label
                  className="width-100"
                  style={{ display: 'inline' }}
                  htmlFor="file-select"
                >
                  <div className="clickable upload-instruction-wrapper relative height-100">
                    <div className="flex-content">
                      <div className="upload-instruction relative text-center">
                        {t('clickToSelectVideo')}
                      </div>
                    </div>
                  </div>
                </label>
                <div className="uploaded-files"></div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <SubmittingButton
                className="btn btn-primary extramargin"
                disabled={files.length === 0}
                onClick={onStart}
              >
                {type === 'assignment'
                  ? t('module.development.tasks:form:submitVideo')
                  : t('startUpload')}
              </SubmittingButton>
            </div>
          </div>
        </Fragment>
      );
    }
  )
);

export const UploadProgress = withTranslation('module.match.upload')(
  observer(({ uploadState, onPause, onResume, t, isLiveRecording }) => {
    let percentage = 0;
    let state = '';
    let speed = null;
    switch (uploadState.state) {
      case 'queueing':
      case 'starting':
        state = t('starting');
        break;
      // return <div>Starting upload...</div>;
      case 'uploading':
        speed = uploadState.speed;
      case 'stopped':
        percentage = (uploadState.bytesUploaded / uploadState.bytesTotal) * 100;
        state =
          uploadState.state === 'uploading' ? t('uploading') : t('paused');
        break;
      // return <div>{uploadState.bytesUploaded} / {uploadState.bytesTotal}</div>;
      case 'success':
        state = t('completed');
        break;
      // return <div>klaar...</div>;
      case 'error':
        state = t('common:error');
        break;
      // return <div>fout</div>;
      default:
        debugger;
    }

    if (speed === null) {
      speed = '';
    } else {
      // const bytesRemaining = uploadState.bytesTotal - uploadState.bytesUploaded;
      // const remainingTime = parseInt(bytesRemaining / speed);
      speed = `(${((speed / 1024) * 8).toFixed(1)}Kb/s)`; // - ${remainingTime})`;
    }

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <div className="upload-warning text-center width-100">
              <div className="upload-warning-inner">
                <div className="warning-icon float-left" />
                {t('dontCloseWindowMessage')}
              </div>
            </div>
            <b>{t('title')}</b>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="upload-progress">
              <div
                className="upload-progress-inner"
                style={{ width: `${percentage}%` }}
              />
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <div className="col-12">
            <div className="float-left">
              {uploadState.state !== 'stopped' && (
                <div
                  className="progress-icon float-left clickable"
                  id="pause"
                  onClick={onPause}
                />
              )}
              {(uploadState.state === 'stopped' ||
                uploadState.state === 'error') && (
                <div
                  className="progress-icon float-left clickable"
                  id="resume"
                  onClick={onResume}
                />
              )}
              {/*<div className="progress-icon float-left" id="stop"></div>*/}
            </div>
            <div className="float-right">
              <div className="progress-text float-left">{state} </div>
              <div className="progress-text float-left">
                {' '}
                {t('completion')}{' '}
                <b>
                  {percentage.toFixed(0)}% {speed}
                </b>{' '}
              </div>
              {/*<div className="progress-text float-left">Resterend <b>00:02:09</b></div>*/}
            </div>
          </div>
        </div>
        {!isLiveRecording && (
          <div className="row">
            <div className="col-12 text-center">
              <QuoteCarousel />
            </div>
          </div>
        )}
      </Fragment>
    );
  })
);

export const EncodeProgress = withTranslation('module.match.upload')(
  observer(({ encodeState, t, isLiveRecording }) => {
    let stateMessage;
    let percentage = 0.0;
    switch (encodeState.state) {
      case 'new': // client knows it sent all data, server is not informed yet
      case 'waiting_for_encode':
        stateMessage = t('processingVideo');
        break;

      case 'encoding':
        stateMessage = t('processingVideo');
        percentage = (encodeState.percentage / 100) * 99;
        break;

      case 'fetching_content_info':
        stateMessage = t('processingVideo');
        percentage = 99;
        break;

      case 'ready':
        stateMessage = t('completed');
        percentage = 100;
        break;

      case 'failed':
        stateMessage = t('common:error');
        percentage = 0;
        break;
    }

    return (
      <Fragment>
        <div className="row">
          <div className="col-12">
            <h5>{stateMessage}</h5>
            <div>
              {!isLiveRecording && (
                <div className="upload-warning-inner">
                  <div className="info-icon float-left" />
                  {t('closeWindowMessage')}
                </div>
              )}
              {isLiveRecording && !encodeState.state === 'ready' && (
                <div className="upload-warning-inner">
                  <div className="warning-icon float-left" />
                  {t('dontCloseWindowMessage')}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="video-process-progress">
              <div
                className="video-process-progress-inner"
                style={{ width: `${percentage}%` }}
              />
            </div>
          </div>
        </div>
        <div
          className="row"
          style={{ marginTop: '10px', marginBottom: '10px' }}
        >
          <div className="col-12">
            <div className="float-right">
              <div className="progress-text float-left">{stateMessage} </div>
              <div className="progress-text float-left">
                {' '}
                {t('completion')} <b>{percentage.toFixed(0)}%</b>{' '}
              </div>
              {/*<div className="progress-text float-left">Resterend <b>00:02:09</b></div>*/}
            </div>
          </div>
        </div>
        {!isLiveRecording && (
          <div className="row">
            <div className="col-12 text-center">
              <QuoteCarousel />
            </div>
          </div>
        )}
      </Fragment>
    );
  })
);

const UploadVideo = withTranslation('module.match.upload')(
  observer(
    class UploadVideo extends Component {
      constructor(props) {
        super(props);

        this.onBeforeUnloadHandler = this.onBeforeUnloadHandler.bind(this);

        this.state = observable('selecting-files');
        // this.state = observable('uploading');

        const onStartHandler = action((videoId) => {
          this.state.set('uploading');

          EventPublisher.dispatch('UPLOAD_STARTED');
          props.onStart && props.onStart(videoId, this.sportingEvent);
        });

        const onSuccessHandler = action((videoId) => {
          this.encodeState.startMonitoring(videoId);
          this.state.set('encoding');

          EventPublisher.dispatch('UPLOAD_COMPLETED');

          props.onUploaded && props.onUploaded(videoId, this.sportingEvent);
        });

        this.uploadState = new UploadState();
        this.encodeState = new EncodeState();

        this.uploader = new FileUploader(
          onStartHandler,
          onSuccessHandler,
          this.uploadState
        );

        this.type = this.props.type ?? 'match';
        this.sportingEvent = this.props.sportingEvent ?? null;
      }

      onBeforeUnloadHandler(e) {
        if (this.state.get() === 'uploading') {
          e.preventDefault();
          e.returnValue = '';
        }
      }

      componentWillUnmount() {
        if (this.state.get() === 'uploading') {
          EventPublisher.dispatch('UPLOAD_ABORTED');
          this.uploader.stopUpload();
        }

        this.encodeState.stopMonitoring();

        window.removeEventListener('beforeunload', this.onBeforeUnloadHandler);
      }

      componentDidMount() {
        window.addEventListener('beforeunload', this.onBeforeUnloadHandler);
      }

      onBeforeClose() {
        const close = () => {
          this.props.sportingEvent.fetch();
          this.props.onReady();
        };
        if (this.state.get() === 'uploading') {
          sweetAlert({
            title: this.props.t('abortUploadConfirmTitle'),
            text: this.props.t('abortUploadConfirmMessage'),
            dangerMode: true,
            buttons: [this.props.t('common:cancel'), this.props.t('abortCTA')],
          }).then(async (willDelete) => {
            if (willDelete) {
              await this.props.sportingEvent.deleteVideo(this.uploader.videoId);
              close();
            }
          });
        } else {
          close();
        }
      }
      async startNewSportingEvent(task, assignmentName) {
        const date = new Date();

        const sportingEvent = await SportingEvent.create(
          {
            name: `${assignmentName} - ${task.name} `,
            scheduledAt: date.toISOString(),
            type: 'training', // TODO: consider 'recording'
            tags: {
              taskId: task.taskId,
              fileNames: this.uploader.files.map((file) => file.name),
            },
          },
          { optimistic: false }
        );
        const uploadable = await SportingEventCollection.getOrFetch(
          sportingEvent.sportingEventId
        );
        this.sportingEvent = uploadable;
        this.uploader.start(this.sportingEvent);
      }

      render() {
        let component = 'empty';
        switch (this.state.get()) {
          case 'selecting-files':
            component = (
              <FileSelect
                files={this.uploadState.files}
                onAddFile={(file) => this.uploader.addFile(file)}
                onRemoveFile={(file) => this.uploader.removeFile(file)}
                onReOrder={(startIndex, endIndex) =>
                  this.uploader.reOrder(startIndex, endIndex)
                }
                onStart={() =>
                  this.props.sportingEvent
                    ? this.uploader.start(this.props.sportingEvent)
                    : this.startNewSportingEvent(
                        this.props.task,
                        this.props.assignmentName
                      )
                }
                sportingEvent={this.props.sportingEvent}
                type={this.type}
              />
            );
            break;

          case 'uploading':
            component = (
              <UploadProgress
                uploadState={this.uploadState}
                onPause={() => this.uploader.stopUpload()}
                onResume={() => this.uploader.startUpload()}
              />
            );
            break;

          case 'encoding':
            component = <EncodeProgress encodeState={this.encodeState} />;
            break;
        }

        if (this.props.hideModal) {
          return component;
        }

        return (
          <Modal size="md" onCloseClick={() => this.onBeforeClose()}>
            {component}
          </Modal>
        );
      }
    }
  )
);

export default UploadVideo;
