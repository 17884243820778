import { Session } from '../../../domain/Session';
import moment from 'moment';
import teamCollection from '../../../domain/Team';

export const filterSportingEvents = (viewArguments, sportingEvents) => {
  /*
   *  Returns sportingEvents of Type grouped by pastSportingEvents, todaySportingEvents , upcomingSportingEvents and archived.
   *  Filtered by arguments in viewArguments.
   *   ViewArguments = {
   *     type: eg. match,
   *     group: all or my-matches,
   *     filters: eg. Archived
   *  }
   * */
  let result = [];
  let currentSession = Session.current();
  const today = moment();
  if (sportingEvents?.length) {
    result = sportingEvents;
  }

  // Default filtering

  const _isShared = (s) => {
    return s._metadata?.source?.type === 'SharedResourceGroup';
  };
  const _isCopied = (s) => {
    /* Check if there is a copy available of this item */
    return !!sportingEvents.find((_se) => {
      return s.sportingEventId === _se.tags?.copyOf;
    });
  };

  switch (viewArguments.type) {
    case 'match':
    case 'recording':
      result = result.filter((s) => {
        // todo: use own type recording.
        if (_isCopied(s) && _isShared(s)) {
          return false;
        }
        if (s.type !== 'match' && s.type !== 'recording') {
          return false;
        } else {
          return true;
        }
      });
      break;
    // case 'recording':
    //   result = result.filter((s) => {
    //     return s.type === 'recording'
    //   });
    //   break;
    case 'training':
      result = result.filter((s) => {
        return s.type === 'training';
      });
      break;
    default:
      result = result;
  }

  if (viewArguments.search) {
    result = filterSearch(result, viewArguments.search);
  }

  if (viewArguments.group && viewArguments.group !== 'all') {
    let currentTeam = currentSession.currentResource();
    switch (viewArguments.group) {
      case 'my-matches':
        result = result.filter((s) => {
          let _currentTeam = teamCollection.get(currentTeam.id);
          let awayTeam = teamCollection.get(s.awayTeamId, true);
          let homeTeam = teamCollection.get(s.homeTeamId, true);

          return (
            s.homeTeamId === currentTeam.id ||
            s.awayTeamId === currentTeam.id ||
            // when awayTeamId/homeTeamId is 'not_set' or not found the `.get` will break
            (awayTeam && awayTeam.get('name') === _currentTeam.get('name')) ||
            (homeTeam && homeTeam.get('name') === _currentTeam.get('name')) ||
            s.name.includes(_currentTeam.name) ||
            // From legacy isMatchOf() [teamName and Id can deviate when source is share]
            s._metadata?.source?.share?.attributes?.teamId === s.awayTeamId ||
            s._metadata?.source?.share?.attributes?.teamId === s.homeTeamId
          );
        });
        break;
    }
  }

  result = result.sort((a, b) => {
    return a.scheduledAt === b.scheduledAt
      ? 0
      : a.scheduledAt > b.scheduledAt || b.scheduledAt === false
      ? -1
      : 1;
  });

  let archived = [];
  if (1 || viewArguments.filters.includes('archived')) {
    // Archive always shown
    archived = result.filter((s) => {
      return (
        !s._metadata.privilegesV2['sporting-event:view'].ok === true ||
        !s._metadata.privilegesV2['sporting-event:view-video'].ok === true
      );
    });
  }
  // Remove archived
  result = result.filter((s) => {
    return (
      s.videoIds.length === 0 ||
      (s._metadata.privilegesV2['sporting-event:view'].ok === true &&
        s._metadata.privilegesV2['sporting-event:view-video'].ok === true)
    );
  });

  let upcoming = [];
  upcoming = result
    .filter((s) => {
      return moment(s.scheduledAt).isAfter(today, 'day');
    })
    .sort((a, b) => {
      sportingEvents.sort((a, b) => {
        return a.scheduledAt === b.scheduledAt
          ? 0
          : a.scheduledAt > b.scheduledAt || b.scheduledAt === false
          ? -1
          : 1;
      });
    })
    .reverse();

  result = result.filter((s) => {
    return !moment(s.scheduledAt).isAfter(today, 'day');
  });

  let todaySportingEvents = [];

  todaySportingEvents = result.filter((s) => {
    return moment(s.scheduledAt).isSame(today, 'day');
  });
  result = result.filter((s) => {
    return !moment(s.scheduledAt).isSame(today, 'day');
  });

  if (viewArguments.filters.includes('video')) {
    result = result.filter((s) => {
      return s.videoIds.length > 0;
    });
  }

  return {
    pastSportingEvents: result,
    upcomingSportingEvents: upcoming,
    todaySportingEvents: todaySportingEvents,
    archivedSportingEvents: archived,
  };
};

const filterSearch = (sportingEvents, value) => {
  return sportingEvents.filter((sportingEvent) => {
    let homeTeam = sportingEvent?.homeTeamId
      ? teamCollection.get(sportingEvent.homeTeamId, true)
      : null;
    let awayTeam = sportingEvent?.awayTeamId
      ? teamCollection.get(sportingEvent.awayTeamId, true)
      : null;

    let haystack = `${sportingEvent.name.toLowerCase()}${homeTeam?.name}${
      awayTeam?.name
    }`;
    let needle = value.toLowerCase();
    return haystack.indexOf(needle) !== -1;
  });
};
