import { PersistentCollection, Model } from '../PersistentCollection';

import PlayerCollection from '../Player';

import SHIELD_IMAGE from '../../img/icons/club_placeholder.svg';
import { isDev } from '../../lib/dev';

// const SHIELD_IMAGE = "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyBmaWxsPSIjMDAwMDAwIiB2ZXJzaW9uPSIxLjEiIHZpZXdCb3g9IjAgMCAxMDAgMTAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJtNTAgOS0zMS4yNSAxMWMtNy45MTggMjUuOTM0IDAuOTAyMzQgNTkuNzg1IDMxLjI1IDcxIDMwLjM1Mi0xMS4yMTUgMzkuMTY4LTQ1LjA2NiAzMS4yNS03MXoiLz4KPC9zdmc+";

class Team extends Model {
  constructor(attributes) {
    super(attributes);

    this.playerCollection = null;
  }

  get primaryKey() {
    return 'teamId';
  }

  get teamId() {
    return this.get('teamId');
  }

  get logoUrl() {
    let logoUrl = this.has('appearance') && this.get('appearance').logoUrl;
    if (!logoUrl) {
      logoUrl = SHIELD_IMAGE;
    }
    return logoUrl;
  }

  get label() {
    return (this.has('name')&&this.get('name')) || '-';
  }

  get name() {
    return  (this.has('name')&&this.get('name')) || '-';
  }

  get sportType() {
    return this.has('sportType') ? this.get('sportType') : null;
  }

  get shortLabel() {
    if (this.label.length < 5) {
      return this.label;
    }

    let _shortLabel = this.label.substring(0, 3).toUpperCase();
    // if (this.label.indexOf(' ') !== -1) {
    //   const ageIdentifier = this.label.split(/[, ]+/).pop();
    //   _shortLabel += ' ' + ageIdentifier;
    // }
    return _shortLabel;
  }

  get players() {
    if (this.playerCollection === null) {
      if (this.teamId === 'not_set') {
        this.playerCollection = PersistentCollection.emptyCollection();
      } else {
        this.playerCollection = new PlayerCollection(`${this.url()}/players`);
      }
    }
    return this.playerCollection;
  }
  get playerCount() {
    if (this.has('players')) {
      return Object.keys(this.get('players')).length;
    }
  }

  addPlayer(personId, number) {
    this.players.add([
      {
        personId,
        number,
      },
    ]);
  }
  isShared() {
    return (
      this.has('_metadata') &&
      this.get('_metadata').source.type === 'SharedResourceGroup'
    );
  }

  isCopied() {
    return (
      !!this.getLocalCopy() || (!!this._isCopiedShare && this._isCopiedShare())
      // this.tags?.copyOf
    );
  }

  isSameAs(team) {
    if (team === null) {
      return false;
    }
    return (
      team.id === this.id ||
      (this.tags.externalIdentifier !== undefined &&
        this.tags.externalIdentifier === team.tags.externalIdentifier)
    );
  }

  _isCopiedShare() {
    // return try when item is a shared team, and copied
    if (!this.isShared()) {
      return false;
    }
    const shareTeamId = this.get('_metadata').source.share.attributes.teamId;
    return shareTeamId === this.id;
  }

  aliases() {
    return (this.has('aliases') && this.get('aliases')) || [];
  }

  aliasedTeams() {
    return this.aliases().map((a) => {
      return teamCollection
        .toArray()
        .find((t) => t.name === a && t.teamId !== this.teamId);
    });
  }

  aliasOf() {
    return (
      this.aliases().length === 0 &&
      (teamCollection.toArray().find((t) => t.aliases().includes(this.name))
        ?.teamId ??
        null)
    );
  }

  createLocalTeamFromShareTeam() {
    return this.rpc('createLocalTeamFromShareTeam');
  }
}

class TeamCollection extends PersistentCollection {
  url() {
    return '/teams';
  }

  model() {
    return Team;
  }

  get(id, getAliasWhenAvailable = false) {
    if (getAliasWhenAvailable) {
      let team = super.get(id);
      if (team?.aliasOf()) {
        isDev() && console.log('Team has alias, fetching parent Team');
        return super.get(team.aliasOf());
      }
    }
    // default
    return super.get(id);
  }
  // toArray(getAliasWhenAvailable = false) {
  //   todo: remove doubles when getting alias.
  //   return super.toArray();
  // }
}

const teamCollection = new TeamCollection();
window.teamCollection = teamCollection;

export default teamCollection;
