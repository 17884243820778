import React, { Component } from 'react';
import { action, observable, runInAction } from 'mobx';
import { now } from 'mobx-utils';
import { observer } from 'mobx-react';

import { Command, CommandBus, CommandHistory } from '../../../infra/Messaging';

import { InputTrigger } from '../components/InputTrigger';
import { ObservationInputBase } from '../components/ObservationInputBase';
import { ObservationInputOptions, ObservationInput } from '../ObservationInput';

import backgroundImageLeftGreen from '../../../img/korfbalveld-links-groen.svg';
import backgroundImageLeftRed from '../../../img/korfbalveld-links-rood.svg';
import backgroundImageRightGreen from '../../../img/korfbalveld-rechts-groen.svg';
import backgroundImageRightRed from '../../../img/korfbalveld-rechts-rood.svg';
import { Modal } from 'lib/Modal';
import { gotoRoute } from '../../../modules/route';
import { Trans, withTranslation } from 'react-i18next';

import previewImgSrc from './korfball.live.default.png';

const PositionMarker = observer(({ position, color }) => {
  if (!position) {
    return <div />;
  }
  const size = 20;
  const left = position.x - size / 2;
  const top = position.y - size / 2;
  return (
    <div
      style={{
        height: size + 'px',
        width: size + 'px',
        borderRadius: '50%',
        zIndex: 2,
        backgroundColor: color,
        left: left + 'px',
        top: top + 'px',
        position: 'absolute',
      }}
    />
  );
});

const Label = observer(({ text }) => {
  let value;
  if (typeof text === 'function') {
    value = text();
  } else {
    value = text;
  }

  return (
    <div
      style={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {value}
    </div>
  );
});

class LocationInput extends Component {
  REAL_FIELD_WIDTH = 20;
  REAL_FIELD_HEIGHT = 20;

  constructor() {
    super();
    this.domRef = React.createRef();
  }

  get imageWidth() {
    const bb = this.domRef.current.getBoundingClientRect();
    return bb.width;
  }

  get imageHeight() {
    const bb = this.domRef.current.getBoundingClientRect();
    return bb.height;
  }

  get rotation() {
    return typeof this.props.rotation !== 'undefined' ? this.props.rotation : 0;
  }

  get centerX() {
    switch (this.rotation) {
      case -90:
        return this.imageWidth * 0.31;
      case 0:
        return this.imageWidth * 0.5;
      case 90:
        return this.imageWidth * 0.69;
    }
  }

  get centerY() {
    switch (this.rotation) {
      case -90:
        return this.imageHeight * 0.5;
      case 0:
        return this.imageHeight * 0.31;
      case 90:
        return this.imageHeight * 0.5;
    }
  }

  cart2pol({ x, y }) {
    const xCorrected =
      ((x - this.centerX) / this.imageWidth) * this.REAL_FIELD_WIDTH;
    const yCorrected =
      ((y - this.centerY) / this.imageHeight) * this.REAL_FIELD_HEIGHT;

    const distance =
      Math.round(Math.sqrt(xCorrected ** 2 + yCorrected ** 2) * 10) / 10;
    let angle =
      Math.round(
        (Math.atan2(yCorrected, xCorrected) / (2 * Math.PI)) * 360 * 10
      ) / 10;

    angle -= this.rotation + 90;
    if (angle < -180) {
      angle = 360 + angle;
    }

    return { distance, angle };
  }

  pol2cart({ distance, angle }) {
    if (angle > 90) {
      angle -= 360;
    }
    angle += this.rotation + 90;

    const phi = (angle / 360) * 2 * Math.PI;
    const rho = distance;

    const x =
      ((rho * Math.cos(phi)) / this.REAL_FIELD_WIDTH) * this.imageWidth +
      this.centerX;
    const y =
      ((rho * Math.sin(phi)) / this.REAL_FIELD_HEIGHT) * this.imageHeight +
      this.centerY;
    return { x, y };
  }

  onClick(e) {
    if (this.props.active) {
      const bb = e.nativeEvent.target.getBoundingClientRect();
      const x = e.nativeEvent.clientX - bb.left;
      const y = e.nativeEvent.clientY - bb.top;
      const { distance, angle } = this.cart2pol({ x, y });

      const command = Command.create(
        'ObservationLogger.StartRangeObservation',
        {
          groupId: this.props.groupId,
          code: this.props.observationCode,
          attributes: { distance, angle },
        }
      );
      CommandBus.dispatch(command);
    } else {
      this.props.onActivate();
    }
  }

  render() {
    // if (this.props.showCurrentMarker)
    // {
    //     let position = null;
    //     const attributes = this.props.observationLogger.getCurrentPartialObservationAttributes(this.props.groupId);
    //     if (attributes && attributes.has("distance") && attributes.has("angle") && this.props.active)
    //     {
    //         position = this.pol2cart({distance: attributes.get('distance'), angle: attributes.get('angle')});
    //     }
    // }

    // TODO: move this to higher component
    let markers = [];
    if (this.props.active) {
      markers = this.props.observationLogger
        .getCurrentPossessionShots()
        .map((shotObservation) => {
          const attributes = shotObservation.get('attributes');
          return {
            position: this.pol2cart({
              distance: attributes.distance,
              angle: attributes.angle,
            }),
            color: {
              MISS: '#cf0f0f',
              HIT: '#cf990f',
              GOAL: '#0fcf85',
            }[attributes.result],
          };
        });
    }

    // this.props.observationLogger
    const backgroundImageLeft =
      this.props.color === 'green'
        ? backgroundImageLeftGreen
        : backgroundImageLeftRed;
    const backgroundImageRight =
      this.props.color === 'green'
        ? backgroundImageRightGreen
        : backgroundImageRightRed;
    const backgroundImage =
      this.props.rotation === -90 ? backgroundImageLeft : backgroundImageRight;

    return (
      <div ref={this.domRef} style={{ position: 'relative' }}>
        <img src={backgroundImage} className="width-100 field-image" />
        {!this.props.active && <div className="overlay" />}

        <div
          style={{
            height: '100%',
            width: '100%',
            top: 0,
            position: 'absolute',
            zIndex: 10,
          }}
          onClick={this.onClick.bind(this)}
          className="clickable possession"
        />
        <div
          style={{
            height: '100%',
            width: '100%',
            top: 0,
            position: 'absolute',
            zIndex: 6,
          }}
        >
          {markers.map((marker, i) => {
            return (
              <PositionMarker
                key={i}
                color={marker.color}
                position={marker.position}
              />
            );
          })}
        </div>
      </div>
    );
  }
}

const shotTypeLabels = {
  LONG: 'long',
  SHORT: 'short',
  'RUNNING-IN': 'running-in',
  'FREE-BALL': 'freeShot',
  PENALTY: 'penalty',
};

const shotResultLabels = {
  MISS: 'miss',
  HIT: 'hit',
  GOAL: 'goal',
};

const SelectShotDetails = withTranslation('module.observe.live')(
  ({ sportingEventId, observationLogger, players, t }) => {
    let playerSelect;
    if (players.length > 0) {
      playerSelect = (
        <ul className="invoer-select" id="player-select">
          {players.map((player) => {
            return (
              <InputTrigger
                key={player.person.id}
                type="set-attributes"
                observationCode="SHOT"
                groupId="SHOT"
                attributes={{ personId: player.person.id }}
                observationLogger={observationLogger}
                render={({ active, trigger }) => (
                  <li className={active ? 'selected' : ''}>
                    {!!player.person.profilePictureUrl && (
                      <div
                        className="select-thumb"
                        style={{
                          backgroundImage: `url(${player.person.profilePictureUrl})`,
                        }}
                        onClick={trigger}
                      />
                    )}
                    {!player.person.profilePictureUrl && (
                      <div className="select-thumb" onClick={trigger}>
                        {player.person.code}
                      </div>
                    )}
                    {player.player.number && (
                      <div className="select-shirt" onClick={trigger}>
                        {player.player.number}
                      </div>
                    )}
                    <div className="select-name clickable" onClick={trigger}>
                      {player.person.firstName}
                    </div>
                  </li>
                )}
              />
            );
          })}
        </ul>
      );
    } else {
      playerSelect = (
        <div style={{ paddingTop: '24px' }}>
          {t('noAllignmentWarning')}
          <br />
          <br />
          <div
            onClick={() => gotoRoute('lineup.detail', { sportingEventId })}
            className="font-heavy clickable"
          >
            {t('alignmentDescription')}
          </div>
        </div>
      );
    }
    return (
      <div className="row">
        <div className="col-4">
          <b>{t('selectPlayer')}</b>
          {playerSelect}
        </div>
        <div className="col-4">
          <b>{t('selectTypeOpportunity')}</b>
          <ul className="invoer-select" id="shot-select">
            {Object.entries(shotTypeLabels).map(([type, label]) => {
              return (
                <InputTrigger
                  key={type}
                  type="set-attributes"
                  observationCode="SHOT"
                  groupId="SHOT"
                  attributes={{ type: type }}
                  observationLogger={observationLogger}
                  render={({ active, trigger }) => (
                    <li className={active ? 'selected' : ''}>
                      <div className="select-name clickable" onClick={trigger}>
                        {t(label)}
                      </div>
                    </li>
                  )}
                />
              );
            })}
          </ul>
        </div>
        <div className="col-4">
          <b>{t('selectResult')}</b>
          <ul className="invoer-select" id="chance-select">
            {Object.entries(shotResultLabels).map(([result, label]) => {
              return (
                <InputTrigger
                  key={result}
                  type="end-point"
                  observationCode="SHOT"
                  groupId="SHOT"
                  attributes={{ result: result }}
                  observationLogger={observationLogger}
                  render={({ active, trigger }) => (
                    <li className={active ? 'selected' : ''}>
                      <div className="select-thumb" />
                      <div className="select-name clickable" onClick={trigger}>
                        {t(label)}
                      </div>
                    </li>
                  )}
                />
              );
            })}
          </ul>
        </div>
      </div>
    );
  }
);

const InfoOverlay = ({
  playDirection,
  activeSideName,
  matchState,
  homeTeamLabel,
  awayTeamLabel,
}) => {
  const elements = [];
  if (matchState === 'NOT_STARTED') {
    elements.push(
      <div key="pre-game" className="pre-game-overlay width-100 height-100">
        <div className="pre-game-overlay-half relative left">
          <div className="flex-content">
            <div className="relative width-100 pre-game-overlay-wrapper">
              <div className="pre-game-overlay-text">
                <Trans i18nKey="module.observe.live:startAttackDescription">
                  Klik
                  <b> hier</b>
                  <br />
                  voor start aanval
                </Trans>{' '}
                {playDirection === 'home-away' ? homeTeamLabel : awayTeamLabel}.
              </div>
            </div>
          </div>
        </div>
        <div className="pre-game-overlay-half relative right">
          <div className="flex-content">
            <div className="relative width-100 pre-game-overlay-wrapper">
              <div className="pre-game-overlay-text">
                <Trans i18nKey="module.observe.live:startAttackDescription">
                  Klik
                  <b> hier</b>
                  <br />
                  voor start aanval
                </Trans>{' '}
                {playDirection === 'home-away' ? awayTeamLabel : homeTeamLabel}.
              </div>
            </div>
          </div>
        </div>
        <div className="pre-game-overlay-bottom">
          <div className="pre-game-overlay-text">
            <Trans i18nKey="module.observe.live:tagShotDescription">
              Klik daarna in het veld om
              <br />
              een kans toe te voegen.
            </Trans>
          </div>
        </div>
      </div>
    );
  } else {
    if (activeSideName !== 'left') {
      elements.push(
        <div key="left" className="invoer-overlay left">
          <div className="invoer-overlay-inner relative width-100 height-100">
            <div className="flex-content">
              <div className="relative width-100 pre-game-overlay-wrapper">
                <div className="invoer-overlay-text">
                  <Trans i18nKey="module.observe.live:tagBaloverDescription">
                    Klik
                    <b>hier</b>
                    voor bal naar ander vak
                  </Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (activeSideName !== 'right') {
      elements.push(
        <div key="right" className="invoer-overlay right">
          <div className="invoer-overlay-inner relative width-100 height-100">
            <div className="flex-content">
              <div className="relative width-100 pre-game-overlay-wrapper">
                <div className="invoer-overlay-text">
                  <Trans i18nKey="module.observe.live:tagBaloverDescription">
                    Klik
                    <b>hier</b>
                    voor bal naar ander vak
                  </Trans>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
  return elements;
};

const KorfballObservationInput = withTranslation('module.observe.live')(
  observer(
    class KorfballObservationInput extends ObservationInputBase {
      constructor() {
        super();

        this.commandHistory = CommandHistory.instance();
        this._playDirection = observable('home-away');
      }

      onActivate(side) {
        const possessionTeamMap =
          this.playDirection() === 'home-away'
            ? {
                left: this.observationContext.homeTeam.id,
                right: this.observationContext.awayTeam.id,
              }
            : {
                left: this.observationContext.awayTeam.id,
                right: this.observationContext.homeTeam.id,
              };

        runInAction(() => {
          this.switchPossession(possessionTeamMap[side]);

          const command = Command.create(
            'ObservationLogger.RemovePartialObservation',
            { groupId: 'SHOT' }
          );
          CommandBus.dispatch(command);
        });

        this.commandHistory.tagHistoryItem('Bal-over');
      }

      switchPossession(teamId) {
        // make sure current attack is ended before determining goalCount
        CommandBus.dispatch(
          Command.create('ObservationLogger.TurnoffGroup', {
            groupId: 'POSSESSION',
          })
        );

        const activePosition =
          this.observationContext.goalCounter % 4 < 2 ? 'ATTACK' : 'DEFENCE';

        CommandBus.dispatch(
          Command.create('ObservationLogger.StartRangeObservation', {
            groupId: 'POSSESSION',
            code: 'POSSESSION',
            attributes: { teamId, position: activePosition },
          })
        );

        const teamData = this.lineUpData[teamId][activePosition];
        CommandBus.dispatch(
          Command.create('ObservationLogger.SetPointObservation', {
            code: 'START-POSSESSION',
            attributes: { teamId, position: activePosition },
            description: `Start balbezit: ${teamData.label}`,
          })
        );
      }

      setupObservationLoggerHooks() {
        // don't let parent add hooks
      }

      componentWillUnmount() {
        this.observationLogger.resetHooks();
      }

      componentWillMount() {
        super.componentWillMount();

        this.observationLogger.on(
          'afterObservationStarted',
          action((code, attributes) => {
            if (code === 'SHOT') {
              const command = Command.create(
                'ObservationLogger.SetObservationAttributes',
                {
                  groupId: 'SHOT',
                  code: 'SHOT',
                  attributes: {
                    type: attributes.distance <= 6 ? 'SHORT' : 'LONG',
                  },
                }
              );
              CommandBus.dispatch(command);
            }
          })
        );

        this.observationLogger.on(
          'descriptionRequested',
          (code, attributes) => {
            switch (code) {
              case 'POSSESSION':
                const teamData =
                  this.lineUpData[attributes.get('teamId')][
                    attributes.get('position')
                  ];
                return `${this.props.t('attack')} ${teamData.label}`;
              case 'SHOT':
                const shotTypeLabel = shotTypeLabels[attributes.get('type')];
                const shotResultLabel =
                  shotResultLabels[attributes.get('result')];

                let personLabel;
                if (attributes.has('personId')) {
                  personLabel = this.observationContext.lineUp.getPerson(
                    attributes.get('personId')
                  ).label;
                } else {
                  personLabel = '';
                }

                return `${shotTypeLabel} ${personLabel}: ${shotResultLabel}`;
              default:
                throw "Don't know how to create description!";
            }
          }
        );

        this.observationLogger.on(
          'afterObservationAdded',
          action(
            (
              code,
              attributes,
              triggerTime,
              startTime,
              endTime,
              description
            ) => {
              switch (code) {
                case 'SHOT':
                  if (attributes.get('result') === 'GOAL') {
                    const possessionAttributes =
                      this.observationLogger.getCurrentPartialObservationAttributes(
                        'POSSESSION'
                      );
                    if (possessionAttributes) {
                      const possessionTeamId =
                        possessionAttributes.get('teamId') ===
                        this.observationContext.homeTeam.id
                          ? this.observationContext.awayTeam.id
                          : this.observationContext.homeTeam.id;
                      this.switchPossession(possessionTeamId);
                    }

                    this.commandHistory.tagHistoryItem(
                      `${description} en vakwissel`
                    );
                  } else {
                    this.commandHistory.tagHistoryItem(description);
                  }
                  break;
                case 'GOAL-CORRECTION':
                  const activePosition =
                    this.observationContext.goalCounter % 4 < 2
                      ? 'ATTACK'
                      : 'DEFENCE';
                  const possessionAttributes =
                    this.observationLogger.getCurrentPartialObservationAttributes(
                      'POSSESSION'
                    );
                  if (possessionAttributes) {
                    if (
                      possessionAttributes.get('position') !== activePosition
                    ) {
                      const command = Command.create(
                        'ObservationLogger.SetObservationAttributes',
                        {
                          groupId: 'POSSESSION',
                          code: 'POSSESSION',
                          attributes: { position: activePosition },
                        }
                      );
                      CommandBus.dispatch(command);
                    }
                  }
                  break;
              }
            }
          )
        );

        this.observationContext.on('periodStarted', (periodNr) => {
          if (periodNr === 1 || periodNr === 3) {
            this.switchPossession(this.observationContext.homeTeam.id);
          } else if (periodNr === 2) {
            this.switchPossession(this.observationContext.awayTeam.id);
          }
        });

        this.observationContext.on('periodEnded', (periodNr) => {
          const command = Command.create('ObservationLogger.TurnoffGroup', {
            groupId: 'POSSESSION',
          });
          CommandBus.dispatch(command);
        });

        this.observationContext.on(
          'playDirectionChanged',
          action((direction) => {
            this._playDirection.set(direction);
          })
        );
      }

      get lineUpData() {
        const data = {};

        const homeTeam = this.observationContext.homeTeam;
        const awayTeam = this.observationContext.awayTeam;

        const homeTeamPlayers = this.observationContext
          .getTeamRoles(homeTeam.id)
          .filter(({ roleName }) => {
            return roleName === 'player';
          });
        const awayTeamPlayers = this.observationContext
          .getTeamRoles(awayTeam.id)
          .filter(({ roleName }) => {
            return roleName === 'player';
          });

        const sortFn = (a, b) => {
          return a.position === b.position
            ? 0
            : a.position < b.position
            ? -1
            : 1;
        };

        const sortByNumber = (a, b) => {
          return a.player.number - b.player.number;
        };

        homeTeamPlayers.sort(sortByNumber);
        awayTeamPlayers.sort(sortByNumber);

        const homeAttackPlayers = homeTeamPlayers.filter(({ position }) =>
          position.startsWith('ATTACK:')
        );
        const homeDefencePlayers = homeTeamPlayers.filter(({ position }) =>
          position.startsWith('DEFENCE:')
        );

        const awayAttackPlayers = awayTeamPlayers.filter(({ position }) =>
          position.startsWith('ATTACK:')
        );
        const awayDefencePlayers = awayTeamPlayers.filter(({ position }) =>
          position.startsWith('DEFENCE:')
        );

        return {
          [homeTeam.id]: {
            ATTACK: {
              players: homeAttackPlayers,
              label: `${this.props.t('firstAttack')} ${homeTeam.label}`,
            },
            DEFENCE: {
              players: homeDefencePlayers,
              label: `${this.props.t('firstDefence')} ${homeTeam.label}`,
            },
          },
          [awayTeam.id]: {
            ATTACK: {
              players: awayAttackPlayers,
              label: `${this.props.t('firstAttack')} ${awayTeam.label}`,
            },
            DEFENCE: {
              players: awayDefencePlayers,
              label: `${this.props.t('firstDefence')} ${awayTeam.label}`,
            },
          },
        };
      }

      playDirection() {
        return this._playDirection.get();
      }

      cancelCurrentShot() {
        const command = Command.create(
          'ObservationLogger.RemovePartialObservation',
          { groupId: 'SHOT' }
        );
        CommandBus.dispatch(command);
      }

      render() {
        const possessionTeamMap =
          this.playDirection() === 'home-away'
            ? {
                [this.observationContext.homeTeam.id]: 'left',
                [this.observationContext.awayTeam.id]: 'right',
              }
            : {
                [this.observationContext.awayTeam.id]: 'left',
                [this.observationContext.homeTeam.id]: 'right',
              };

        const shotAttributes =
          this.observationLogger.getCurrentPartialObservationAttributes('SHOT');
        const possessionAttributes =
          this.observationLogger.getCurrentPartialObservationAttributes(
            'POSSESSION'
          );

        const defaultProps = {
          observationLogger: this.observationLogger,
          groupId: 'SHOT',
          observationCode: 'SHOT',
        };

        const lineUpData = this.lineUpData;
        const activePosition =
          this.observationContext.goalCounter % 4 < 2 ? 'ATTACK' : 'DEFENCE';

        const leftSide =
          this.playDirection() === 'home-away'
            ? lineUpData[this.observationContext.homeTeam.id][activePosition]
            : lineUpData[this.observationContext.awayTeam.id][activePosition];
        const rightSide =
          this.playDirection() === 'home-away'
            ? lineUpData[this.observationContext.awayTeam.id][activePosition]
            : lineUpData[this.observationContext.homeTeam.id][activePosition];

        let activeSide;
        let activeSideName = null;
        let leftLabel = () => leftSide.label;
        let rightLabel = () => rightSide.label;
        switch (true) {
          case !possessionAttributes:
            activeSide = { label: () => '', players: [] };
            break;
          default:
            activeSideName =
              possessionTeamMap[possessionAttributes.get('teamId')];
            switch (activeSideName) {
              case 'left':
                activeSide = {
                  players: leftSide.players,
                  label: leftLabel,
                };
                leftLabel = () => {
                  const a = now();
                  return `${leftSide.label} (${this.observationLogger
                    .getCurrentPartialObservationDuration('POSSESSION')
                    .toFixed(0)} sec)`;
                };
                break;
              case 'right':
                activeSide = {
                  players: rightSide.players,
                  label: rightLabel,
                };
                rightLabel = () => {
                  const a = now();
                  return `${rightSide.label} (${this.observationLogger
                    .getCurrentPartialObservationDuration('POSSESSION')
                    .toFixed(0)} sec)`;
                };
                break;
            }
            break;
        }

        return (
          <div className="height-100">
            {leftSide.players[0] && (
              <div className="field-leader left">
                {!!leftSide.players[0].profilePictureUrl && (
                  <div
                    className="player-thumb"
                    style={{
                      backgroundImage: `url(${leftSide.players[0].profilePictureUrl})`,
                    }}
                  />
                )}
                {!leftSide.players[0].profilePictureUrl && (
                  <div className="player-thumb">{leftSide.players[0].code}</div>
                )}
                <div className="player-name">
                  {this.props.t('section')} <b>{leftSide.players[0].label}</b>
                </div>
              </div>
            )}
            {rightSide.players[0] && (
              <div className="field-leader right">
                {!!rightSide.players[0].profilePictureUrl && (
                  <div
                    className="player-thumb"
                    style={{
                      backgroundImage: `url(${rightSide.players[0].profilePictureUrl})`,
                    }}
                  />
                )}
                {!rightSide.players[0].profilePictureUrl && (
                  <div className="player-thumb">
                    {rightSide.players[0].code}
                  </div>
                )}
                <div className="player-name">
                  {this.props.t('section')} <b>{rightSide.players[0].label}</b>
                </div>
              </div>
            )}
            <InfoOverlay
              activeSideName={activeSideName}
              playDirection={this.playDirection()}
              homeTeamLabel={this.observationContext.homeTeam.label}
              awayTeamLabel={this.observationContext.awayTeam.label}
              matchState={this.observationContext.currentState}
            />
            <div>
              <div style={{ width: '50%', float: 'left' }}>
                <LocationInput
                  {...defaultProps}
                  active={activeSideName === 'left'}
                  onActivate={() => this.onActivate('left')}
                  rotation={-90}
                  color={this.playDirection() === 'home-away' ? 'green' : 'red'}
                />
              </div>
              <div style={{ width: '50%', float: 'left' }}>
                <LocationInput
                  {...defaultProps}
                  active={activeSideName === 'right'}
                  onActivate={() => this.onActivate('right')}
                  rotation={90}
                  color={this.playDirection() === 'home-away' ? 'red' : 'green'}
                />
              </div>
            </div>
            {shotAttributes &&
              shotAttributes.has('distance') &&
              shotAttributes.has('angle') && (
                <Modal
                  size="lg"
                  onCloseClick={this.cancelCurrentShot.bind(this)}
                >
                  <SelectShotDetails
                    sportingEventId={this.observationContext.sportingEvent.id}
                    players={activeSide.players}
                    observationLogger={this.observationLogger}
                  />
                </Modal>
              )}
          </div>
        );
      }
    }
  )
);

const options = new ObservationInputOptions(true, true, true, true, true);

export default new ObservationInput({
  name: 'korfball.live.default',
  version: 1,
  component: KorfballObservationInput,
  title: 'Korfball Live',
  options,
  previewImgSrc,
});
