import React, { useState, useEffect, useRef } from 'react';
import { appCollection, playlistCollection } from 'domain/Playlist';

import addToPlaylist from 'img/icons/add_to_playlist.svg';
import presentation from 'img/icons/presentation.svg';
import multiCamIcon from 'img/icons/multicam.svg';
import shareIcon from 'img/icons/share.svg';

import ReactJWPlayer from 'react-jw-player';
import { Notification } from 'lib/Notification';
import { Modal } from 'lib/Modal';
import { PlaylistCreateView } from 'modules/playlist/create-view';
import playlist from '../modules/playlist';
import { ReactPlayerDragZoomHandler } from './ReactPlayerZoomDragHandler';
import { ReactPlayerMultiCamHandler } from './ReactPlayerMultiCamHandler';
import { Session } from '../domain/Session';
import Loading from './Loading';
import { ShareVideo } from './shareVideo';
import { useVideoAngleContext } from './VideoAngleContextProvider';
import videoCollection from '../domain/Video';
import { SubmittingButton } from './SubmittingButton';
import { useTranslation } from 'react-i18next';
import Logger from '../utils/Logger';

const regex = new RegExp(
  '([a-z0-9]{14})(\\.mp4|/main\\.m3u8).*start=([0-9]+).*end=([0-9]+)'
);

const videoFragmentFromPlaylistItem = ({
  file,
  title: description,
  videoFragment,
}) => {
  let videoId, startTime, endTime;
  if (videoFragment) {
    ({ videoId, startTime, endTime } = videoFragment);
  } else {
    // Fallback for Dashboard. In dashboard
    const match = file.match(regex);
    if (!match) {
      throw `Cannot parse url ${file}`;
    }
    videoId = match[1];
    startTime = parseFloat(match[3]);
    endTime = parseFloat(match[4]);
  }
  console.log('videoFragment', videoFragment);
  return {
    videoId,
    startTime,
    endTime,
    description,
  };
};

const AddToPlaylist = ({ videoFragment, currentTime, onCloseClick }) => {
  const [dataState, setDataState] = useState('loading');
  useEffect(() => {
    (async () => {
      setDataState('loading');
      await playlistCollection.fetch();
      setDataState('ready');
    })();
  }, []);

  const onSubmit = async ({
    playlist: playlistOption,
    description,
    comment,
    length,
  }) => {
    let playlistId;
    if (playlistOption.__isNew__) {
      playlistId = (
        await playlistCollection.create({
          name: playlistOption.value,
        })
      ).playlistId;
    } else {
      playlistId = playlistOption.value;
    }
    const playlist = playlistCollection.get(playlistId);
    let startTime, endTime, videoTime;
    if (length === 'video-fragment') {
      startTime = videoFragment.startTime;
      endTime = videoFragment.endTime;
      videoTime = parseInt(currentTime) - 1;
    } else {
      const [before, after] = length.split('-').map((x) => parseInt(x));
      startTime = Math.max(0, videoFragment.startTime + currentTime - before);
      endTime = videoFragment.startTime + currentTime + after;
      videoTime = before - 1;
    }
    const videoFragmentId = await playlist.addVideoFragment(
      videoFragment.videoId,
      parseInt(startTime),
      parseInt(endTime),
      description
    );
    if (comment) {
      await playlist.conversations.create(
        {
          comment,
          videoFragmentId: videoFragmentId,
          videoTime,
        },
        { optimistic: false }
      );
    }
    onCloseClick();
    Notification.show('Videofragment toegevoegd');
  };

  return (
    <Modal onCloseClick={onCloseClick}>
      {dataState === 'loading' && <Loading />}
      {dataState === 'ready' && (
        <PlaylistCreateView
          groups={playlistCollection.toGroups()}
          description={videoFragment.description}
          length={
            videoFragment.endTime - videoFragment.startTime > 60
              ? '7-5'
              : 'video-fragment'
          }
          onSubmit={onSubmit}
          onCancel={onCloseClick}
        />
      )}
    </Modal>
  );
};

const usePromise = () => {
  const promiseRef = useRef({});

  return {
    reset: () => {
      promiseRef.current.promise = new Promise((resolve) => {
        promiseRef.current.resolve = resolve;
      });
    },
    resolve: (value) => {
      if (promiseRef.current.resolve) {
        promiseRef.current.resolve(value);
      }
    },
    wait: async () => {
      return await promiseRef.current.promise;
    },
  };
};

const shouldHide = (feature, hideFeatures) => {
  if (Array.isArray(hideFeatures)) {
    return hideFeatures.includes(feature);
  } else if (typeof hideFeatures === 'boolean') {
    return hideFeatures;
  } else {
    return false;
  }
};

export const ReactPlayer = ({
  playerId,
  repeat,
  playlistItems,
  onReady,
  showPlaylist,
  noDimensions,
  multiCam,
  hideFeatures = false,
}) => {
  const [addToPlaylistVideoFragment, setAddToPlaylistVideoFragment] =
    useState(null);

  const [openMultiCam, setOpenMultiCam] = useState({
    enabled: false,
    player: null,
  });

  const [digitalZoom, setDigitalZoom] = useState({
    enabled: false,
    player: null,
  });

  const [share, setShare] = useState({
    enabled: false,
    player: null,
  });

  const [error, setError] = useState(false);
  const [currentlyPlayingIndex, setCurrentlyPlayingIndex] = useState(0);
  const [playlist, setPlaylist] = useState(null);

  const currentSession = Session.current();

  const videoAngleContext = useVideoAngleContext();
  const playlistPromise = usePromise();

  useEffect(() => {
    return () => videoAngleContext && videoAngleContext.removePlayer(playerId);
  }, []);

  useEffect(() => {
    setPlaylist(playlistItems);
  }, [playlistItems]);

  useEffect(() => {
    // Throw error when unable to load livestream on pageload..
    (async () => {
      if (playlist) {
        let originalVideo = videoCollection.get(
          playlist[currentlyPlayingIndex]?.videoFragment?.videoId
        );
        if (originalVideo && originalVideo.has('livestream')) {
          let serverAvailable = await currentSession.canConnectToClubServer();
          if (!serverAvailable) {
            setError(true);
          }
        }
      }
    })();
  }, [playlistItems]);

  const toggleDigitalZoom = (playerRef) => {
    if (digitalZoom.enabled) {
      document.querySelectorAll('div[button="Zoom"]')[0].style.opacity = 1;
      setDigitalZoom((o) => {
        return { ...o, ...{ enabled: false, player: null } };
      });
    } else {
      document.querySelectorAll('div[button="Zoom"]')[0].style.opacity = 0.5;
      setDigitalZoom((o) => {
        return { ...o, ...{ enabled: true, player: playerRef } };
      });
    }
  };

  const _onReady = () => {
    const playerRef = window.jwplayer(playerId);

    if (!shouldHide('playlist', hideFeatures)) {
      playerRef.addButton(
        addToPlaylist,
        'Add to playlist',
        () => {
          playerRef.setControls(false);
          playerRef.pause();
          setAddToPlaylistVideoFragment({
            videoFragment: videoFragmentFromPlaylistItem(
              playerRef.getPlaylist()[playerRef.getPlaylistIndex()]
            ),
            currentTime: playerRef.getCurrentTime(),
          });
        },
        'playlist'
      );
    }

    playerRef.on('playlistItem', ({ index }) => {
      playlistPromise.resolve(index);
      setCurrentlyPlayingIndex(index);
    });
    if (
      currentSession
        .licensePrivileges()
        .hasPrivilege('video:presentation-mode') &&
      !shouldHide('presentation', hideFeatures)
    ) {
      /* Digital zoom button */
      playerRef.addButton(
        presentation,
        'Presentation mode',
        () => {
          toggleDigitalZoom(playerRef);
        },
        'Zoom'
      );
    }
    if (
      false &&
      currentSession.isFeatureAvailable('shareVideo') &&
      !shouldHide('share', hideFeatures)
    ) {
      playerRef.addButton(
        shareIcon,
        'Share',
        (evt) => {
          setShare((o) => {
            return { enabled: true, player: playerRef };
          });
        },
        'Share'
      );
    }

    if (multiCam && !shouldHide('multiCam', hideFeatures)) {
      playerRef.addButton(
        multiCamIcon,
        'multicam select',
        (evt) => {
          setOpenMultiCam((o) => {
            evt.target.style.opacity = o.enabled ? 1 : 0.5;
            return { enabled: !o.enabled, player: playerRef };
          });
        },
        'multiCam'
      );
    }
    /* Store videoAngle in context */
    if (videoAngleContext) {
      videoAngleContext.addPlayer(playerRef);
    }
    if (
      videoAngleContext &&
      !videoAngleContext.getPlayerDataForPlayerId(playerId)
    ) {
      videoAngleContext.setAngleForPlayer(
        playerId,
        playlistItems[0].videoFragment.videoId,
        'initial',
        multiCam
      );
    }

    playerRef.on('playlistItem', ({ index }) => {
      playlistPromise.resolve(index);
    });

    onReady();
  };

  const _onError = (event) => {
    setError(event);
  };

  const VideoErrorMessage = ({ error }) => {
    let player = window.jwplayer(playerId);
    let originalVideo = videoCollection.get(
      playlist[player.getPlaylistIndex()]?.videoFragment?.videoId
    );
    console.log('playback error', error);
    Logger.error('playback error', {
      ...error,
      transactionName: error?.sourceError?.type ?? 'Playback Error',
      videoId: originalVideo.videoId,
      isLiveStream: originalVideo.has('livestream'),
    });

    if (
      error &&
      originalVideo &&
      originalVideo.has('livestream')
      // [232011, 232001, 102630].includes(error?.code)
    ) {
      return (
        <CheckAutomaticCameraConnectionError
          onResolved={() => {
            setError(false);
            // Reload the livestream.
            // Unfortunately we cannot seek to your last used time here This information is missing from player.
            player.load(playlist);
            player.play();
          }}
        />
      );
    } else {
      // no other error handling in place
      return null;
    }
  };

  if (!playlist) {
    return null;
  }

  return (
    <>
      <ReactJWPlayer
        playerId={playerId}
        isAutoPlay={true}
        playerScript="https://cdn.jwplayer.com/libraries/iJqhMiBf.js"
        playlist={playlist}
        onReady={_onReady}
        onError={(e) => _onError(e)}
        onSetupError={(e) => _onError(e)}
        customProps={{
          repeat,
          visualplaylist: !!showPlaylist,
          controls: true,
          displaytitle: false,
          playbackRateControls: true,
          nextUpDisplay: false,
          playbackRates: [0.25, 0.5, 1, 1.25, 1.5, 2, 3, 4],
          ...(noDimensions
            ? {}
            : {
                height: 500, // random value, will be overwritten by css
                width: 800,
              }), // random value, will be overwritten by css)
        }}
      />
      {error && <VideoErrorMessage error={error} />}
      {multiCam && openMultiCam.enabled && (
        <ReactPlayerMultiCamHandler
          videoAngleContext={videoAngleContext}
          multiCam={multiCam}
          player={openMultiCam.player}
        />
      )}
      {digitalZoom.enabled && (
        <ReactPlayerDragZoomHandler
          playerContainer={digitalZoom.player.getContainer()} // This will force a rerender when container is changed
          player={digitalZoom.player}
          onClose={toggleDigitalZoom}
        />
      )}

      {addToPlaylistVideoFragment !== null && (
        <AddToPlaylist
          {...addToPlaylistVideoFragment}
          onCloseClick={() => {
            setAddToPlaylistVideoFragment(null);
            const player = window.jwplayer(playerId);
            player.setControls(true);
            player.play();
          }}
        />
      )}
      {share.enabled && (
        <ShareVideo
          onClose={() => setShare({ enabled: false })}
          playerRef={share.player}
        />
      )}
    </>
  );
};

const CheckAutomaticCameraConnectionError = ({ onResolved }) => {
  const { t } = useTranslation('videoPlayer');
  let currentSession = Session.current();
  let [connectionError, setconnectionError] = useState(null);

  useEffect(() => {
    (async () => {
      await currentSession.isCameraAvailableAtLocalClub();
      let serverAvailable = await currentSession.canConnectToClubServer();
      if (!serverAvailable) {
        setconnectionError(true);
      }
    })();
  }, []);

  if (!connectionError) {
    // Can connect to Server.
    return null;
  }
  return (
    <div className={'video-not-found'}>
      <div className="userlogin__warning">
        <label>{t('errors.livestream.label')}</label>
        <p>{t('errors.livestream.body')}</p>
        <SubmittingButton
          className={'btn secondary btn-cta'}
          href={'#'}
          onClick={async () => {
            await currentSession.isCameraAvailableAtLocalClub();
            let serverAvailable = await currentSession.canConnectToClubServer();
            if (serverAvailable) {
              onResolved();
            }
          }}
        >
          {t('errors.livestream.cta')}
        </SubmittingButton>
      </div>
    </div>
  );
};
