import React, { Fragment, useEffect, useState, useCallback } from 'react';
import { withTranslation } from 'react-i18next';
import { Session } from 'domain/Session';
import { ObservationContext } from 'domain/ObservationLogger/ObservationContext';
import { VideoPlayer } from 'lib/Observation/ObservationLogVideoPlayer';
import { ScoreBoard } from 'lib/Observation/ScoreBoard';
import { FilterOptions } from 'lib/FilterOptions/index';
import { ObservationFilter } from 'lib/Observation/ObservationFilter';
import { VideoPlayerPagination } from 'lib/VideoPlayerPagination';
import { TagsFilter } from '../../observe/components/tags-filter';
import multiCamIcon from 'img/icons/multicam.svg';

const useSportingEventVideoPlayer = (
  sportingEvent,
  video,
  fullMatchLabel,
  multiCam
) => {
  const [videoFragmentCollection, setVideoFragmentCollection] = useState(null);
  const [selectedCategory, setCategory] = useState(null);
  const [selectedLabel, setLabel] = useState(fullMatchLabel);
  const [observationContext, setObservationContext] = useState(null);
  const [currentItem, setCurrentItem] = useState(0);

  const filterObservations = (observations, filter) =>
    observations
      .filter(
        (observation) =>
          filter['roles'].length == 0 ||
          filter['roles'].includes(observation.creatorRoleName)
      )
      .filter((observation) => {
        return (
          filter['sets'].length == 0 ||
          filter['sets'].includes(observation.attributes_.learningLineId) ||
          filter['sets'].includes(observation.attributes_.phaseLearningLine)
        );
      });

  // TODO: @koen confirm no use of useCallback is necessary
  // eslint-disable-next-line
  const collectVideoFragmentCollection = () => {
    const videoFragmentCollector =
      Session.current().getVideoFragmentCollector();

    const videoFragmentCollection = videoFragmentCollector.collect(
      video,
      sportingEvent,
      filterObservations(observationContext.observationsWithTime(), filter)
    );

    const categories = videoFragmentCollection.getNonEmptyCategories();
    if (categories.length > 0) {
      setCategory(categories[0].key);
    } else {
      setCategory(null);
    }
    setLabel(fullMatchLabel);
    setVideoFragmentCollection(videoFragmentCollection);
  };

  const [filter, setFilter] = useState({
    roles: [],
    sets: [],
  });

  useEffect(() => {
    const loadData = async () => {
      const observationContext = new ObservationContext(
        sportingEvent,
        null,
        sportingEvent.clocks()[video.id].clockId,
        false
      );
      await observationContext.init();

      setObservationContext(observationContext);
    };
    loadData();
  }, [sportingEvent]);

  useEffect(() => {
    if (observationContext !== null) {
      collectVideoFragmentCollection();
    }
  }, [observationContext, filter]);

  const isLoaded = videoFragmentCollection !== null;
  let videoPlayer = null,
    labelSelector = null,
    categorySelector = null;

  if (isLoaded) {
    const sportType = Session.current().sportType();
    const fullMatchVideoFragment = {
      ...video.getVideoFragment(0, video.duration),
      description: fullMatchLabel,
      isFullMatch: true,
    };

    const categories = videoFragmentCollection.getNonEmptyCategories();
    const filters = categories.map(({ key, label }) => {
      return {
        title: label,
        isActive: selectedCategory === key,
        onClick: () => {
          setCategory(key);
          setLabel(fullMatchLabel);
        },
      };
    });

    const labels = [
      {
        description: fullMatchLabel,
        videoFragmentCount: 1,
      },
    ];

    let videoFragments;
    if (categories.length === 0) {
      videoFragments = [fullMatchVideoFragment];
    } else {
      if (selectedLabel === fullMatchLabel) {
        videoFragments = [fullMatchVideoFragment];
      } else {
        videoFragments = videoFragmentCollection.getVideoFragments(
          selectedCategory,
          selectedLabel
        );
      }
      labels.push(...videoFragmentCollection.getLabels(selectedCategory));
    }

    videoPlayer = (
      <Fragment>
        {Session.current().isFeatureAvailable('scoreBar') &&
          sportingEvent.get('type') === 'match' && (
            <ScoreBoard
              observationContext={observationContext}
              sportType={sportType}
            />
          )}
        <VideoPlayer
          clock={observationContext.clock}
          videoFragments={videoFragments}
          video={video}
          title={selectedLabel}
          buttons={video.privilegedTo('download') ? ['download'] : []}
          activePlayListItem={currentItem}
          onPlaylistItem={(index) => setCurrentItem(index)}
          multiCam={multiCam}
        />
        <VideoPlayerPagination
          currentItem={currentItem}
          setCurrentItem={setCurrentItem}
          totalItems={videoFragments.length}
        />
      </Fragment>
    );

    categorySelector = categories.length > 1 && (
      <div className="col-12">
        <FilterOptions filters={filters} />
      </div>
    );

    labelSelector = (
      <ObservationFilter
        observationGroups={labels}
        selectedDescription={selectedLabel}
        onSelect={(description) => setLabel(description)}
      />
    );
  }

  const filterComponent = (
    <TagsFilter
      values={filter}
      observationsFetcher={async () => {
        if (observationContext) {
          await observationContext.observationLog.fetch();
          return observationContext.observationsWithTime();
        }
        return [];
      }}
      onChange={setFilter}
    />
  );

  return {
    isLoaded: videoFragmentCollection !== null,
    reload: () => {
      observationContext.observationLog.reload();
      collectVideoFragmentCollection();
    },
    observationContext,
    videoPlayer,
    categorySelector,
    labelSelector,
    filter: filterComponent,
  };
};

const SportingEventVideoPlayerHOC = withTranslation('module.play')(
  ({ children, t, sportingEvent, video, multiCam }) => {
    const ret = useSportingEventVideoPlayer(
      sportingEvent,
      video,
      t('fullMatch'),
      multiCam
    );
    return children(ret);
  }
);

export { SportingEventVideoPlayerHOC, useSportingEventVideoPlayer };
